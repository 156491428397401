import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  FirstStepStyle,
  OpportunityRecordStyle,
  SecondStepStyle
} from './style'
import BradCrumbs from '../../../../shared/components/common/breadcrumbs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  EEducationLevelEnum,
  EIntershipStepsEnum,
  EKnowledgeTypeEnum,
  EPeriodsEnum,
  EWeekDayEnum
} from '../../../../shared/enums'
import {
  commonService,
  internshipService,
  notification
} from '../../../../shared/services'
import { TOpportunityForm } from '../../../../core/models'
import { FormStyle } from '../../../../shared/styles/form.style'
import {
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Checkbox,
  Slider,
  Typography
} from '@mui/material'
import './style.css'
import {
  TAddressResponse,
  TCompaniesFilters,
  TCourse,
  TFacilitiesFilters,
  TIndicatorsFilters,
  TScoreRangesFilters,
  TKnowledgeItem,
  TRequestPagination
} from '../../../../shared/models'
import dayjs from 'dayjs'
import { IoIosArrowDown, IoIosInformationCircleOutline } from 'react-icons/io'
import { ScheduleTypeEnum } from '../../../../core/enums/internship.enum'
import ToggleComponent from '../../../../shared/components/common/toggle'
import { MdCancel } from 'react-icons/md'
import CalendarIcon from '../../../../assets/svgs/calendar.svg'
import { EScholarshipTypeEnum } from '../../../../shared/enums'
import { Button } from 'react-bootstrap'
import { BsGeoAlt } from 'react-icons/bs'
import { MdCheckBoxOutlineBlank } from 'react-icons/md'
import { IoMdCheckbox } from 'react-icons/io'
import { NumericFormat } from 'react-number-format'
import CustomSpinner from '../../../../shared/components/common/custom-spinner'
const OpportunityRecord: React.FC = () => {
  let { id, clone } = useParams()
  const location = useLocation()

  if (!location.state) {
    location.state = '#004699'
  }
  const icon = (
    <MdCheckBoxOutlineBlank size={25} style={{ color: location.state }} />
  )
  const checkedIcon = (
    <IoMdCheckbox size={25} style={{ color: location.state }} />
  )
  let debounceTimer: any
  const navigate = useNavigate()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isloading, setLoading] = useState<boolean>(false)
  const [activeNumbers, setActiveNumbers] = useState([1])
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedCompany, setSelectedCompany] = useState<any>(null)
  const stepNames = [
    'Dados da Oportunidade',
    'Financeiro',
    'Local de Estágio',
    'Perfil do Candidato'
  ]
  const dayMinutesLimit: number = 1800

  const form: TOpportunityForm = {
    jobVacancyId: null,
    selectedFacility: null,
    selectedCompany: null,
    selectedModality: 'P',
    selectedModalityType: 'P',
    opportunityCode: '',
    opportunityStatus: 'A',
    opportunityDescription: '',
    scheduleType: ScheduleTypeEnum.Fixed,
    dailyWorkload: null,
    internshipDays: null,
    weeklyWorkload: null,
    occupationArea: null,
    jobReasonCode: 'N',
    featuredOnPortal: 'N',
    makeAvailableOnPortal: 'S',
    additionalInformation: '',
    observation: '',
    openingRequestDate: dayjs(),
    totalVacancies: 1,
    totalReferrals: 0,
    totalWorkDays: 2,
    activities: [],
    weekDays: [
      {
        disabled: true,
        weekDayEnum: EWeekDayEnum.FIXED,
        scheduleType: ScheduleTypeEnum.Fixed,
        firstTurnEntry: null,
        firstTurnExit: null,
        secondTurnEntry: null,
        secondTurnExit: null,
        interval: null
      },
      {
        disabled: true,
        weekDayEnum: EWeekDayEnum.MONDAY,
        scheduleType: ScheduleTypeEnum.Flexible,
        firstTurnEntry: null,
        firstTurnExit: null,
        secondTurnEntry: null,
        secondTurnExit: null,
        interval: null
      },
      {
        disabled: true,
        weekDayEnum: EWeekDayEnum.TUESDAY,
        scheduleType: ScheduleTypeEnum.Flexible,
        firstTurnEntry: null,
        firstTurnExit: null,
        secondTurnEntry: null,
        secondTurnExit: null,
        interval: null
      },
      {
        disabled: true,
        weekDayEnum: EWeekDayEnum.WEDNESDAY,
        scheduleType: ScheduleTypeEnum.Flexible,
        firstTurnEntry: null,
        firstTurnExit: null,
        secondTurnEntry: null,
        secondTurnExit: null,
        interval: null
      },
      {
        disabled: true,
        weekDayEnum: EWeekDayEnum.THURSDAY,
        scheduleType: ScheduleTypeEnum.Flexible,
        firstTurnEntry: null,
        firstTurnExit: null,
        secondTurnEntry: null,
        secondTurnExit: null,
        interval: null
      },
      {
        disabled: true,
        weekDayEnum: EWeekDayEnum.FRIDAY,
        scheduleType: ScheduleTypeEnum.Flexible,
        firstTurnEntry: null,
        firstTurnExit: null,
        secondTurnEntry: null,
        secondTurnExit: null,
        interval: null
      },
      {
        disabled: true,
        weekDayEnum: EWeekDayEnum.SATURDAY,
        scheduleType: ScheduleTypeEnum.Flexible,
        firstTurnEntry: null,
        firstTurnExit: null,
        secondTurnEntry: null,
        secondTurnExit: null,
        interval: null
      }
    ],
    contractType: '',
    scholarshipType: null,
    scholarshipAmount: null,
    mealVoucherAmount: null,
    foodVoucherAmount: null,
    benefitsDescription: '',
    hasMedicalAssistance: false,
    hasMealVoucher: false,
    hasFoodVoucher: false,
    hasTransportationAllowance: false,

    zipCode: '',
    address: '',
    city: '',
    streetAddress: '',
    streetNumber: '',
    state: '',
    neighborhood: '',
    complement: '',
    ibgeCode: '',
    latitude: null,
    longitude: null,

    educationLevel: null,
    courses: [],
    courseType: EPeriodsEnum.ANNUAL,
    ageRange: [16, 70],
    availability: '',
    maxDistanceToCandidate: [30],
    distanceRange: 30,
    studying: [1, 5],
    coursesAndSpecializations: [],
    languages: [],
    certifications: '',
    overallClassification: [],
    indicatorsClassification: [],
    fromProtectionAgencies: 'N',
    allowSpecialNeeds: 'N',
    specialNeedsType: null,
    gender: [],
    materialValue: null,
    paymentValues: null,
    salaryValue: null
  }

  //#region fistStep
  const [formData, setFormData] = useState<TOpportunityForm>(form)
  const [pagination, setPagination] = useState<TRequestPagination>({
    searchTerm: '',
    page: 1,
    pageSize: 10,
    facilityId: null
  })
  const [facilitiesFilters, setFacilitiesFilters] = useState<
    TFacilitiesFilters[]
  >([])
  const [indicatorsFilters, setIndicatorsFilters] = useState<
    TIndicatorsFilters[]
  >([])
  const [scoreRanges, setScoreRanges] = useState<TScoreRangesFilters[]>([])
  const [autocompleteValue, setAutocompleteValue] = useState(null)
  const availableOptions: TIndicatorsFilters[] = indicatorsFilters.filter(
    (indicator: any) =>
      !formData.indicatorsClassification.some(
        (selected: any) => selected.id === indicator.id
      )
  )
  const [opportunityAreas, setOpportunityAreas] = useState<any[]>([])
  const [companiesFilters, setCompaniesFilters] = useState<TCompaniesFilters[]>(
    []
  )
  const [expandedAccordion, setExpandedAccordion] = useState<number | null>(
    null
  )

  const [expandedAccordionContainer, setExpandedAccordionContainer] =
    useState<boolean>(true)

  const handlerLoadFilters = async (): Promise<void> => {
    try {
      setLoading(true)
      const facilitiesResult = await commonService.getFacilitiesFilters()
      const opportunityAreas = await commonService.getOpportunityAreas()
      const indicatorsFilters = await commonService.getIndicatorsFilter()
      const scoreRanges = await commonService.getScoreRanges()
      setOpportunityAreas(opportunityAreas)
      setFacilitiesFilters(facilitiesResult)
      setIndicatorsFilters(indicatorsFilters)
      setScoreRanges(scoreRanges)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handleFacilityChange = (
    _: ChangeEvent<{}>,
    value: TFacilitiesFilters | null
  ) => {
    setFormData({
      ...formData,
      selectedFacility: value?.id || null,
      selectedCompany: null
    })

    setPagination(pagination => ({
      ...pagination,
      facilityId: value?.id || null
    }))

    setCompaniesFilters([])
    handlerSearchCompanies('', value?.id)
  }

  const handlerSearchCompanies = useCallback(
    async (query: string = '', facilityId?: number) => {
      if (facilityId || formData.selectedFacility) {
        const companiesResult = await commonService.getCompaniesFilters({
          searchTerm: query,
          facilityId: facilityId || formData.selectedFacility,
          page: pagination.page,
          pageSize: pagination.pageSize
        })
        setCompaniesFilters(companiesResult)
      } else {
        setCompaniesFilters([])
      }
    },
    [formData.selectedFacility, pagination.page, pagination.pageSize]
  )

  const handleNextClick = () => {
    const currentStep = activeNumbers[activeNumbers.length - 1]
    const nextStep =
      currentStep < stepNames.length ? currentStep + 1 : currentStep
    changeTab(nextStep)
  }

  const changeTab = (nextStep: number) => {
    setCurrentPage(nextStep - 1)
    setActiveNumbers(Array.from({ length: nextStep }, (_, i) => i + 1))
  }

  const setCompanyZipCode = () => {
    if (selectedCompany) {
      setFormData(prevData => ({
        ...prevData,
        zipCode: selectedCompany.address.zipCode ?? '',
        streetNumber: selectedCompany.address.streetNumber ?? ''
      }))
      handleAddressByZip(selectedCompany.address.zipCode)
    }
  }

  const validation = (): boolean => {
    const mandatoryItens = [
      'scholarshipType',
      'selectedFacility',
      'selectedCompany',
      'selectedModality',
      // 'opportunityModalityType',
      'occupationArea',
      'totalVacancies',
      'totalReferrals',
      'zipCode',
      'streetAddress',
      'streetNumber',
      'state',
      'city',
      'neighborhood',
      'activities',
      'latitude',
      'longitude',
      'jobReasonCode'
    ]
    const invalidFields: string[] = []
    for (const [key, value] of Object.entries(formData) as [
      keyof TOpportunityForm,
      any
    ][]) {
      if (key !== 'activities') {
        if (mandatoryItens.includes(key) && (value === null || value === '')) {
          invalidFields.push(key)
        }
      } else {
        if (key.length < 3) {
          invalidFields.push(key)
        }
      }
    }
    if (formData.hasMealVoucher && formData.mealVoucherAmount === null) {
      notification.error(
        'Atenção',
        'Valor do Auxílio refeição precisa ser informado caso habilitado'
      )
      return false
    }

    if (formData.hasFoodVoucher && formData.foodVoucherAmount === null) {
      notification.error(
        'Atenção',
        'Valor do Auxílio alimentação precisa ser informado caso habilitado'
      )
      return false
    }

    if (selectedCompany) {
      const companiesWithPendingIssues =
        selectedCompany.isInactive || selectedCompany.missDocuments
      // ||selectedCompany.isOverdue

      if (companiesWithPendingIssues) {
        notification.error(
          'Atenção',
          'A companhia selecionada contém pendências'
        )
        return false
      }
    }

    if (invalidFields.length > 0) {
      const allTrueTouchedFields = Object.fromEntries(
        Object.keys(touchedFields).map(key => [key, true])
      )
      setTouchedFields(allTrueTouchedFields)
      notification.error('Atenção', 'Campos obrigatórios não informados')
      return false
    }

    if (
      formData.allowSpecialNeeds === 'S' &&
      (formData.specialNeedsType === null || formData.specialNeedsType === '')
    ) {
      notification.error(
        'Atenção',
        'Sendo portador de necessidades especials, é necessário descrever'
      )
      return false
    }

    if (formData.scheduleType === ScheduleTypeEnum.Fixed) {
      const schedule = formData.weekDays.find(
        day => day.scheduleType === ScheduleTypeEnum.Fixed
      )
      if (
        schedule?.firstTurnEntry === null ||
        schedule?.firstTurnExit === null
      ) {
        return false
      }
    } else {
      return formData.weekDays.some(schedule => !schedule.disabled)
    }

    formData.weekDays.forEach((day, index) => {
      if (!day.disabled && day.scheduleType === ScheduleTypeEnum.Flexible) {
        if (!validateInterval(index)) {
          return false
        }
      }
    })

    if (
      formData.overallClassification.length < 1 &&
      formData.indicatorsClassification.length < 1
    ) {
      notification.error(
        'Atenção',
        'Selecione uma das modalidades de classificação'
      )
      return false
    }

    return true
  }

  const prepareWeekDaysTimeZone = (newObject: any) => {
    newObject.weekDays.forEach((day: any) => {
      if (day.firstTurnEntry) {
        day.firstTurnEntry = dayjs(day.firstTurnEntry).subtract(3, 'hour')
      }
      if (day.firstTurnExit) {
        day.firstTurnExit = dayjs(day.firstTurnExit).subtract(3, 'hour')
      }
      if (day.secondTurnEntry) {
        day.secondTurnEntry = dayjs(day.secondTurnEntry).subtract(3, 'hour')
      }
      if (day.secondTurnExit) {
        day.secondTurnExit = dayjs(day.secondTurnExit).subtract(3, 'hour')
      }
    })
    return newObject
  }

  const prepareToSave = async (form: TOpportunityForm) => {
    const newObject: any = Object.assign({}, form)
    newObject.totalReferrals = Number(form.totalReferrals)
    newObject.totalVacancies = Number(form.totalVacancies)

    if (form.foodVoucherAmount)
      newObject.foodVoucherAmount = form.foodVoucherAmount.replace(/^R\$/, '')

    if (form.mealVoucherAmount)
      newObject.mealVoucherAmount = form.mealVoucherAmount.replace(/^R\$/, '')

    if (form.scholarshipAmount)
      newObject.scholarshipAmount = form.scholarshipAmount.replace(/[R$%]/g, '')
    if (newObject.zipCode.length === 8) {
      newObject.zipCode =
        newObject.zipCode.substring(0, 5) + '-' + newObject.zipCode.substring(5)
    }
    if (newObject.ibgeCode === '') {
      const result = await commonService.getAddressByZipCode(formData.zipCode)
      if (!result.erro) {
        newObject.ibgeCode = result.ibge
      }
    }
    prepareWeekDaysTimeZone(newObject)
    return newObject
  }

  const goBackPage = () => {
    navigate('/intership')
  }

  const handleSave = async () => {
    if (!validation()) return

    try {
      const preparedForm = await prepareToSave(formData)
      setLoading(true)
      if (!id || clone) {
        const result = await internshipService.createIntership(preparedForm)
        if (result) {
          notification.success('Sucesso', result.message)
          goBackPage()
          setLoading(false)
        }
      } else {
        const result = await internshipService.updateInternship(preparedForm)
        if (result) {
          notification.success('Sucesso', result.message)
          goBackPage()
          setLoading(false)
        }
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleInputChangeDefault = (event: ChangeEvent<any>) => {
    const { name, value } = event.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const setFixedSchedule = (field: any, newValue: any) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      weekDays: prevFormData.weekDays.map(day => {
        if (day.scheduleType === ScheduleTypeEnum.Fixed) {
          return {
            ...day,
            [field]: newValue
          }
        }
        return day
      })
    }))
    calculateIntervalFixedSchedule()
  }

  const handleInputChange = (event: ChangeEvent<any>, index?: number) => {
    const { name, value } = event.target
    if (name === 'weekDays') {
      setFormData(prevData => ({
        ...prevData,
        weekDays: prevData.weekDays.map((day, i) =>
          i === index ? { ...day, disabled: value } : day
        )
      }))
    } else if (name.includes('Turn')) {
      const [, field] = name.split('.')
      setFormData(prevData => ({
        ...prevData,
        weekDays: prevData.weekDays.map((day, i) =>
          i === index
            ? {
                ...day,
                [field]: dayjs(value).format('HH:mm')
              }
            : day
        )
      }))
      calculateInterval(index)
    } else {
      if (name === 'scheduleType') {
        clearSchedule()
      }
      setFormData(prevData => ({ ...prevData, [name]: value }))
    }
  }

  const clearSchedule = () => {
    setFormData(prevData => ({
      ...prevData,
      weekDays: [
        {
          disabled: true,
          weekDayEnum: EWeekDayEnum.FIXED,
          scheduleType: ScheduleTypeEnum.Fixed,
          firstTurnEntry: null,
          firstTurnExit: null,
          secondTurnEntry: null,
          secondTurnExit: null,
          interval: null
        },
        {
          disabled: true,
          weekDayEnum: EWeekDayEnum.MONDAY,
          scheduleType: ScheduleTypeEnum.Flexible,
          firstTurnEntry: null,
          firstTurnExit: null,
          secondTurnEntry: null,
          secondTurnExit: null,
          interval: null
        },
        {
          disabled: true,
          weekDayEnum: EWeekDayEnum.TUESDAY,
          scheduleType: ScheduleTypeEnum.Flexible,
          firstTurnEntry: null,
          firstTurnExit: null,
          secondTurnEntry: null,
          secondTurnExit: null,
          interval: null
        },
        {
          disabled: true,
          weekDayEnum: EWeekDayEnum.WEDNESDAY,
          scheduleType: ScheduleTypeEnum.Flexible,
          firstTurnEntry: null,
          firstTurnExit: null,
          secondTurnEntry: null,
          secondTurnExit: null,
          interval: null
        },
        {
          disabled: true,
          weekDayEnum: EWeekDayEnum.THURSDAY,
          scheduleType: ScheduleTypeEnum.Flexible,
          firstTurnEntry: null,
          firstTurnExit: null,
          secondTurnEntry: null,
          secondTurnExit: null,
          interval: null
        },
        {
          disabled: true,
          weekDayEnum: EWeekDayEnum.FRIDAY,
          scheduleType: ScheduleTypeEnum.Flexible,
          firstTurnEntry: null,
          firstTurnExit: null,
          secondTurnEntry: null,
          secondTurnExit: null,
          interval: null
        },
        {
          disabled: true,
          weekDayEnum: EWeekDayEnum.SATURDAY,
          scheduleType: ScheduleTypeEnum.Flexible,
          firstTurnEntry: null,
          firstTurnExit: null,
          secondTurnEntry: null,
          secondTurnExit: null,
          interval: null
        }
      ],
      dailyWorkload: 0,
      weeklyWorkload: 0
    }))
  }

  const calculateInterval = (index?: number) => {
    const day = formData.weekDays[index || 0]
    const firstTurnExit = day.firstTurnExit
      ? dayjs(day.firstTurnExit, 'HH:mm')
      : null
    const secondTurnEntry = day.secondTurnEntry
      ? dayjs(day.secondTurnEntry, 'HH:mm')
      : null

    if (firstTurnExit && secondTurnEntry) {
      const interval = secondTurnEntry.diff(firstTurnExit, 'minutes')
      setFormData(prevData => ({
        ...prevData,
        weekDays: prevData.weekDays.map((d, i) =>
          i === index ? { ...d, interval: interval } : d
        )
      }))
    }
  }

  const setTotalWorkDays = (value: any) => {
    setFormData(prevData => ({
      ...prevData,
      totalWorkDays: value
    }))
    calculateShedule(Number(value))
  }

  const calculateShedule = (value?: number) => {
    const fixedHourlyConfiguration = formData.weekDays.find(
      day => day.scheduleType === ScheduleTypeEnum.Fixed
    )

    if (!fixedHourlyConfiguration) return

    const totalFirstTurn = calculateTotalMinutes(
      fixedHourlyConfiguration.firstTurnEntry,
      fixedHourlyConfiguration.firstTurnExit
    )
    const totalSecondTurn = calculateTotalMinutes(
      fixedHourlyConfiguration.secondTurnEntry,
      fixedHourlyConfiguration.secondTurnExit
    )

    let totalMinutesPerDay = totalFirstTurn + totalSecondTurn
    let totalMinutesWeek =
      totalMinutesPerDay *
      (value !== undefined ? value : formData.totalWorkDays)

    if (totalMinutesWeek > dayMinutesLimit) {
      notification.error(
        'Atenção',
        `O total semanal tem o limite de 30 horas que foram excedidas`
      )
    }
    setFormData((prevData: any) => ({
      ...prevData,
      dailyWorkload: totalMinutesPerDay,
      weeklyWorkload: totalMinutesWeek
    }))
  }

  const calculateFlexibileShedule = () => {
    let totalMinutesWeek = 0
    let enabledDaysCount = 0

    formData.weekDays.forEach(day => {
      if (!day.disabled) {
        const totalFirstTurn = calculateTotalMinutes(
          day.firstTurnEntry,
          day.firstTurnExit
        )
        const totalSecondTurn = calculateTotalMinutes(
          day.secondTurnEntry,
          day.secondTurnExit
        )

        let totalMinutesPerDay = totalFirstTurn + totalSecondTurn

        const interval = calculateIntervalBetweenEntries(
          day.firstTurnExit,
          day.secondTurnEntry
        )

        if (totalMinutesPerDay >= 360 && interval < 15) {
          notification.error(
            'Atenção',
            `O total do dia com 6 ou mais horas deve ter um intervalo de 15 minutos.`
          )
        }

        totalMinutesWeek += totalMinutesPerDay
        enabledDaysCount++
      }
    })

    if (totalMinutesWeek > 30 * 60) {
      notification.error(
        'Atenção',
        `O total semanal tem o limite de 30 horas que foram excedidas`
      )
    }

    const averageTotalMinutesPerDay = totalMinutesWeek / enabledDaysCount

    setFormData(prevData => ({
      ...prevData,
      dailyWorkload: averageTotalMinutesPerDay,
      weeklyWorkload: totalMinutesWeek
    }))
  }

  const calculateIntervalBetweenEntries = (entry: any, exit: any) => {
    if (entry && exit) {
      const entryMoment = dayjs(entry, 'HH:mm')
      const exitMoment = dayjs(exit, 'HH:mm')
      return exitMoment.diff(entryMoment, 'minutes')
    }
    return 0
  }

  const validateInterval = (index: number, warning: boolean = false) => {
    const totalFirstTurn = calculateTotalMinutes(
      formData.weekDays[index].firstTurnEntry,
      formData.weekDays[index].firstTurnExit
    )
    const totalSecondTurn = calculateTotalMinutes(
      formData.weekDays[index].secondTurnEntry,
      formData.weekDays[index].secondTurnExit
    )

    let totalMinutesPerDay = totalFirstTurn + totalSecondTurn

    const interval = calculateIntervalBetweenEntries(
      formData.weekDays[index].firstTurnExit,
      formData.weekDays[index].secondTurnEntry
    )
    if (warning && totalMinutesPerDay >= 360 && interval < 15) {
      notification.error(
        'Atenção',
        `O total do dia com 6 ou mais horas deve ter um intervalo de 15 minutos.`
      )
    }
    return totalMinutesPerDay >= 360 && interval < 15
  }

  const calculateTotalMinutes = (entry: any, exit: any) => {
    if (entry && exit) {
      const entryMoment = dayjs(entry, 'HH:mm')
      const exitMoment = dayjs(exit, 'HH:mm')
      return exitMoment.diff(entryMoment, 'minutes')
    }
    return 0
  }

  const getDayLabel = (day: EWeekDayEnum): string => {
    switch (day) {
      case EWeekDayEnum.MONDAY:
        return 'Segunda-feira'
      case EWeekDayEnum.TUESDAY:
        return 'Terça-feira'
      case EWeekDayEnum.WEDNESDAY:
        return 'Quarta-feira'
      case EWeekDayEnum.THURSDAY:
        return 'Quinta-feira'
      case EWeekDayEnum.FRIDAY:
        return 'Sexta-feira'
      case EWeekDayEnum.SATURDAY:
        return 'Sábado'
      default:
        return ''
    }
  }

  const calculateIntervalFixedSchedule = () => {
    const firstTurnExit = formData.weekDays.find(
      day => day.scheduleType === ScheduleTypeEnum.Fixed
    )?.firstTurnExit
    const secondTurnEntry = formData.weekDays.find(
      day => day.scheduleType === ScheduleTypeEnum.Fixed
    )?.secondTurnEntry

    if (firstTurnExit && secondTurnEntry) {
      const exitMoment = dayjs(firstTurnExit, 'HH:mm')
      const entryMoment = dayjs(secondTurnEntry, 'HH:mm')
      const interval = entryMoment.diff(exitMoment, 'minutes')

      setFormData(prevData => ({
        ...prevData,
        weekDays: prevData.weekDays.map(day => {
          if (day.scheduleType === ScheduleTypeEnum.Fixed) {
            return {
              ...day,
              interval: interval
            }
          }
          return day
        })
      }))
    }
  }

  const handleAccordionChange =
    (index: number) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedAccordion(isExpanded ? index : null)
    }

  const handleAccordionChangeContainer = () => {
    setExpandedAccordionContainer(!expandedAccordionContainer)
  }

  const getCompanyTooltip = (company: TCompaniesFilters): string => {
    let result: string = ''

    if (company.isInactive) {
      result += 'Empresa Inativa'

      if (company.missDocuments) {
        result += ', '
      }
    }

    if (company.missDocuments) {
      result += 'Empresa não entregou termo de fomento assinado'
    }

    return result
  }

  const hasCompanyAnyIssue = (company: TCompaniesFilters): boolean => {
    return company.isInactive || company.missDocuments
  }

  const handleSearch = async (event: any) => {
    const query = event.target?.value || ''
    ;(query.length > 2 || query.length === 0) && debounceSearch(query)
  }

  const debounceSearch = (value: string) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      handlerSearchCompanies(value)
    }, 500)
  }

  const handleCompanyChange = (
    _: ChangeEvent<{}>,
    value: TCompaniesFilters | null
  ) => {
    setFormData({
      ...formData,
      selectedCompany: value?.id || null
    })
    setSelectedCompany(companiesFilters.find(option => option.id === value?.id))
    value && checkCompanyStatus(value)
  }

  //#region aqui
  const handleIndicatorSelect = (
    _: ChangeEvent<{}>,
    value: TIndicatorsFilters | null
  ) => {
    if (value != null) {
      setFormData((prevFormData: any) => {
        const exists = prevFormData.indicatorsClassification.some(
          (filter: any) => filter.id === value.id
        )

        if (!exists) {
          return {
            ...prevFormData,
            indicatorsClassification: [
              ...prevFormData.indicatorsClassification,
              value
            ],
            overallClassification: []
          }
        }

        return prevFormData
      })
      setAutocompleteValue(null)
    }
  }

  const checkCompanyStatus = async (
    company: TCompaniesFilters
  ): Promise<void> => {
    let isDisabled = false
    if (company.isInactive) {
      await notification.error('Atenção', 'Empresa inativa', 1500)
      isDisabled = true
    }

    if (company.missDocuments) {
      notification.error(
        'Atenção',
        'Falta de Termo de Fomento, solicitar documento assinado pela empresa',
        1500,
        true,
        location.state
      )
      isDisabled = true
    }

    // if (company.isOverdue) {
    //   await notification.error('Atenção', 'Empresa', 1500)
    //   isDisabled = true
    // }
    //

    if (!company.hiringTypeCode) {
      setFormData(prevData => ({
        ...prevData,
        contractType: 'D'
      }))
    } else {
      setFormData(prevData => ({
        ...prevData,
        contractType: company.hiringTypeCode
      }))
    }

    setIsDisabled(isDisabled)
  }

  function getHourFormat(value: number) {
    let hours = Math.floor(value / 60)
    let minutes = value % 60
    let strHours = hours < 10 ? '0' + hours : hours
    let strMinutes = minutes < 10 ? '0' + minutes : minutes
    return `${strHours}:${strMinutes}`
  }

  const handleInputChangeDate = (date: any) => {
    setFormData(prevData => ({ ...prevData, openingRequestDate: date }))
  }

  const handleInputChangeActivities = (event: any) => {
    if (event.key === 'Enter') {
      const newActivity = event.target.value.trim()
      if (newActivity !== '') {
        setFormData(prevData => ({
          ...prevData,
          activities: [...prevData.activities, newActivity]
        }))
        event.target.value = ''
      }
    }
  }

  const removeActivities = (index: number) => {
    setFormData(prevData => ({
      ...prevData,
      activities: prevData.activities.filter((_, i) => i !== index)
    }))
  }

  const removeIndicator = (removeId: number) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      indicatorsClassification: prevFormData.indicatorsClassification.filter(
        (indicator: any) => indicator.id !== removeId
      )
    }))
  }

  function calendarIcon() {
    return (
      <img
        className="me-1"
        src={CalendarIcon}
        style={{ width: 20 }}
        alt="Date picker opening icon"
        width={32}
      />
    )
  }

  //#endregion
  //#region secondStep
  const [touchedFields, setTouchedFields] = useState<any>({
    scholarshipType: false,
    selectedFacility: false,
    selectedCompany: false,
    selectedModality: false,
    occupationArea: false,
    totalVacancies: false,
    totalReferrals: false,
    zipCode: false,
    streetAddress: false,
    streetNumber: false,
    state: false,
    city: false,
    neighborhood: false,
    activities: false,
    latitude: false,
    longitude: false,
    foodVoucherAmount: false,
    mealVoucherAmount: false,
    specialNeedsType: false
  })

  const [touchedClassificationFields, setTouchedClassificationFields] =
    useState<any>({
      indicatorsClassification: false
    })

  const handlerBlurClassification = (field: keyof TOpportunityForm) => {
    setTouchedClassificationFields({ ...touchedFields, [field]: true })
  }

  const getFieldValidation = (field: keyof TOpportunityForm) => {
    if (field === 'activities') {
      return touchedFields[field] && formData.activities.length < 3
    } else if (field === 'foodVoucherAmount') {
      return (
        touchedFields[field] &&
        formData.hasFoodVoucher &&
        (formData.foodVoucherAmount == null ||
          formData.foodVoucherAmount === '')
      )
    } else if (field === 'mealVoucherAmount') {
      return (
        touchedFields[field] &&
        formData.hasMealVoucher &&
        (formData.mealVoucherAmount == null ||
          formData.mealVoucherAmount === '')
      )
    } else {
      return (
        touchedFields[field] &&
        (formData[field] === null || formData[field] === '')
      )
    }
  }

  const getClassificationValidation = (field: keyof TOpportunityForm) => {
    return (
      touchedClassificationFields.indicatorsClassification &&
      formData.overallClassification.length < 1 &&
      formData.indicatorsClassification.length < 1
    )
  }

  const handleInputChangeAutoComplete = (
    field: keyof TOpportunityForm,
    value: any
  ) => {
    const valueOfIdIndexes = [
      'occupationArea',
      'selectedFacility',
      'selectedCompany'
    ]
    const valueOfValueIndexes = [
      'selectedModality',
      'scholarshipType',
      'jobReasonCode'
    ]

    setFormData(prevData => ({
      ...prevData,
      [field]: valueOfIdIndexes.includes(field)
        ? value.id
        : valueOfValueIndexes.includes(field)
        ? value.value
        : value.label
    }))
    if (field === 'selectedModality') {
      setFormData(prevData => ({
        ...prevData,
        selectedModalityType: value.value
      }))
    }
  }

  function removePrefix(text: string) {
    const posicaoEspaco = text.indexOf(' ')
    if (posicaoEspaco !== -1) {
      return text.substring(posicaoEspaco + 1)
    }
    return text
  }

  const handleInputChangeMasked = (event: ChangeEvent<any>, index?: number) => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: removePrefix(value)
    }))
  }

  const handlerCheckbox = (name: string, isChecked: boolean) => {
    setFormData(prevData => ({ ...prevData, [name]: isChecked }))
  }

  const handleBlur = (field: keyof TOpportunityForm) => {
    setTouchedFields({ ...touchedFields, [field]: true })
    if (field === 'zipCode') {
      handleAddressByZip()
    }

    if (field === 'streetNumber') {
      getLatLngFromAddress(false)
    }

    if (field === 'totalVacancies') {
      if (formData?.totalVacancies && formData.totalVacancies < 1) {
        setFormData(prevData => ({ ...prevData, totalVacancies: 1 }))
        notification.error('Atenção', 'Total de vagas não pode ser menor que 1')
      }
    }

    if (field === 'totalReferrals') {
      if (formData?.totalReferrals && Number(formData.totalReferrals) < 0) {
        setFormData(prevData => ({ ...prevData, totalReferrals: 0 }))
        notification.error(
          'Atenção',
          'Total de indicações não pode ser menor que 0'
        )
      }
    }
    if (
      formData.totalReferrals &&
      formData.totalVacancies &&
      field === 'totalReferrals'
    ) {
      const totalReferrals = Number(formData.totalReferrals)
      const totalVacancies = Number(formData.totalVacancies)
      if (totalReferrals > totalVacancies) {
        setFormData(prevData => ({ ...prevData, totalReferrals: null }))
        notification.error(
          'Atenção',
          'Total de indicações não pode ser menor que o total de vagas'
        )
      }
    }
  }
  //#endregion
  //#region ThirdStep

  const handleAddressByZip = async (
    zipCode: string = formData.zipCode
  ): Promise<void> => {
    if (zipCode?.length > 7) {
      setLoading(true)
      const result = await commonService.getAddressByZipCode(zipCode)

      if (!result.erro) {
        await bindAddress(result)
      } else {
        notification.error(
          'Atenção',
          'Endereço não localizado pelo cep informado'
        )
        setFormData(formData => ({
          ...formData,
          address: '',
          city: '',
          streetAddress: '',
          state: '',
          neighborhood: '',
          ibgeCode: '',
          latitude: null,
          longitude: null
        }))
      }
      setLoading(false)
    }
  }

  async function bindAddress(receivedAddress: TAddressResponse) {
    setFormData(formData => ({
      ...formData,
      zipCode: receivedAddress.cep,
      address: receivedAddress.logradouro,
      city: receivedAddress.localidade,
      streetAddress: receivedAddress.logradouro,
      state: receivedAddress.uf,
      neighborhood: receivedAddress.bairro,
      ibgeCode: receivedAddress.ibge
    }))
    return true
  }

  const getLatLngFromAddress = async (warning: boolean = true) => {
    const fotmatedAddress = formatAddress()
    if (fotmatedAddress) {
      setLoading(true)
      const result = await commonService.getCordinatesByAddress(fotmatedAddress)
      setFormData(formData => ({
        ...formData,
        latitude: result.lat,
        longitude: result.lng
      }))
      setLoading(false)
    } else {
      if (warning) notification.error('Atenção', 'Endereço incompleto')
    }
  }

  const formatAddress = () => {
    const { streetAddress, city, streetNumber, state, neighborhood } = formData
    const addressParts = [
      streetAddress,
      streetNumber,
      neighborhood,
      city,
      state
    ]
    const nonEmptyParts = addressParts.filter(
      part => part && part.trim() !== ''
    )
    return nonEmptyParts.length === 5
      ? `${nonEmptyParts.join(', ')}, Brasil`
      : false
  }
  //#endregion
  //#region ForthStep
  const [languagesSkills, setLanguagesSkills] = useState<TKnowledgeItem[]>([])
  const [informaticSkills, setInformaticSkills] = useState<TKnowledgeItem[]>([])
  const MAX = 70
  const MIN = 0
  const MAXSEMI = 12
  const MINSEMI = 1
  const MAXANN = 6
  const MINANN = 1
  const MAXAGE = 70
  const MINAGE = 16
  const MAXKM = 30
  const MINKM = 0
  const marks = [
    {
      value: MIN,
      label: ''
    },
    {
      value: MAX,
      label: ''
    }
  ]

  const [courses, setCourses] = useState<TCourse[]>([])

  const handleImputLevel = (_: ChangeEvent<any>, value: any) => {
    if (value.id !== EEducationLevelEnum.HIGHSCHOOL) {
      handlerLoadCourses(value.id)
    } else {
      setCourses([])
      setFormData(prevData => ({
        ...prevData,
        courses: []
      }))
    }
    setFormData(prevData => ({
      ...prevData,
      educationLevel: value.id
    }))
  }

  const handleInputCourseTypeChange = (
    _: ChangeEvent<any>,
    value: any
  ): void => {
    if (value.id !== EPeriodsEnum.ANNUAL) {
    } else {
    }
    setFormData(prevData => ({
      ...prevData,
      courseType: value.id
    }))
  }

  const handleCourseChange = (_: any, value: any, reason: any) => {
    setFormData(prevData => ({
      ...prevData,
      courses: value
    }))
  }

  const handleCheckboxChange = (value: number): void => {
    const newClassifications = formData.overallClassification.includes(value)
      ? formData.overallClassification.filter(item => item !== value)
      : [...formData.overallClassification, value]

    setFormData(prevData => ({
      ...prevData,
      overallClassification: newClassifications,
      indicatorsClassification: []
    }))
  }

  const handleCheckboxGenDeChange = (value: string): void => {
    const newGenders = formData.gender.includes(value)
      ? formData.gender.filter(item => item !== value)
      : [...formData.gender, value]

    setFormData(prevData => ({
      ...prevData,
      gender: newGenders
    }))
  }

  const handleLanguageChange = (value: number): void => {
    const newLanguages = formData.languages.includes(value)
      ? formData.languages.filter(item => item !== value)
      : [...formData.languages, value]

    setFormData(prevState => ({
      ...prevState,
      languages: newLanguages
    }))
  }

  const handleCoursesAndSpecializationsChange = (value: number): void => {
    const newEspecializations = formData.coursesAndSpecializations.includes(
      value
    )
      ? formData.coursesAndSpecializations.filter(item => item !== value)
      : [...formData.coursesAndSpecializations, value]

    setFormData(prevState => ({
      ...prevState,
      coursesAndSpecializations: newEspecializations
    }))
  }

  const handleChangeRangeAge = (_: Event, newValue: number | number[]) => {
    setFormData(prevData => ({ ...prevData, ageRange: newValue as number[] }))
  }

  const handleInputAvailabilitiesChange = (_: ChangeEvent<{}>, item: any) => {
    setFormData({
      ...formData,
      availability: item.value || null
    })
  }

  const handleChangeRangeDistance = (_: Event, newValue: number | number[]) => {
    setFormData(prevData => ({
      ...prevData,
      maxDistanceToCandidate: newValue as number[]
    }))
  }

  const handleInputStudyng = (_: Event, newValue: number | number[]) => {
    setFormData(prevData => ({
      ...prevData,
      studying: newValue as number[]
    }))
  }

  const handlerLoadCourses = async (level: number): Promise<void> => {
    try {
      setLoading(true)
      const result = await commonService.getFormationCoursesByLevel(level)
      setCourses(result)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerLoadKnowledgeItems = async (): Promise<void> => {
    try {
      setLoading(true)
      const result: TKnowledgeItem[] =
        await commonService.getAllKnowledgeItems()
      if (result) {
        setLanguagesSkills(
          result.filter(r => r.knowledgeId === EKnowledgeTypeEnum.LANGUAGES)
        )
        setInformaticSkills(
          result.filter(r => r.knowledgeId === EKnowledgeTypeEnum.INFORMATICS)
        )
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const isCourseDisabled = (): boolean => {
    return (
      !formData.educationLevel ||
      formData.educationLevel === EEducationLevelEnum.HIGHSCHOOL
    )
  }
  //#endregion

  const prepareWeekDays = (weekdays: any) => {
    weekdays.forEach((day: any) => {
      if (day.firstTurnEntry) {
        day.firstTurnEntry = dayjs(day.firstTurnEntry)
      }
      if (day.firstTurnExit) {
        day.firstTurnExit = dayjs(day.firstTurnExit)
      }
      if (day.secondTurnEntry) {
        day.secondTurnEntry = dayjs(day.secondTurnEntry)
      }
      if (day.secondTurnExit) {
        day.secondTurnExit = dayjs(day.secondTurnExit)
      }
    })
    return weekdays
  }

  const handlerLoadRequestData = useCallback(
    (result: any) => {
      if (clone) {
        result.opportunityCode = ''
      }
      setFormData(prevData => ({
        ...prevData,
        jobVacancyId: result.jobVacancyId,
        openingRequestDate: dayjs(result.openingRequestDate),
        activities: result.activities,
        selectedFacility: result.selectedFacility,
        selectedCompany: result.selectedCompany,
        selectedModality: result.selectedModality,
        selectedModalityType: result.selectedModalityType,
        opportunityCode: result.opportunityCode,
        opportunityStatus: result.opportunityStatus,
        opportunityDescription: result.opportunityDescription,
        opportunityModalityType: result.opportunityModalityType,
        scheduleType: result.scheduleType,
        internshipDays: result.internshipDays,
        availabilities: result.availabilities,
        jobReasonCode: result.jobReasonCode,
        featuredOnPortal: result.featuredOnPortal,
        makeAvailableOnPortal: result.makeAvailableOnPortal,
        additionalInformation: result.additionalInformation,
        observation: result.observation,
        totalVacancies: result.totalVacancies,
        totalReferrals: String(result.totalReferrals),
        totalWorkDays: result.totalWorkDays,
        contractType: result.contractType,
        zipCode: result.zipCode,
        address: result.address,
        city: result.city,
        streetAddress: result.streetAddress,
        streetNumber: result.streetNumber,
        state: result.state,
        neighborhood: result.neighborhood,
        complement: result.complement,
        latitude: result.latitude,
        longitude: result.longitude,
        courses: result.courses,
        ageRange: result.ageRange,
        availability: result.availability,
        maxDistanceToCandidate: result.maxDistanceToCandidate,
        distanceRange: result.distanceRange,
        studying: result.studying,
        coursesAndSpecializations: result.coursesAndSpecializations,
        languages: result.languages,
        certifications: result.certifications,
        overallClassification: result.overallClassification,
        indicatorsClassification: result.indicatorsClassification || [],
        fromProtectionAgencies: result.fromProtectionAgencies,
        allowSpecialNeeds: result.allowSpecialNeeds,
        specialNeedsType: result.specialNeedsType,
        gender: result.gender,
        hasTransportationAllowance: result.hasTransportationAllowance,
        hasMedicalAssistance: result.hasMedicalAssistance,
        hasMealVoucher: result.hasMealVoucher,
        hasFoodVoucher: result.hasFoodVoucher,
        mealVoucherAmount: result.mealVoucherAmount,
        foodVoucherAmount: result.foodVoucherAmount,
        scholarshipAmount: result.scholarshipAmount,
        benefitsDescription: result.benefitsDescription,
        scholarshipType: result.scholarshipType,
        educationLevel: result.educationLevel,
        occupationArea: result.occupationArea,
        weekDays:
          result.weekDays?.length > 0
            ? prepareWeekDays(result.weekDays)
            : prevData.weekDays,
        weeklyWorkload: result.weeklyWorkload,
        dailyWorkload: result.dailyWorkload,
        materialValue: result.materialValue,
        paymentValues: result.paymentValues,
        salaryValue: result.salaryValue
      }))
      handlerSearchCompanies('', result.selectedFacility)
      handlerLoadCourses(result.educationLevel)
    },
    [clone]
  )

  const handlerInternship = useCallback(
    async (id: number): Promise<void> => {
      try {
        setLoading(true)
        const result = await internshipService.getIntershipById(id)
        handlerLoadRequestData(result)
      } catch {
      } finally {
        setLoading(false)
      }
    },
    [handlerLoadRequestData]
  )

  useEffect(() => {
    handlerLoadFilters()
    handlerLoadKnowledgeItems()

    if (id) {
      handlerInternship(Number(id))
    }
  }, [handlerInternship, id])

  useEffect(() => {
    setCompanyZipCode()
  }, [selectedCompany])

  return (
    <OpportunityRecordStyle.Container className="w-100">
      <BradCrumbs
        names={['Sistema de estágio', ' Cadastro de Oportunidades']}
        paths={[]}
        disabled={false}
        showArrow={true}
        backGroundColor={location.state}
      />
      <CustomSpinner state={location.state} show={isloading} />
      <OpportunityRecordStyle.Title>
        {!id ? 'Nova Oportunidade' : 'Edição de Oportunidade '}
      </OpportunityRecordStyle.Title>
      <OpportunityRecordStyle.Content>
        <OpportunityRecordStyle.Form>
          <OpportunityRecordStyle.FormHeader
            className="py-2 mx-3"
            backgroundColor={location.state}
          >
            <div className="left-side d-flex align-items-center">
              {activeNumbers.length > 0 && (
                <span
                  className="number-form py-2"
                  style={{
                    backgroundColor: location.state,
                    paddingRight:
                      activeNumbers[activeNumbers.length - 1] === 1
                        ? '16.5px'
                        : '15px',
                    paddingLeft:
                      activeNumbers[activeNumbers.length - 1] === 1
                        ? '16.5px'
                        : '15px'
                  }}
                >
                  {activeNumbers[activeNumbers.length - 1]}
                </span>
              )}
              <h5 className="m-0 ms-2">
                {stepNames[activeNumbers[activeNumbers.length - 1] - 1]}
              </h5>
            </div>
            <div className="right-side d-flex align-items-center">
              {!isDisabled &&
                [1, 2, 3, 4].map(number => (
                  <span
                    key={number}
                    className={`number-form  py-1 ms-2 ${
                      activeNumbers.includes(number) ? 'active' : ''
                    }`}
                    style={{
                      paddingRight: number === 1 ? '9.5px' : '8px',
                      paddingLeft: number === 1 ? '9.5px' : '8px'
                    }}
                    onClick={() => changeTab(number)}
                  >
                    {number}
                  </span>
                ))}
            </div>
          </OpportunityRecordStyle.FormHeader>
          <div className="w-100 content-container p-3 pt-0 overflow-auto">
            <div
              style={{
                display:
                  currentPage + 1 === EIntershipStepsEnum.FISTSTEP
                    ? 'block'
                    : 'none'
              }}
            >
              <div className="w-100 ">
                <div>
                  <div className="row">
                    <div className="col">
                      <div className="mt-2">
                        <FormStyle.Label>
                          Motivo da Abertura da Vaga
                        </FormStyle.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          options={internshipService.opportunityReasons}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0
                            }
                          }}
                          getOptionLabel={option => option.label}
                          getOptionKey={option => option.value}
                          value={
                            internshipService.opportunityReasons.find(
                              option => option.value === formData.jobReasonCode
                            ) || { id: 0, label: '', value: '' }
                          }
                          disableClearable={true}
                          noOptionsText="Nenhum resultado encontrado"
                          onChange={(event, value) =>
                            handleInputChangeAutoComplete(
                              'jobReasonCode',
                              value
                            )
                          }
                          onBlur={() => handleBlur('jobReasonCode')}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mt-2">
                        <FormStyle.Label>Unidade ISBET*</FormStyle.Label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={facilitiesFilters}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0,
                              border: getFieldValidation('selectedFacility')
                                ? 'solid red 1px'
                                : ''
                            }
                          }}
                          value={
                            facilitiesFilters.find(
                              option => option.id === formData.selectedFacility
                            ) || null
                          }
                          getOptionLabel={option => option.name}
                          getOptionKey={option => option.id}
                          onChange={handleFacilityChange}
                          onBlur={() => handleBlur('selectedFacility')}
                          noOptionsText="Nenhum resultado encontrado"
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              ({option.name})
                            </Box>
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mt-2">
                        <FormStyle.Label>Tipo da oportunidade</FormStyle.Label>
                        <Autocomplete
                          disabled
                          id="combo-box-demo"
                          options={internshipService.opportunityType}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0
                            }
                          }}
                          getOptionLabel={option => option.label}
                          getOptionKey={option => option.id}
                          value={internshipService.opportunityType[0]}
                          noOptionsText="Nenhum resultado encontrado"
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                    <div className="col d-flex flex-column mt-3">
                      <FormStyle.Label>Código da oportunidade:</FormStyle.Label>
                      <FormStyle.Field
                        disabled
                        name="opportunityCode"
                        value={formData.opportunityCode}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <div className="mt-2">
                        <FormStyle.Label>Modalidade da vaga*</FormStyle.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          options={internshipService.opportunityModality}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0,
                              border: getFieldValidation('selectedModality')
                                ? 'solid red 1px'
                                : ''
                            }
                          }}
                          getOptionLabel={option => option.label}
                          getOptionKey={option => option.id}
                          value={internshipService.opportunityModality[0]}
                          disableClearable={true}
                          noOptionsText="Nenhum resultado encontrado"
                          onChange={(event, value) =>
                            handleInputChangeAutoComplete(
                              'selectedModality',
                              value
                            )
                          }
                          onBlur={() => handleBlur('selectedModality')}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mt-2">
                        <FormStyle.Label>
                          Identificação da Empresa*
                        </FormStyle.Label>
                        <Autocomplete
                          disablePortal
                          id="company"
                          options={companiesFilters}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0,
                              border:
                                isDisabled ||
                                getFieldValidation('selectedCompany')
                                  ? 'solid red 1px'
                                  : ''
                            }
                          }}
                          value={
                            companiesFilters.find(
                              option => option.id === formData.selectedCompany
                            ) || null
                          }
                          getOptionLabel={option => option.name}
                          getOptionKey={option => option.id}
                          onKeyUp={event => handleSearch(event)}
                          onChange={handleCompanyChange}
                          onBlur={() => handleBlur('selectedCompany')}
                          noOptionsText="Nenhum resultado encontrado"
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              name="selectedCompany"
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          renderOption={(props, option) => (
                            <Tooltip
                              title={getCompanyTooltip(option)}
                              placement="right"
                              arrow
                            >
                              <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                ({option.name}){' '}
                                {hasCompanyAnyIssue(option) && (
                                  <IoIosInformationCircleOutline
                                    className="ms-2"
                                    style={{ color: 'orangered' }}
                                    size={20}
                                  />
                                )}
                              </Box>
                            </Tooltip>
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="d-flex flex-column mt-3 me-1">
                        <FormStyle.Label>
                          Descrição da oportunidade:
                        </FormStyle.Label>
                        <FormStyle.Field
                          name="opportunityDescription"
                          value={formData.opportunityDescription}
                          onChange={handleInputChange}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col d-flex flex-column mt-3">
                      <FormStyle.Label>Situação da vaga</FormStyle.Label>
                      <FormStyle.Field
                        disabled
                        name="opportunityStatus"
                        value={
                          formData.opportunityStatus === 'A'
                            ? 'Ativa'
                            : 'Cancelado'
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <div className="mt-2">
                        <FormStyle.Label>Área de Atuação*</FormStyle.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          options={opportunityAreas}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0,
                              border: getFieldValidation('occupationArea')
                                ? 'solid red 1px'
                                : ''
                            }
                          }}
                          getOptionLabel={option => option.name}
                          getOptionKey={option => option.id}
                          value={
                            opportunityAreas.find(
                              option => option.id === formData.occupationArea
                            ) || {
                              id: 0,
                              value: 'Selecione',
                              label: 'Selecione'
                            }
                          }
                          disableClearable={true}
                          noOptionsText="Nenhum resultado encontrado"
                          onBlur={() => handleBlur('occupationArea')}
                          onChange={(event, value) =>
                            handleInputChangeAutoComplete(
                              'occupationArea',
                              value
                            )
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              name="companyStatus"
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mt-2">
                        <FormStyle.Label>Horário de estágio</FormStyle.Label>
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={internshipService.scheduleTypes}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0
                            }
                          }}
                          value={internshipService.scheduleTypes.find(
                            i => i.value === formData.scheduleType
                          )}
                          noOptionsText="Nenhum resultado encontrado"
                          disableClearable={true}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Fixo"
                              InputLabelProps={{ shrink: false }}
                              name="scheduleType"
                              value={formData.scheduleType}
                            />
                          )}
                          onChange={(event: any, option: any) => {
                            const fakeEvent = {
                              target: {
                                name: 'scheduleType',
                                value:
                                  option.label === 'Fixo'
                                    ? ScheduleTypeEnum.Fixed
                                    : option.label === 'Flexível'
                                    ? ScheduleTypeEnum.Flexible
                                    : undefined
                              }
                            }
                            handleInputChange(fakeEvent as ChangeEvent<any>)
                          }}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                    <div className="col d-flex">
                      <div className="col-6 d-flex flex-column mt-3  ">
                        <FormStyle.Label>Carga horária diária:</FormStyle.Label>
                        <FormStyle.Field
                          disabled
                          name="dailyWorkload"
                          value={getHourFormat(formData.dailyWorkload || 0)}
                        />
                      </div>
                      <div className="col-6 d-flex flex-column mt-3  ms-2">
                        <FormStyle.Label>
                          Carga horária semanal:
                        </FormStyle.Label>
                        <FormStyle.Field
                          disabled
                          name="weeklyWorkload"
                          value={getHourFormat(formData.weeklyWorkload || 0)}
                          style={
                            (formData.weeklyWorkload || 0) > dayMinutesLimit
                              ? { borderColor: 'red' }
                              : {}
                          }
                        />
                      </div>
                    </div>
                    <div className="col mt-3">
                      <div className="w-100 d-flex flex-column">
                        <FormStyle.Label>Número de dias</FormStyle.Label>
                        <Autocomplete
                          disablePortal
                          id="totalWorkDays"
                          options={[2, 3, 4, 5, 6]}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0
                            }
                          }}
                          value={[2, 3, 4, 5, 6].find(
                            n => n === formData.totalWorkDays
                          )}
                          disableClearable={true}
                          getOptionLabel={option => option.toString()}
                          onChange={(event: any, value: any) =>
                            setTotalWorkDays(value)
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                  </div>
                  {formData.scheduleType === ScheduleTypeEnum.Flexible && (
                    <div className="row mt-4 p-3 pt-0">
                      <FirstStepStyle.AccordionContent
                        expanded={expandedAccordionContainer}
                        onChange={handleAccordionChangeContainer}
                      >
                        <AccordionSummary expandIcon={<IoIosArrowDown />}>
                          <FormStyle.Label>Horário Flexível</FormStyle.Label>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="d-flex flex-column">
                            {formData.weekDays.map((day, index) => {
                              if (day.scheduleType === ScheduleTypeEnum.Fixed) {
                                return null
                              }
                              return (
                                <Grid
                                  item
                                  key={index}
                                  xs={7}
                                  sm={7}
                                  md={7}
                                  lg={7}
                                  className="py-2"
                                >
                                  <FirstStepStyle.AccordionContent
                                    expanded={expandedAccordion === index}
                                    onChange={handleAccordionChange(index)}
                                    className={!day.disabled ? 'active' : ''}
                                  >
                                    <AccordionSummary
                                      expandIcon={
                                        <IoIosArrowDown
                                          fill={
                                            !day.disabled ? location.state : ''
                                          }
                                        />
                                      }
                                    >
                                      <div className="d-flex align-items-center">
                                        <ToggleComponent
                                          value={!day.disabled}
                                          onChange={value => {
                                            handleInputChange(
                                              {
                                                target: {
                                                  name: 'weekDays',
                                                  value: !value
                                                }
                                              } as ChangeEvent<any>,
                                              index
                                            )
                                          }}
                                          onClick={e => e.stopPropagation()}
                                        />
                                        <FormStyle.Label className="ms-2 m-0">
                                          {getDayLabel(day.weekDayEnum)}
                                        </FormStyle.Label>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      aria-disabled={!day.disabled}
                                    >
                                      <div className="d-flex align-items-center justify-content-between">
                                        <FormStyle.TimePickerStyle
                                          disabled={day.disabled}
                                          ampm={false}
                                          label="Entrada"
                                          value={
                                            formData.weekDays[index]
                                              .firstTurnEntry
                                          }
                                          onChange={value => {
                                            const updatedWeekDays: any = [
                                              ...formData.weekDays
                                            ]
                                            updatedWeekDays[
                                              index
                                            ].firstTurnEntry = value
                                            setFormData({
                                              ...formData,
                                              weekDays: updatedWeekDays
                                            })
                                            calculateFlexibileShedule()
                                          }}
                                        />

                                        <FormStyle.TimePickerStyle
                                          disabled={day.disabled}
                                          ampm={false}
                                          label="Saida"
                                          className="ms-3"
                                          value={
                                            formData.weekDays[index]
                                              .firstTurnExit
                                          }
                                          onChange={value => {
                                            const updatedWeekDays: any = [
                                              ...formData.weekDays
                                            ]
                                            updatedWeekDays[
                                              index
                                            ].firstTurnExit = value
                                            setFormData({
                                              ...formData,
                                              weekDays: updatedWeekDays
                                            })
                                            calculateInterval(index)
                                            calculateFlexibileShedule()
                                          }}
                                        />
                                        <div className="d-flex flex-column ms-3 mb-4">
                                          <FormStyle.Label>
                                            Intervalo
                                          </FormStyle.Label>
                                          <FormStyle.Field
                                            value={
                                              formData.weekDays[index]
                                                .interval ?? ''
                                            }
                                            disabled
                                            style={{
                                              border: validateInterval(
                                                index,
                                                true
                                              )
                                                ? 'red 1px solid'
                                                : ''
                                            }}
                                          />
                                        </div>
                                        <FormStyle.TimePickerStyle
                                          disabled={day.disabled}
                                          ampm={false}
                                          label="Entrada"
                                          className="ms-3"
                                          value={
                                            formData.weekDays[index]
                                              .secondTurnEntry
                                          }
                                          onChange={value => {
                                            const updatedWeekDays: any = [
                                              ...formData.weekDays
                                            ]
                                            updatedWeekDays[
                                              index
                                            ].secondTurnEntry = value
                                            setFormData({
                                              ...formData,
                                              weekDays: updatedWeekDays
                                            })
                                            calculateInterval(index)
                                            calculateFlexibileShedule()
                                          }}
                                        />
                                        <FormStyle.TimePickerStyle
                                          disabled={day.disabled}
                                          ampm={false}
                                          label="Saida"
                                          className="ms-3"
                                          value={
                                            formData.weekDays[index]
                                              .secondTurnExit
                                          }
                                          onChange={value => {
                                            const updatedWeekDays: any = [
                                              ...formData.weekDays
                                            ]
                                            updatedWeekDays[
                                              index
                                            ].secondTurnExit = value
                                            setFormData({
                                              ...formData,
                                              weekDays: updatedWeekDays
                                            })
                                            calculateFlexibileShedule()
                                          }}
                                        />
                                      </div>
                                    </AccordionDetails>
                                  </FirstStepStyle.AccordionContent>
                                </Grid>
                              )
                            })}
                          </div>
                        </AccordionDetails>
                      </FirstStepStyle.AccordionContent>
                    </div>
                  )}
                  {formData.scheduleType === ScheduleTypeEnum.Fixed && (
                    <div className="row mt-3">
                      <div className="col mt-4">
                        <FormStyle.TimePickerContainer>
                          <FormStyle.TimePickerStyle
                            ampm={false}
                            label="Entrada"
                            onChange={value =>
                              setFixedSchedule('firstTurnEntry', value)
                            }
                            value={
                              formData.weekDays.find(
                                day =>
                                  day.scheduleType === ScheduleTypeEnum.Fixed
                              )?.firstTurnEntry
                            }
                          />
                        </FormStyle.TimePickerContainer>
                      </div>

                      <div className="col mt-4">
                        <FormStyle.TimePickerContainer
                          onBlur={() => calculateShedule()}
                        >
                          <FormStyle.TimePickerStyle
                            ampm={false}
                            label="Saida"
                            className="ms-3"
                            onChange={value => {
                              setFixedSchedule('firstTurnExit', value)
                            }}
                            value={
                              formData.weekDays.find(
                                day =>
                                  day.scheduleType === ScheduleTypeEnum.Fixed
                              )?.firstTurnExit
                            }
                          />
                        </FormStyle.TimePickerContainer>
                      </div>

                      <div className="col mt-1">
                        <div className="w-100 d-flex flex-column">
                          <FormStyle.Label>Intervalo</FormStyle.Label>
                          <FormStyle.Field
                            value={
                              formData.weekDays.find(
                                day =>
                                  day.scheduleType === ScheduleTypeEnum.Fixed
                              )?.interval || ''
                            }
                            style={{
                              border: validateInterval(
                                formData.weekDays.findIndex(
                                  day =>
                                    day.scheduleType === ScheduleTypeEnum.Fixed
                                ),
                                true
                              )
                                ? 'red 1px solid'
                                : ''
                            }}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="col mt-4">
                        <FormStyle.TimePickerContainer>
                          <FormStyle.TimePickerStyle
                            ampm={false}
                            label="Entrada"
                            className="ms-3"
                            onChange={value => {
                              setFixedSchedule('secondTurnEntry', value)
                            }}
                            value={
                              formData.weekDays.find(
                                day =>
                                  day.scheduleType === ScheduleTypeEnum.Fixed
                              )?.secondTurnEntry
                            }
                          />
                        </FormStyle.TimePickerContainer>
                      </div>

                      <div className="col mt-4">
                        <FormStyle.TimePickerContainer
                          onBlur={() => calculateShedule()}
                        >
                          <FormStyle.TimePickerStyle
                            ampm={false}
                            label="Saida"
                            className="ms-3"
                            onChange={value =>
                              setFixedSchedule('secondTurnExit', value)
                            }
                            value={
                              formData.weekDays.find(
                                day =>
                                  day.scheduleType === ScheduleTypeEnum.Fixed
                              )?.secondTurnExit
                            }
                          />
                        </FormStyle.TimePickerContainer>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <div className="d-flex flex-column mt-3">
                        <FormStyle.Label>
                          Atividade{' '}
                          <small
                            style={{ fontSize: '10px', marginLeft: '10px' }}
                          >
                            (Após digitar pressione a tecla enter, Mínimo 3
                            atividades)
                          </small>
                        </FormStyle.Label>

                        <FormStyle.Field
                          onKeyDown={handleInputChangeActivities}
                          onBlur={() => handleBlur('activities')}
                          placeholder="Após digitar pressione a tecla enter"
                          style={{
                            border: getFieldValidation('activities')
                              ? 'solid red 1px'
                              : ''
                          }}
                        />
                        <div className="d-flex flex-wrap">
                          {formData.activities.map((activity, index) => (
                            <div
                              key={index}
                              style={{
                                marginTop: '5px',
                                marginRight: '5px',
                                minHeight: 30,
                                backgroundColor: location.state,
                                border: '1px solid gray',
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderRadius: '25px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                fontSize: '13px'
                              }}
                            >
                              {activity}
                              <MdCancel
                                className="ms-2"
                                size={20}
                                onClick={() => removeActivities(index)}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex flex-column mt-3">
                        <Tooltip
                          title={
                            'O conteúdo escrito neste campo irá constar como uma cláusula no contrato de estágio'
                          }
                          placement="top"
                          arrow
                        >
                          <FormStyle.Label>
                            Informações complementares
                            <IoIosInformationCircleOutline
                              className="ms-2"
                              style={{ color: 'orangered' }}
                              size={20}
                            />
                          </FormStyle.Label>
                        </Tooltip>
                        <FormStyle.TextArea
                          rows={4}
                          style={{ resize: 'none' }}
                          className="custom-textarea"
                          value={formData.additionalInformation}
                          placeholder="O conteúdo escrito neste campo irá constar como uma cláusula no contrato de estágio"
                          name="additionalInformation"
                          onChange={handleInputChangeDefault}
                        ></FormStyle.TextArea>
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex flex-column mt-3">
                        <FormStyle.Label>Observação</FormStyle.Label>
                        <FormStyle.TextArea
                          rows={4}
                          style={{ resize: 'none' }}
                          value={formData.observation}
                          name="observation"
                          onChange={handleInputChangeDefault}
                        ></FormStyle.TextArea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2">
                      <RadioGroup
                        className="mt-3"
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="makeAvailableOnPortal"
                        value={formData.makeAvailableOnPortal}
                        onChange={handleInputChangeDefault}
                      >
                        <FormStyle.Label>
                          Disponibilizar no Portal
                        </FormStyle.Label>

                        <div className="d-flex align-items-center">
                          <FormControlLabel
                            value="S"
                            control={
                              <Radio style={{ color: location.state }} />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Sim
                              </span>
                            }
                          />
                          <FormControlLabel
                            value="N"
                            control={
                              <Radio style={{ color: location.state }} />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Não
                              </span>
                            }
                          />
                        </div>
                      </RadioGroup>
                    </div>
                    <div className="col-2">
                      <RadioGroup
                        className="mt-3"
                        name="featuredOnPortal"
                        value={formData.featuredOnPortal}
                        onChange={handleInputChangeDefault}
                      >
                        <FormStyle.Label>Destaque no Portal</FormStyle.Label>

                        <div className="d-flex align-items-center">
                          <FormControlLabel
                            value="S"
                            control={
                              <Radio style={{ color: location.state }} />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Sim
                              </span>
                            }
                          />
                          <FormControlLabel
                            value="N"
                            control={
                              <Radio style={{ color: location.state }} />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Não
                              </span>
                            }
                          />
                        </div>
                      </RadioGroup>
                    </div>

                    <div className="col">
                      <div className="d-flex flex-column mt-3">
                        <FormStyle.Label>
                          Data da solicitação de abertura da vaga
                        </FormStyle.Label>
                        <FormStyle.DatePickerStyle
                          slots={{ openPickerIcon: calendarIcon }}
                          sx={{ width: 260, fontSize: '15px' }}
                          format="DD/MM/YYYY"
                          name="openingRequestDate"
                          value={formData.openingRequestDate}
                          onChange={handleInputChangeDate}
                        />
                      </div>
                    </div>
                    <div className="col d-flex flex-column mt-3">
                      <FormStyle.Label>Total de Vagas*</FormStyle.Label>
                      <FormStyle.Field
                        type="number"
                        name="totalVacancies"
                        value={formData.totalVacancies || ''}
                        min={1}
                        onChange={handleInputChange}
                        onBlur={() => handleBlur('totalVacancies')}
                        style={{
                          border:
                            isDisabled || getFieldValidation('totalVacancies')
                              ? 'solid red 1px'
                              : ''
                        }}
                      />
                    </div>
                    <div className="col d-flex flex-column mt-3">
                      <FormStyle.Label>Total de Indicação*</FormStyle.Label>
                      <FormStyle.Field
                        type="number"
                        name="totalReferrals"
                        value={formData.totalReferrals || ''}
                        onChange={handleInputChange}
                        onBlur={() => handleBlur('totalReferrals')}
                        style={{
                          border:
                            isDisabled || getFieldValidation('totalReferrals')
                              ? 'solid red 1px'
                              : ''
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* endregion */}
            </div>
            <div
              style={{
                display:
                  currentPage + 1 === EIntershipStepsEnum.SECONDSTEP
                    ? 'block'
                    : 'none'
              }}
            >
              {/* #region SecondStepHTML start */}
              <div className="row">
                <div className="col-3">
                  <RadioGroup
                    className="mt-3"
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="contractType"
                    value={formData.contractType}
                    // onChange={handleInputChangeDefault}
                  >
                    <FormStyle.Label>Tipo de contratação</FormStyle.Label>
                    <div className="d-flex align-items-center">
                      <FormControlLabel
                        value="D"
                        disabled
                        control={<Radio style={{ color: location.state }} />}
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Empresa
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="I"
                        disabled
                        control={<Radio style={{ color: location.state }} />}
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Isbet (Repasse)
                          </span>
                        }
                      />
                    </div>
                  </RadioGroup>
                </div>
                <div className="col-4">
                  <div className="mt-2">
                    <FormStyle.Label>Tipo de bolsa*</FormStyle.Label>
                    <Autocomplete
                      id="scholarshipType"
                      options={internshipService.scholarshipType}
                      sx={{
                        minWidth: 200,
                        '& .MuiInputBase-root': {
                          height: 40,
                          paddingTop: 0,
                          border: getFieldValidation('scholarshipType')
                            ? 'solid red 1px'
                            : ''
                        }
                      }}
                      value={
                        internshipService.scholarshipType.find(
                          option => option.value === formData.scholarshipType
                        ) || { id: 0, label: '', value: '' }
                      }
                      getOptionLabel={option => option.label}
                      getOptionKey={option => option.id}
                      disableClearable={true}
                      onChange={(event, value) =>
                        handleInputChangeAutoComplete('scholarshipType', value)
                      }
                      onBlur={() => handleBlur('scholarshipType')}
                      renderInput={params => (
                        <TextField
                          {...params}
                          placeholder="Selecione"
                          InputLabelProps={{ shrink: false }}
                        />
                      )}
                      popupIcon={<IoIosArrowDown />}
                    />
                  </div>
                </div>
                <div className="col-3 d-flex flex-column mt-3">
                  <FormStyle.Label>Valor da bolsa</FormStyle.Label>
                  <NumericFormat
                    value={formData.scholarshipAmount?.toString() || ''}
                    prefix={
                      formData.scholarshipType ===
                      EScholarshipTypeEnum.PERCENTEGE
                        ? '%'
                        : 'R$'
                    }
                    customInput={TextField}
                    name="scholarshipAmount"
                    onChange={handleInputChangeMasked}
                    inputProps={{
                      style: { height: '8px' }
                    }}
                    placeholder={
                      formData.scholarshipType ===
                      EScholarshipTypeEnum.PERCENTEGE
                        ? '%'
                        : 'R$'
                    }
                    thousandSeparator="."
                    decimalSeparator=","
                  />
                </div>
              </div>
              <div className="mt-5">
                <SecondStepStyle.Label>Benefícios</SecondStepStyle.Label>
                <div className="row mt-2">
                  <div className="d-flex">
                    <FormControlLabel
                      className="me-5"
                      control={
                        <Checkbox
                          style={{ color: location.state }}
                          checked={formData.hasTransportationAllowance}
                          onChange={event =>
                            handlerCheckbox(
                              'hasTransportationAllowance',
                              event.target.checked
                            )
                          }
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          Auxílio transporte
                        </span>
                      }
                    />
                    <FormControlLabel
                      className="mx-5"
                      control={
                        <Checkbox
                          style={{ color: location.state }}
                          checked={formData.hasMedicalAssistance}
                          onChange={event =>
                            handlerCheckbox(
                              'hasMedicalAssistance',
                              event.target.checked
                            )
                          }
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          Assistência médica
                        </span>
                      }
                    />

                    <FormControlLabel
                      className="mx-5"
                      control={
                        <Checkbox
                          style={{ color: location.state }}
                          checked={formData.hasMealVoucher}
                          onChange={event =>
                            handlerCheckbox(
                              'hasMealVoucher',
                              event.target.checked
                            )
                          }
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          Auxílio refeição
                        </span>
                      }
                    />
                    <FormControlLabel
                      className="mx-5"
                      control={
                        <Checkbox
                          style={{ color: location.state }}
                          checked={formData.hasFoodVoucher}
                          onChange={event =>
                            handlerCheckbox(
                              'hasFoodVoucher',
                              event.target.checked
                            )
                          }
                        />
                      }
                      label={
                        <span
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            whiteSpace: 'nowrap'
                          }}
                        >
                          Auxílio alimentação
                        </span>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-3 d-flex flex-column mt-3">
                  <FormStyle.Label>Valor do auxílio refeição</FormStyle.Label>
                  <NumericFormat
                    disabled={!formData.hasMealVoucher}
                    value={formData.mealVoucherAmount?.toString() || ''}
                    prefix="R$"
                    customInput={TextField}
                    name="mealVoucherAmount"
                    onChange={handleInputChangeMasked}
                    inputProps={{
                      style: {
                        height: '8px',
                        border: getFieldValidation('mealVoucherAmount')
                          ? 'solid red 1.5px'
                          : '',
                        borderRadius: '5px'
                      }
                    }}
                    placeholder="R$"
                    thousandSeparator="."
                    decimalSeparator=","
                    onBlur={() => handleBlur('mealVoucherAmount')}
                  />
                </div>
                <div className="col-3 d-flex flex-column mt-3">
                  <FormStyle.Label>
                    Valor do auxílio alimentação
                  </FormStyle.Label>
                  <NumericFormat
                    disabled={!formData.hasFoodVoucher}
                    value={formData.foodVoucherAmount?.toString() || ''}
                    prefix="R$"
                    customInput={TextField}
                    name="foodVoucherAmount"
                    onChange={handleInputChangeMasked}
                    inputProps={{
                      style: {
                        height: '8px',
                        border: getFieldValidation('foodVoucherAmount')
                          ? 'solid red 1.5px'
                          : '',
                        borderRadius: '5px'
                      }
                    }}
                    placeholder="R$"
                    thousandSeparator="."
                    decimalSeparator=","
                    onBlur={() => handleBlur('foodVoucherAmount')}
                  />
                </div>
                <div className="col-6 d-flex flex-column mt-3">
                  <FormStyle.Label>Descrição de benefícios</FormStyle.Label>
                  <FormStyle.Field
                    name="benefitsDescription"
                    value={formData.benefitsDescription}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              {/* endregion */}
            </div>
            <div
              style={{
                display:
                  currentPage + 1 === EIntershipStepsEnum.THIRDSTEP
                    ? 'block'
                    : 'none'
              }}
            >
              {/* #region ThirdStepHTML start */}
              <div className="row mt-2">
                <div className="col-2 d-flex flex-column mt-3">
                  <FormStyle.Label>Cep da empresa*</FormStyle.Label>
                  <FormStyle.FieldMask
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('zipCode')}
                    autoComplete="off"
                    mask="00000-000"
                    style={{
                      border: getFieldValidation('zipCode')
                        ? 'solid red 1px'
                        : ''
                    }}
                  />
                </div>
                <div className="col-6 d-flex flex-column mt-3">
                  <FormStyle.Label>Rua*</FormStyle.Label>
                  <FormStyle.Field
                    name="streetAddress"
                    value={formData.streetAddress}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('streetAddress')}
                    style={{
                      border: getFieldValidation('streetAddress')
                        ? 'solid red 1px'
                        : ''
                    }}
                  />
                </div>
                <div className="col-2 d-flex flex-column mt-3">
                  <FormStyle.Label>Número*</FormStyle.Label>
                  <FormStyle.Field
                    name="streetNumber"
                    value={formData.streetNumber}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('streetNumber')}
                    style={{
                      border: getFieldValidation('streetNumber')
                        ? 'solid red 1px'
                        : ''
                    }}
                  />
                </div>
                <div className="col-2 d-flex flex-column mt-3">
                  <FormStyle.Label>Complemento</FormStyle.Label>
                  <FormStyle.Field
                    name="complement"
                    value={formData.complement}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2 d-flex flex-column mt-3">
                  <FormStyle.Label>Estado*</FormStyle.Label>
                  <FormStyle.Field
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('state')}
                    style={{
                      border:
                        isDisabled || getFieldValidation('state')
                          ? 'solid red 1px'
                          : ''
                    }}
                  />
                </div>
                <div className="col-3 d-flex flex-column mt-3">
                  <FormStyle.Label>Cidade*</FormStyle.Label>
                  <FormStyle.Field
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('city')}
                    style={{
                      border:
                        isDisabled || getFieldValidation('city')
                          ? 'solid red 1px'
                          : ''
                    }}
                  />
                </div>
                <div className="col-3 d-flex flex-column mt-3">
                  <FormStyle.Label>Bairro*</FormStyle.Label>
                  <FormStyle.Field
                    name="neighborhood"
                    value={formData.neighborhood}
                    onChange={handleInputChange}
                    onBlur={() => handleBlur('neighborhood')}
                    style={{
                      border:
                        isDisabled || getFieldValidation('neighborhood')
                          ? 'solid red 1px'
                          : ''
                    }}
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-2 d-flex flex-column mt-3">
                  <FormStyle.Label>Latitude</FormStyle.Label>
                  <FormStyle.Field
                    disabled
                    name="latitude"
                    value={
                      formData.latitude != null
                        ? formData.latitude.toString()
                        : ''
                    }
                    style={{
                      border: getFieldValidation('latitude')
                        ? 'solid red 1px'
                        : ''
                    }}
                    onBlur={() => handleBlur('latitude')}
                  />
                </div>
                <div className="col-2 d-flex flex-column mt-3">
                  <FormStyle.Label>Longitude</FormStyle.Label>
                  <FormStyle.Field
                    disabled
                    name="longitude"
                    value={
                      formData.longitude != null
                        ? formData.longitude.toString()
                        : ''
                    }
                    style={{
                      border: getFieldValidation('longitude')
                        ? 'solid red 1px'
                        : ''
                    }}
                    onBlur={() => handleBlur('longitude')}
                  />
                </div>
              </div>
              <Button
                variant="outline-danger"
                type="button"
                color="error"
                className="mt-5 col-2"
                onClick={event => getLatLngFromAddress(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  minWidth: '250px'
                }}
              >
                <BsGeoAlt /> OBTER LOCALIZAÇÂO
              </Button>
              {/* endregion */}
            </div>
            <div
              style={{
                display:
                  currentPage + 1 === EIntershipStepsEnum.FOURTHSTEP
                    ? 'block'
                    : 'none'
              }}
            >
              {/* #region ThirdStepHTML start */}
              <div className="row mt-2">
                <div className="col mt-2">
                  <FormStyle.Label>Nível de ensino</FormStyle.Label>
                  <Autocomplete
                    disablePortal
                    id="educationalLevels"
                    options={internshipService.educationalLevels}
                    sx={{
                      minWidth: 200,
                      '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
                    }}
                    value={
                      internshipService.educationalLevels.find(
                        option => option.id === formData.educationLevel
                      ) || { id: 0, value: '', label: '' }
                    }
                    getOptionLabel={option => option.label}
                    getOptionKey={option => option.id}
                    disableClearable={true}
                    noOptionsText="Nenhum resultado encontrado"
                    onChange={handleImputLevel}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Selecione"
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                    popupIcon={<IoIosArrowDown />}
                  />
                </div>
                <div className="col mt-2">
                  <FormStyle.Label>Disponibilidade</FormStyle.Label>
                  <Autocomplete
                    disablePortal
                    id="availabilities"
                    options={internshipService.availabilities}
                    sx={{
                      minWidth: 200,
                      '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
                    }}
                    getOptionLabel={option => option.label}
                    getOptionKey={option => option.value}
                    disableClearable={true}
                    noOptionsText="Nenhum resultado encontrado"
                    onChange={handleInputAvailabilitiesChange}
                    value={
                      internshipService.availabilities.find(
                        option => option.value === formData.availability
                      ) || { id: 0, value: '', label: '' }
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Selecione "
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                    popupIcon={<IoIosArrowDown />}
                  />
                </div>

                <div className="col mt-2">
                  <FormStyle.Label>Tipo de Curso</FormStyle.Label>
                  <Autocomplete
                    disablePortal
                    id="courseTypes"
                    options={internshipService.courseTypes}
                    sx={{
                      minWidth: 200,
                      '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
                    }}
                    value={internshipService.courseTypes.find(
                      option => option.id === formData.courseType
                    )}
                    getOptionLabel={option => option.label}
                    getOptionKey={option => option.id}
                    disableClearable={true}
                    noOptionsText="Nenhum resultado encontrado"
                    onChange={handleInputCourseTypeChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Selecione "
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                    popupIcon={<IoIosArrowDown />}
                  />
                </div>
                <div className="col d-flex flex-column mt-3">
                  <FormStyle.Label>
                    Cursando ({' '}
                    {formData.courseType === EPeriodsEnum.ANNUAL
                      ? 'anual'
                      : 'semestre'}
                    )
                  </FormStyle.Label>
                  <Box>
                    <Slider
                      sx={{ color: location.state }}
                      marks={marks}
                      step={1}
                      value={formData.studying}
                      valueLabelDisplay="auto"
                      min={
                        formData.courseType === EPeriodsEnum.ANNUAL
                          ? MINANN
                          : MINSEMI
                      }
                      max={
                        formData.courseType === EPeriodsEnum.ANNUAL
                          ? MAXANN
                          : MAXSEMI
                      }
                      onChange={handleInputStudyng}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                        {formData.courseType === EPeriodsEnum.ANNUAL
                          ? MINANN
                          : MINSEMI}
                      </Typography>
                      <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                        {formData.courseType === EPeriodsEnum.ANNUAL
                          ? MAXANN
                          : MAXSEMI}
                      </Typography>
                    </Box>
                  </Box>
                </div>

                <div className="col">
                  <FormStyle.Label>Sexo</FormStyle.Label>
                  <div className="row mt-0">
                    <div className="d-flex">
                      <FormControlLabel
                        className="me-2"
                        control={
                          <Checkbox
                            checked={formData.gender.includes('M')}
                            onChange={() => handleCheckboxGenDeChange('M')}
                            style={{ color: location.state }}
                          />
                        }
                        label="Masculino"
                      />
                      <FormControlLabel
                        className="mx-2"
                        control={
                          <Checkbox
                            checked={formData.gender.includes('F')}
                            onChange={() => handleCheckboxGenDeChange('F')}
                            style={{ color: location.state }}
                          />
                        }
                        label="Feminino"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col d-flex flex-column mt-3">
                  <FormStyle.Label>
                    Distãncia máxima até o candidato
                  </FormStyle.Label>
                  <Box>
                    <Slider
                      sx={{ color: location.state }}
                      marks={marks}
                      step={1}
                      value={formData.maxDistanceToCandidate}
                      valueLabelDisplay="auto"
                      min={MINKM}
                      max={MAXKM}
                      onChange={handleChangeRangeDistance}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                        {MINKM} Km
                      </Typography>
                      <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                        {MAXKM} Km
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div className="col d-flex flex-column mt-3">
                  <FormStyle.Label>Distãncia de até</FormStyle.Label>
                  <FormStyle.Field disabled name="state" value={'30 Km'} />
                </div>
                <div className="col d-flex flex-column mt-3">
                  <FormStyle.Label>Raio de idade</FormStyle.Label>
                  <Box>
                    <Slider
                      sx={{ color: location.state }}
                      marks={marks}
                      step={1}
                      value={formData.ageRange}
                      valueLabelDisplay="auto"
                      min={MINAGE}
                      max={MAXAGE}
                      onChange={handleChangeRangeAge}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                        {MINAGE}
                      </Typography>
                      <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                        {MAXAGE}
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div className="col d-flex flex-column mt-3"></div>
                <div className="col d-flex flex-column mt-3"></div>
              </div>
              <div className="row">
                <div className="col mt-2">
                  <FormStyle.Label>Curso</FormStyle.Label>
                  <Autocomplete
                    disabled={isCourseDisabled()}
                    multiple
                    id="courses"
                    options={courses}
                    disableCloseOnSelect
                    value={formData.courses}
                    getOptionLabel={option => option.courseName}
                    getOptionKey={option => option.courseId}
                    noOptionsText="Nenhum resultado encontrado"
                    onChange={handleCourseChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.courseName}
                      </li>
                    )}
                    sx={{
                      '& .MuiInputBase-root': {
                        minHeight: 30,
                        paddingTop: 0
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Selecione"
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                    popupIcon={<IoIosArrowDown />}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column mt-3">
                    <FormStyle.Label>Cursos e especializações</FormStyle.Label>
                    <div
                      className="d-flex w-100 flex-wrap"
                      style={{
                        border: '1px solid #CCC',
                        borderRadius: '5px',
                        padding: '8px'
                      }}
                    >
                      {informaticSkills.map((lang, index) => (
                        <button
                          key={index}
                          className="me-2 mb-1 mt-1"
                          style={{
                            height: 30,
                            backgroundColor:
                              formData.coursesAndSpecializations.includes(
                                lang.knowledgeItemSequence
                              )
                                ? location.state
                                : 'white',
                            border: '1px solid gray',
                            color: formData.coursesAndSpecializations.includes(
                              lang.knowledgeItemSequence
                            )
                              ? 'white'
                              : 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '25px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '13px'
                          }}
                          type="button"
                          onClick={() =>
                            handleCoursesAndSpecializationsChange(
                              lang.knowledgeItemSequence
                            )
                          }
                        >
                          {lang.knowledgeItemDescription}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column mt-3">
                    <FormStyle.Label>Idiomas</FormStyle.Label>
                    <div
                      className="d-flex w-100 flex-wrap"
                      style={{
                        border: '1px solid #CCC',
                        borderRadius: '5px',
                        padding: '8px'
                      }}
                    >
                      {languagesSkills.map((lang, index) => (
                        <button
                          key={index}
                          className="me-2 mb-1 mt-1"
                          style={{
                            height: 30,
                            backgroundColor: formData.languages.includes(
                              lang.knowledgeItemSequence
                            )
                              ? location.state
                              : 'white',
                            border: '1px solid gray',
                            color: formData.languages.includes(
                              lang.knowledgeItemSequence
                            )
                              ? 'white'
                              : 'black',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '25px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '13px'
                          }}
                          type="button"
                          onClick={() =>
                            handleLanguageChange(lang.knowledgeItemSequence)
                          }
                        >
                          {lang.knowledgeItemDescription}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="d-flex flex-column mt-3">
                    <FormStyle.Label>Certificações</FormStyle.Label>
                    <FormStyle.TextArea
                      style={{ resize: 'none' }}
                      name="certifications"
                      value={formData.certifications}
                      onChange={handleInputChange}
                    ></FormStyle.TextArea>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <FormStyle.Label>Classificação por indicador</FormStyle.Label>
                  <Autocomplete
                    disablePortal
                    id="indicator"
                    options={availableOptions}
                    sx={{
                      minWidth: 200,
                      '& .MuiInputBase-root': {
                        height: 40,
                        paddingTop: 0,
                        border:
                          isDisabled ||
                          getClassificationValidation(
                            'indicatorsClassification'
                          )
                            ? 'solid red 1px'
                            : ''
                      }
                    }}
                    onBlur={() =>
                      handlerBlurClassification('indicatorsClassification')
                    }
                    value={autocompleteValue}
                    getOptionLabel={option => option.name}
                    getOptionKey={option => option.id}
                    onChange={handleIndicatorSelect}
                    noOptionsText="Nenhum resultado encontrado"
                    renderInput={params => (
                      <TextField
                        {...params}
                        placeholder="Selecione "
                        name="selectedIndicator"
                        InputLabelProps={{ shrink: false }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Tooltip
                        title={option.description}
                        placement="right"
                        arrow
                      >
                        <Box
                          component="li"
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          {option.name}
                        </Box>
                      </Tooltip>
                    )}
                    popupIcon={<IoIosArrowDown />}
                  />
                  <div className="d-flex flex-wrap ">
                    {formData.indicatorsClassification.map(
                      (indicator: any, index) => (
                        <div
                          key={index}
                          style={{
                            marginTop: '5px',
                            marginRight: '5px',
                            minHeight: 30,
                            backgroundColor: location.state,
                            border: '1px solid gray',
                            color: 'white',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                            borderRadius: '25px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontSize: '13px'
                          }}
                        >
                          <Tooltip
                            title={indicator.description}
                            placement="right"
                            arrow
                          >
                            {indicator.name}
                          </Tooltip>

                          <MdCancel
                            className="ms-2"
                            size={20}
                            onClick={() => removeIndicator(indicator.id)}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="col-4 ms-5">
                  <FormStyle.Label>Classificação Geral</FormStyle.Label>
                  <div className="row">
                    <div className="d-flex">
                      {scoreRanges.map((score: any, index: number) => (
                        <FormControlLabel
                          className={index !== 0 ? 'mx-2' : 'me-2'}
                          control={
                            <Checkbox
                              checked={formData.overallClassification.includes(
                                score.id
                              )}
                              onChange={() => handleCheckboxChange(score.id)}
                              style={{
                                color: getClassificationValidation(
                                  'overallClassification'
                                )
                                  ? 'red'
                                  : location.state
                              }}
                            />
                          }
                          label={score.name}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-3">
                  <RadioGroup
                    className="mt-3"
                    aria-labelledby="fromProtectionAgencies"
                    name="fromProtectionAgencies"
                    value={formData.fromProtectionAgencies}
                    onChange={handleInputChangeDefault}
                  >
                    <FormStyle.Label>
                      Oriundos de Orgãos de Proteção
                    </FormStyle.Label>
                    <div className="d-flex align-items-center">
                      <FormControlLabel
                        value="S"
                        control={<Radio style={{ color: location.state }} />}
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Sim
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio style={{ color: location.state }} />}
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Não
                          </span>
                        }
                      />
                    </div>
                  </RadioGroup>
                </div>
                <div className="col-3">
                  <RadioGroup
                    className="mt-3"
                    aria-labelledby="allowSpecialNeeds"
                    name="allowSpecialNeeds"
                    value={formData.allowSpecialNeeds}
                    onChange={handleInputChangeDefault}
                  >
                    <FormStyle.Label>
                      Permitir Portador de Necessidades Especiais
                    </FormStyle.Label>
                    <div className="d-flex align-items-center">
                      <FormControlLabel
                        value="S"
                        control={<Radio style={{ color: location.state }} />}
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Sim
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="N"
                        control={<Radio style={{ color: location.state }} />}
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Não
                          </span>
                        }
                      />
                      <FormControlLabel
                        value="E"
                        control={<Radio style={{ color: location.state }} />}
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Exclusivo PCD
                          </span>
                        }
                      />
                    </div>
                  </RadioGroup>
                </div>
                <div className="col d-flex flex-column mt-3">
                  <FormStyle.Label>Tipo de deficiencia:</FormStyle.Label>
                  <FormStyle.Field
                    disabled={formData.allowSpecialNeeds !== 'S'}
                    name="specialNeedsType"
                    value={formData.specialNeedsType || ''}
                    onChange={handleInputChange}
                    style={{
                      border:
                        formData.allowSpecialNeeds === 'S' &&
                        getFieldValidation('specialNeedsType')
                          ? 'solid red 1px'
                          : ''
                    }}
                    onBlur={() => handleBlur('specialNeedsType')}
                  />
                </div>
              </div>
              {/* endregion */}
            </div>
          </div>
          {currentPage < 3 ? (
            <div className="d-flex w-100 next-form justify-content-end p-3 mt-3">
              <span
                className="me-3"
                onClick={goBackPage}
                style={{
                  backgroundColor: location.state,
                  borderColor: location.state
                }}
              >
                Cancelar
              </span>
              {!isDisabled && (
                <span
                  onClick={handleNextClick}
                  style={{
                    backgroundColor: location.state,
                    borderColor: location.state
                  }}
                >
                  Próximo <IoIosArrowDown />
                  <div className="form-name">
                    <span
                      style={{
                        backgroundColor: location.state,
                        borderColor: location.state
                      }}
                    >
                      {stepNames[currentPage + 1]}
                    </span>
                  </div>
                </span>
              )}
            </div>
          ) : (
            <div className="d-flex w-100 next-form justify-content-end p-3 mt-3">
              <span
                className="me-3"
                onClick={goBackPage}
                style={{
                  backgroundColor: location.state,
                  borderColor: location.state
                }}
              >
                Cancelar
              </span>
              <div className="form-name">
                <button
                  disabled={isDisabled}
                  className="btn"
                  type="button"
                  onClick={handleSave}
                  style={{
                    backgroundColor: location.state,
                    color: 'white'
                  }}
                >
                  Salvar
                </button>
              </div>
            </div>
          )}
        </OpportunityRecordStyle.Form>
      </OpportunityRecordStyle.Content>
    </OpportunityRecordStyle.Container>
  )
}

export default OpportunityRecord
