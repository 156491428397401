import dayjs from 'dayjs'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import logo from '../../../assets/images/logo.png'

export const candidatesPdf = {
  async fetchLogoAsDataURL(url: string): Promise<string> {
    const response = await fetch(url)
    const blob = await response.blob()
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })
  },

  async makePdf(candidates: any, data: any): Promise<void> {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    const logoDataUrl = await this.fetchLogoAsDataURL(logo)
    const reportTitle = [
      {
        image: logoDataUrl,
        width: 50,
        alignment: 'left',
        margin: [10, 2, 0, 0]
      }
    ]

    const dados = candidates.map((candidate: any) => {
      return [
        { text: candidate.id, fontSize: 7, margin: [0, 2, 0, 2] },
        { text: candidate.name, fontSize: 7, margin: [0, 2, 0, 2] },
        { text: candidate.email, fontSize: 7, margin: [0, 2, 0, 2] },
        { text: candidate.phone, fontSize: 7, margin: [0, 2, 0, 2] },
        { text: candidate.type, fontSize: 7, margin: [0, 2, 0, 2] },
        { text: candidate.area, fontSize: 7, margin: [0, 2, 0, 2] },
        { text: candidate.company, fontSize: 7, margin: [0, 2, 0, 2] }
      ]
    })

    const details = [
      {
        text: `Lista de candidatos para entrevista para ${dayjs(
          data.date || new Date()
        ).format('DD/MM/YYYY')}, das ${dayjs(data.start || new Date()).format(
          'HH:mm'
        )} às ${dayjs(data.end || new Date()).format('HH:mm')}`,
        fontSize: 12,
        bold: true,
        margin: [0, 15, 0, 10] // left, top, right, bottom
      },
      {
        table: {
          headerRows: 1,
          widths: ['auto', '*', '*', 'auto', 'auto', '*', '*'],
          body: [
            [
              { text: 'Código', style: 'tableHeader', fontSize: 8 },
              { text: 'Nome', style: 'tableHeader', fontSize: 8 },
              { text: 'E-mail', style: 'tableHeader', fontSize: 8 },
              { text: 'Telefone', style: 'tableHeader', fontSize: 8 },
              { text: 'Tipo', style: 'tableHeader', fontSize: 8 },
              { text: 'Área', style: 'tableHeader', fontSize: 8 },
              { text: 'Empresa', style: 'tableHeader', fontSize: 8 }
            ],
            ...dados
          ]
        },
        layout: 'lightHorizontalLines'
      }
    ]

    function Rodape(currentPage: any, pageCount: any) {
      return [
        {
          text: currentPage + ' / ' + pageCount,
          alignment: 'right',
          fontSize: 9,
          margin: [0, 10, 20, 0]
        }
      ]
    }

    const docDefinitios: any = {
      pageSize: 'A4',
      pageMargins: [15, 50, 15, 40],
      header: [reportTitle],
      content: [details],
      footer: Rodape
    }
    const title = `lista-entrevista-${dayjs(data.date || new Date()).format(
      'DD/MM/YYYY'
    )}.pdf`

    pdfMake.createPdf(docDefinitios).download(title)
  }
}
