import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { IndicatorRecordStyle } from './style'
import BradCrumbs from '../../../../shared/components/common/breadcrumbs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FormStyle } from '../../../../shared/styles/form.style'
import { FormControlLabel, Checkbox } from '@mui/material'
import CustomSpinner from '../../../../shared/components/common/custom-spinner'
import { TIndicatorForm } from '../../../../core/models/indicator.model'
import './style.css'
import { notification } from '../../../../shared/services'
import { indicatorService } from '../../../../shared/services/indicators'

const IndicatorRecord: React.FC = () => {
  let { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  if (!location.state) {
    location.state = '#004699'
  }

  const breadCrumps = !id
    ? ['Indicadores', 'Cadastro de Indicador']
    : ['Indicadores', 'Edição de Indicador']

  const [isloading, setLoading] = useState<boolean>(false)
  const form: TIndicatorForm = {
    id: null,
    name: '',
    description: '',
    active: true
  }

  const goBackPage = () => {
    navigate('/indicators')
  }

  const [formData, setFormData] = useState<TIndicatorForm>(form)

  const validation = (): boolean => {
    const mandatoryItens = ['name', 'description']
    const invalidFields: string[] = []
    for (const [key, value] of Object.entries(formData) as [
      keyof TIndicatorForm,
      any
    ][]) {
      if (mandatoryItens.includes(key) && (value === null || value === '')) {
        invalidFields.push(key)
      }
    }

    if (invalidFields.length > 0) {
      const allTrueTouchedFields = Object.fromEntries(
        Object.keys(touchedFields).map(key => [key, true])
      )
      setTouchedFields(allTrueTouchedFields)
      notification.error('Atenção', 'Campos obrigatórios não informados')
      return false
    }
    return true
  }

  const prepareToSave = async (form: TIndicatorForm) => {
    const newObject: any = Object.assign({}, form)
    return newObject
  }

  const [touchedFields, setTouchedFields] = useState<any>({
    name: false,
    description: false
  })

  const handlerCheckbox = (name: string, isChecked: boolean) => {
    setFormData(prevData => ({ ...prevData, [name]: isChecked }))
  }

  const handleInputChangeDefault = (event: ChangeEvent<any>) => {
    const { name, value } = event.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const getFieldValidation = (field: keyof TIndicatorForm) => {
    return (
      touchedFields[field] &&
      (formData[field] === null || formData[field] === '')
    )
  }

  const handleBlur = (field: keyof TIndicatorForm) => {
    setTouchedFields({ ...touchedFields, [field]: true })
  }

  const handleSave = async () => {
    if (!validation()) return
    try {
      const preparedForm = await prepareToSave(formData)
      setLoading(true)
      const result = !id
        ? await indicatorService.createIndicator(preparedForm)
        : await indicatorService.updateIndicator(preparedForm)

      if (result) {
        notification.success('Sucesso', result.message)
        goBackPage()
        setLoading(false)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handlerLoadRequestData = useCallback((result: any) => {
    setFormData(prevData => ({
      ...prevData,
      id: result.id,
      name: result.name,
      description: result.description,
      active: result.active
    }))
  }, [])

  const handlerGetIndicator = useCallback(
    async (id: number): Promise<void> => {
      try {
        setLoading(true)
        const result = await indicatorService.getIndicatorById(id)
        handlerLoadRequestData(result)
      } catch {
      } finally {
        setLoading(false)
      }
    },
    [handlerLoadRequestData]
  )

  useEffect(() => {
    id && handlerGetIndicator(Number(id))
  }, [handlerGetIndicator, id])

  return (
    <IndicatorRecordStyle.Container className="w-100">
      <BradCrumbs
        names={breadCrumps}
        paths={['/indicators']}
        disabled={false}
        showArrow={true}
        backGroundColor={location.state}
      />
      <CustomSpinner state={location.state} show={isloading} />
      <IndicatorRecordStyle.Title>
        {!id ? 'Novo Indicador' : 'Edição de indicador '}
      </IndicatorRecordStyle.Title>
      <IndicatorRecordStyle.Content>
        <IndicatorRecordStyle.Form>
          <div className="w-100 content-container p-3 pt-0 overflow-auto">
            <div>
              <div className="w-100 ">
                <div>
                  <div className="col  d-flex mt-3">
                    <div className="col-6 d-flex flex-column">
                      <FormStyle.Label>Nome do Indicador</FormStyle.Label>
                      <FormStyle.Field
                        name="name"
                        value={formData.name}
                        onChange={handleInputChangeDefault}
                        style={{
                          border: getFieldValidation('name')
                            ? 'solid red 1px'
                            : ''
                        }}
                        onBlur={() => handleBlur('name')}
                      />
                    </div>
                    <div className="col-3 d-flex flex-column  ">
                      <FormControlLabel
                        style={{ marginTop: '19px' }}
                        className="ms-3 "
                        control={
                          <Checkbox
                            style={{ color: location.state }}
                            checked={formData.active}
                            onChange={event =>
                              handlerCheckbox('active', event.target.checked)
                            }
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Ativo
                          </span>
                        }
                      />
                    </div>
                  </div>
                  <div className="col mt-3">
                    <div className="d-flex flex-column">
                      <FormStyle.Label>Descrição</FormStyle.Label>
                      <FormStyle.TextArea
                        rows={4}
                        className="custom-textarea"
                        value={formData.description}
                        placeholder="O conteúdo descrito neste campo irá ajudar na seleção de um indicador"
                        name="description"
                        onChange={handleInputChangeDefault}
                        style={{
                          resize: 'none',
                          border: getFieldValidation('description')
                            ? 'solid red 1px'
                            : ''
                        }}
                        onBlur={() => handleBlur('description')}
                      ></FormStyle.TextArea>
                    </div>
                  </div>
                  <div className="d-flex w-100 next-form justify-content-end p-3 mt-3">
                    <span
                      className="me-3"
                      onClick={goBackPage}
                      style={{
                        backgroundColor: location.state,
                        borderColor: location.state
                      }}
                    >
                      Cancelar
                    </span>
                    <div className="form-name">
                      <button
                        className="btn"
                        type="button"
                        onClick={handleSave}
                        style={{
                          backgroundColor: location.state,
                          color: 'white'
                        }}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </IndicatorRecordStyle.Form>
      </IndicatorRecordStyle.Content>
    </IndicatorRecordStyle.Container>
  )
}

export default IndicatorRecord
