import Autocomplete from '@mui/material/Autocomplete'
import { styled } from '@mui/material/styles'

const CustomAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-popupIndicator': {
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease'
  },
  '&[aria-expanded="true"] .MuiAutocomplete-popupIndicator': {
    transform: 'rotate(180deg)'
  }
})

export default CustomAutocomplete
