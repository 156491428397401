import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 50px;
  height: 100dvh;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.2);
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 63px;
    height: 61px;
  }
  span {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    cursor: pointer;
    &.blocked {
      cursor: not-allowed !important;
    }
  }
  .item {
    display: flex;
    align-items: center;
    color: gray;
    border-radius: 5px;
    padding: 12px 8px;
    transition: 0.25s;
    margin: 5px 7px;

    span {
      font-size: 15px;
    }

    &:hover {
      color: white;
    }
  }
`
