import { FaUsers, FaRegUser, FaRegFileAlt } from 'react-icons/fa'
import { HiMenuAlt2 } from 'react-icons/hi'
import { LuUserSquare2 } from 'react-icons/lu'
import { MdOutlinePostAdd, MdOutlineManageAccounts } from 'react-icons/md'
import { TMenuItem } from '../../models'

export function getMenu(): TMenuItem[] {
  return [
    {
      blocked: false,
      path: '/dashboard',
      icon: HiMenuAlt2,
      label: '',
      labelBackGroundColor: '#bc0823',
      roverClass: 'hoverRed',
      submenu: []
    },
    {
      blocked: true,
      path: '/registration',
      icon: MdOutlinePostAdd,
      label: 'Cadastro',
      labelBackGroundColor: '#bc0823',
      roverClass: 'hoverRed',
      submenu: [
        {
          name: 'Candidatos',
          path: '/candidate',
          labelBackGroundColor: '#bc0823'
        },
        { name: 'Colaboradores', path: '/not-implemented' },
        { name: 'Empresas', path: 'not-implemented' },
        {
          name: 'Faixa de Pontuação',
          path: '/score-range',
          labelBackGroundColor: '#bc0823'
        },
        { name: 'Instituições de ensino', path: 'not-implemented' },
        {
          name: 'Indicadores',
          path: '/indicators',
          labelBackGroundColor: '#bc0823'
        },
        { name: 'Curso IES', path: 'not-implemented' },
        {
          name: 'Configuração de Agenda',
          path: '/registration/calendar',
          labelBackGroundColor: '#bc0823'
        },
        { name: 'Parametros Agenda', path: 'not-implemented' }
      ]
    },
    {
      blocked: true,
      path: '/guidance',
      icon: FaUsers,
      label: 'Orientação',
      labelBackGroundColor: '#bc0823',
      roverClass: 'hoverRed',
      submenu: [{ name: 'Orientação Profissional ', path: 'not-implemented' }]
    },
    {
      blocked: true,
      path: '/intership',
      icon: LuUserSquare2,
      label: 'Estágio',
      labelBackGroundColor: '#004699',
      roverClass: 'hoverBlue',
      submenu: [
        {
          name: 'Cadastro de Oportunidade ',
          path: '/intership/opportunity-record',
          labelBackGroundColor: '#004699'
        },
        {
          name: 'Processo Seletivo',
          path: 'not-implemented',
          labelBackGroundColor: '#004699'
        },
        {
          name: 'Gerar Contrato',
          path: 'not-implemented',
          labelBackGroundColor: '#004699'
        },
        {
          name: 'Consultar Candidatos',
          path: 'not-implemented',
          labelBackGroundColor: '#004699'
        }
      ]
    },
    {
      blocked: true,
      path: '/apprendice',
      icon: FaRegUser,
      label: 'Jovem Aprendiz',
      labelBackGroundColor: '#bc0823',
      roverClass: 'hoverRed',
      submenu: [
        {
          name: 'Cadastro de Oportunidade ',
          path: '/apprendice/opportunity-record/not-implemented',
          labelBackGroundColor: '#bc0823'
        },
        {
          name: 'Processo Seletivo',
          path: 'not-implemented',
          labelBackGroundColor: '#bc0823'
        },
        {
          name: 'Gerar Contrato',
          path: 'not-implemented',
          labelBackGroundColor: '#bc0823'
        },
        {
          name: 'Consultar Candidatos',
          path: 'not-implemented',
          labelBackGroundColor: '#bc0823'
        }
      ]
    },
    {
      blocked: true,
      path: '/reports',
      icon: FaRegFileAlt,
      label: 'Relatórios',
      labelBackGroundColor: '#bc0823',
      roverClass: 'hoverRed',
      submenu: [
        { name: 'Gestão', path: 'not-implemented' },
        { name: 'Acadêmico', path: 'not-implemented' },
        { name: 'Financeiro', path: 'not-implemented' },
        { name: 'Outros', path: 'not-implemented' }
      ]
    },
    {
      blocked: true,
      path: '/any',
      icon: MdOutlineManageAccounts,
      label: 'Administração',
      labelBackGroundColor: '#bc0823',
      roverClass: 'hoverGold',
      submenu: [
        { name: 'Geral', path: 'not-implemented' },
        { name: 'Financeira ', path: 'not-implemented' },
        { name: 'Marketing ', path: 'not-implemented' },
        { name: 'Pessoal ', path: 'not-implemented' }
      ]
    }
  ]
}
