import http from '../../../core/services/api/request'
const basePath = 'InterviewSchedule/'

export const calendarService = {
  async getEvents(pagination: any): Promise<any> {
    let url = `${basePath}GetAllInterviewSchedule`
    if (pagination) {
      const params = new URLSearchParams()
      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async createEvent(data: any): Promise<any> {
    return await http.post(`${basePath}createInteviewSchedule`, data)
  },

  async updateEvent(data: any): Promise<any> {
    return await http.put(`${basePath}UpdateInterviewSchedule`, data)
  },

  async deleteEvent(eventId: number): Promise<any> {
    return await http.put(
      `${basePath}deleteInterviewSchedule?eventId=${eventId}`
    )
  },

  async getEventCandidates(eventId: number): Promise<any> {
    return [
      {
        id: 1,
        name: 'Cláudia Silva',
        email: 'claudia@gmail.com',
        phone: '(11) 99080-8372',
        type: 'Estágio',
        area: 'Administrativo',
        company: 'Tintas do Mário'
      },
      {
        id: 2,
        name: 'Sidney Campos',
        email: 'sidnei.c@gmail.com',
        phone: '(11) 92880-9012',
        type: 'Estágio',
        area: 'Informática',
        company: 'Tintas do Mário'
      },
      {
        id: 3,
        name: 'Carla Sampaio',
        email: 'sampaiocarla@gmail.com',
        phone: '(11) 92880-9012',
        type: 'Jovem Aprendiz',
        area: 'Informática',
        company: 'Tintas do Mário'
      },
      {
        id: 4,
        name: 'Thiago Pereira',
        email: 't.pereira@gmail.com',
        phone: '(11) 99080-8372',
        type: 'Estágio',
        area: 'Administrativo',
        company: 'Tintas do Mário'
      },
      {
        id: 5,
        name: 'Silvio Ramos Pacheco',
        email: 'silvio_ramos@gmail.com',
        phone: '(11) 99080-8372',
        type: 'Jovem Aprendiz',
        area: 'Serralheiro',
        company: 'Tintas do Mário'
      },
      {
        id: 6,
        name: 'Thiago Castor',
        email: 'claudia@gmail.com',
        phone: '(11) 99080-8372',
        type: 'Estágio',
        area: 'Administrativo',
        company: 'Tintas do Mário'
      },
      {
        id: 7,
        name: 'Thiago Castor',
        email: 'claudia@gmail.com',
        phone: '(11) 99080-8372',
        type: 'Estágio',
        area: 'Administrativo',
        company: 'Tintas do Mário'
      },
      {
        id: 8,
        name: 'Thiago Castor',
        email: 'claudia@gmail.com',
        phone: '(11) 99080-8372',
        type: 'Estágio',
        area: 'Administrativo',
        company: 'Tintas do Mário'
      },
      {
        id: 9,
        name: 'Thiago Castor',
        email: 'claudia@gmail.com',
        phone: '(11) 99080-8372',
        type: 'Estágio',
        area: 'Administrativo',
        company: 'Tintas do Mário'
      }
    ]
    // return await http.get(
    //   `${basePath}getEventCandidates?eventId=${eventId}`
    // )
  }
}
