import React, { useEffect, useRef, useState } from 'react'
import BradCrumbs from '../../shared/components/common/breadcrumbs'
import { useLocation } from 'react-router-dom'
import { CalendarStyle } from './style'
import { Autocomplete, TextField } from '@mui/material'
import { IoIosArrowDown } from 'react-icons/io'
import {
  TFacilitiesFilters,
  TFacility,
  TInterviwerFilters,
  TRequestCalendarPagination
} from '../../shared/models'
import { commonService } from '../../shared/services'
import CustomSpinner from '../../shared/components/common/custom-spinner'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import ptBrLocale from '@fullcalendar/core/locales/pt-br'
import { FaUser } from 'react-icons/fa'
import { calendarService } from '../../shared/services/calendar'
import EventModal from './components/event-modal'

const Calendar: React.FC = () => {
  const location = useLocation()
  const [showModal, setShowModal] = useState(false)
  const [facilitiesFilters, setFacilitiesFilters] = useState<
    TFacilitiesFilters[]
  >([])
  const [interViwerFilters, setInterViwerFilters] = useState<
    TInterviwerFilters[]
  >([])
  const [events, setEvents] = useState<any[]>([])
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [isloading, setLoading] = useState<boolean>(false)
  const [pagination, setPagination] = useState<TRequestCalendarPagination>({
    facilityId: null,
    interviwerId: 0
  })

  const handlerSetFacility = (value: any) => {
    setPagination(prevData => ({
      ...prevData,
      facilityId: value.id,
      page: 1
    }))
  }

  const handlerSetInterviewer = (value: any) => {
    setPagination(prevData => ({
      ...prevData,
      interviwerId: value.id,
      page: 1
    }))
  }

  const handlerLoadFilters = async (): Promise<void> => {
    try {
      setLoading(true)
      const facilitiesResult = await commonService.getFacilitiesFilters()
      const interviersResult = await commonService.getInterviwersFilters()
      setFacilitiesFilters(facilitiesResult)
      setInterViwerFilters(interviersResult)
      handlerSetFacility(facilitiesResult[0].id)
      setPagination(prevData => ({
        ...prevData,
        facilityId: facilitiesResult[0].id
      }))
    } catch {
    } finally {
      setLoading(false)
    }
  }
  const handlerLoadEvents = async (): Promise<void> => {
    if (!pagination.facilityId) return
    setLoading(true)
    const events = await calendarService.getEvents(pagination)
    setEvents(events)
    setLoading(false)
  }

  const handleDateClick = (arg: any) => {
    setShowModal(true)
  }

  const handleEventClick = (arg: any) => {
    // if (arg.event._def.extendedProps.isBlocked) return
    const event = events.find(e => e.id === Number(arg.event._def.publicId))
    setSelectedEvent(event)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setSelectedEvent(null)
  }

  const handlerSaveModal = () => {
    setShowModal(false)
    handlerLoadEvents()
  }

  const calendarRef: any = useRef(null)

  function formatTime(time: any) {
    return new Intl.DateTimeFormat('pt-BR', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    }).format(time)
  }

  useEffect(() => {
    handlerLoadFilters()
  }, [])

  useEffect(() => {
    handlerLoadEvents()
  }, [pagination])

  return (
    <CalendarStyle.Container className="w-100">
      <BradCrumbs
        names={['Cadastro', 'Configuração de Agenda']}
        paths={[]}
        disabled={false}
        showArrow={true}
        backGroundColor={location.state}
      />
      <div
        style={{
          paddingLeft: '0.5%',
          paddingRight: '0.5%',
          marginTop: '5px',
          height: '88vh',
          overflowY: 'auto'
        }}
      >
        <div
          className="d-flex justify-content-end "
          style={{
            marginBottom: '-63px',
            marginRight: '10px',
            marginTop: '23px'
          }}
        >
          {showModal && (
            <EventModal
              show={showModal}
              onClose={handleCloseModal}
              onSave={handlerSaveModal}
              state={location.state}
              isEdition={false}
              item={selectedEvent}
              facility={pagination.facilityId}
              interviewer={pagination.interviwerId}
              buttonTitle="Salvar"
            ></EventModal>
          )}
          <div className="d-flex align-items-center justify-content-center me-5">
            <CalendarStyle.SelectedFacility>
              Unidade Isbet:
            </CalendarStyle.SelectedFacility>
            <Autocomplete
              disablePortal
              options={facilitiesFilters}
              getOptionLabel={option => option.name}
              getOptionKey={option => option.id}
              value={
                facilitiesFilters.find(
                  (f: any) => f.id === pagination.facilityId
                ) || { id: 0, name: '', situationCode: '' }
              }
              onChange={(event, newValue) => handlerSetFacility(newValue)}
              sx={{
                minWidth: 200,
                '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
              }}
              disableClearable={true}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Selecione Unidade"
                  InputLabelProps={{ shrink: false }}
                />
              )}
              renderOption={(props, option: TFacility, state, ownerState) => (
                <li {...props}>{option.name}</li>
              )}
              popupIcon={<IoIosArrowDown />}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <CalendarStyle.SelectedInterviewer>
              Entrevistador:
            </CalendarStyle.SelectedInterviewer>
            <Autocomplete
              disablePortal
              options={interViwerFilters}
              getOptionLabel={option => option.name}
              getOptionKey={option => option.id}
              value={
                interViwerFilters.find(
                  (f: any) => f.id === pagination.interviwerId
                ) || { id: 0, name: '' }
              }
              onChange={(event, newValue) => handlerSetInterviewer(newValue)}
              sx={{
                minWidth: 200,
                '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
              }}
              disableClearable={true}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Selecione Entrevistador"
                  InputLabelProps={{ shrink: false }}
                />
              )}
              renderOption={(props, option: TFacility, state, ownerState) => (
                <li {...props}>{option.name}</li>
              )}
              popupIcon={<IoIosArrowDown />}
            />
          </div>
        </div>
        <CalendarStyle.CalendarContainer>
          <CustomSpinner state={location.state || '#004699'} show={isloading} />
          <CalendarStyle.CalendarArea>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              contentHeight="auto"
              events={events}
              headerToolbar={{
                left: 'title prev,next',
                center: '',
                right: ''
              }}
              firstDay={0}
              locale={ptBrLocale}
              dayHeaderFormat={{
                weekday: 'short'
              }}
              titleFormat={{
                month: 'long'
              }}
              eventContent={arg => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    borderRadius: '4px',
                    padding: '0 5px',
                    backgroundColor: arg.event._def.extendedProps.isBlocked
                      ? '#bc08231f'
                      : '#40a81b1f',
                    color: arg.event._def.extendedProps.isBlocked
                      ? '#BC0823'
                      : '#40A81B',
                    fontSize: '9px',
                    fontWeight: 600
                  }}
                >
                  <span>
                    {formatTime(arg.event.start)} -{' '}
                    {arg.event._def.extendedProps.id}
                  </span>{' '}
                  <span>
                    {arg.event._def.extendedProps.candidates}/
                    {arg.event._def.extendedProps.vacancies}
                    <FaUser className="ms-1" size={8} />
                  </span>
                </div>
              )}
              dateClick={handleDateClick}
              eventClick={handleEventClick}
              dayMaxEventRows={4}
              moreLinkClick="popover"
            />
          </CalendarStyle.CalendarArea>
        </CalendarStyle.CalendarContainer>
      </div>
    </CalendarStyle.Container>
  )
}

export default Calendar
