import React, { useEffect, useRef, useState } from 'react'
import { DefaultTableStyle } from './style'
import './style.css'
import {
  IoIosArrowDown,
  IoMdClose,
  IoMdCloseCircleOutline
} from 'react-icons/io'
import {
  MdOutlineEdit,
  MdOutlineInfo,
  MdOutlineWatchLater
} from 'react-icons/md'
import { BsCopy } from 'react-icons/bs'
import { DefaultTableProps, TStatus } from '../../../models'
import { commonService } from '../../../services'
import { EIntershipStatusEnum } from '../../../enums'
import { FaCheck } from 'react-icons/fa6'
import { BiMessageSquareEdit } from 'react-icons/bi'
import CustomModal from '../custom-modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { Tooltip } from 'react-bootstrap'
export const DefaultTable: React.FC<DefaultTableProps> = ({
  headers,
  body,
  options,
  sortHeader,
  scroll,
  deleteVacancy,
  editVacancy,
  cloneVacancy
}: any) => {
  const [statuses, setStatuses] = useState<TStatus[]>([])
  const [showModal, setShowModal] = useState(false)
  const [tableHeaders, setTableHeaders] = useState(headers)
  const prevScrollY = useRef<number>(0)
  const [modalData, setModalData] = useState<any>({})

  const fetchStatuses = async () => {
    const statuses = await commonService.getStatus()
    setStatuses(statuses)
  }

  useEffect(() => {
    fetchStatuses()
    const handleScroll = () => {
      const currentScrollY = document.querySelector('.table-wrapper')?.scrollTop
      if (currentScrollY == null) return
      const isScrollingDown = currentScrollY > prevScrollY.current
      prevScrollY.current = currentScrollY

      const element = document.querySelector('.table-wrapper')
      if (element) {
        const isScrolledToBottom =
          element.scrollHeight - element.clientHeight <= currentScrollY + 1
        if (isScrollingDown && isScrolledToBottom) {
          scroll()
        }
      }
    }

    document
      .querySelector('.table-wrapper')
      ?.addEventListener('scroll', handleScroll)

    return () => {
      document
        .querySelector('.table-wrapper')
        ?.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollUp = () => {
    const element = document.querySelector('.table-wrapper')
    if (!element) return

    // Mover o scroll para cima em 50 pixels
    element.scrollBy({ top: -50, behavior: 'smooth' })
  }

  const sortByClick = (clickedHeader: any) => {
    if (clickedHeader.sortable) {
      const updatedHeaders = tableHeaders.map((header: any) => ({
        ...header,
        isSort: header.id === clickedHeader.id ? !header.isSort : false
      }))
      setTableHeaders(updatedHeaders)
      sortHeader(clickedHeader)
      scrollUp()
    }
  }

  function getStatusComponent(status: number) {
    const findStatus = statuses.find((st: TStatus) => st.id === status)
    switch (status) {
      case EIntershipStatusEnum.INSCRIPTION:
        return (
          <DefaultTableStyle.StatusInscription>
            <BiMessageSquareEdit className="me-2" size={20} />{' '}
            {findStatus?.label}
          </DefaultTableStyle.StatusInscription>
        )
      case EIntershipStatusEnum.INTERVIEW_ISBET:
        return (
          <DefaultTableStyle.StatusInterviewIsbet>
            <MdOutlineWatchLater className="me-2" size={20} />{' '}
            {findStatus?.label}
          </DefaultTableStyle.StatusInterviewIsbet>
        )
      case EIntershipStatusEnum.INTERVIEW_COMPANY:
        return (
          <DefaultTableStyle.StatusInterviewCompany>
            <MdOutlineWatchLater className="me-2" size={20} />{' '}
            {findStatus?.label}
          </DefaultTableStyle.StatusInterviewCompany>
        )
      case EIntershipStatusEnum.FINISHED:
        return (
          <DefaultTableStyle.StatusFinished>
            <FaCheck className="me-2" size={20} /> {findStatus?.label}
          </DefaultTableStyle.StatusFinished>
        )
      case EIntershipStatusEnum.HIRING:
        return (
          <DefaultTableStyle.StatusHiring>
            <FaCheck className="me-2" size={20} /> {findStatus?.label}
          </DefaultTableStyle.StatusHiring>
        )
      case EIntershipStatusEnum.CANCELED:
        return (
          <DefaultTableStyle.StatusCanceled>
            <IoMdCloseCircleOutline className="me-2 " size={23} />{' '}
            {findStatus?.label}
          </DefaultTableStyle.StatusCanceled>
        )
      default:
        break
    }
  }

  const handleOpenModal = (item: any) => {
    setModalData(item)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setModalData(null)
    setShowModal(false)
  }

  return (
    <div className="table-wrapper" style={{ overflowX: 'auto' }}>
      {showModal && (
        <CustomModal
          show={showModal}
          onClose={handleCloseModal}
          title="Detalhe do Oportunidade"
          icon={<MdOutlineInfo size={20} />}
          state={options.backgroundColor}
          onlyCloseBtn={true}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  borderLeft: '4px solid ' + options.backgroundColor,
                  height: '1.5em',
                  marginRight: '8px',
                  paddingLeft: '8px'
                }}
              ></div>
              <div style={{ fontWeight: 'bold' }}> {modalData.companyName}</div>
            </div>
            <div
              style={{
                marginTop: '15px',
                width: '100%',
                background: '#EFEFEF',
                padding: '4px 5px 4px 10px',
                border: '1px solid #7d8398',
                borderRadius: '6px',
                color: 'black',
                fontWeight: '500'
              }}
            >
              Vaga
            </div>
            <div style={{ marginTop: '10px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Processo Seletivo
              </span>
              : 0
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Abertura
              </span>
              :
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>SLA</span>:
            </div>
            <div
              style={{
                marginTop: '15px',
                width: '100%',
                background: '#EFEFEF',
                padding: '4px 5px 4px 10px',
                border: '1px solid #7d8398',
                borderRadius: '6px',
                color: 'black',
                fontWeight: '500'
              }}
            >
              Candidatura
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Inscrições
              </span>
              : 0
            </div>{' '}
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Agendados Entrevista ISBET
              </span>
              :
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Agendados Entrevista Empresa
              </span>
              :
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Contratação
              </span>
              : 0
            </div>
            <div
              style={{
                marginTop: '15px',
                width: '100%',
                background: '#EFEFEF',
                padding: '4px 5px 4px 10px',
                border: '1px solid #7d8398',
                borderRadius: '6px',
                color: 'black',
                fontWeight: '500'
              }}
            >
              Processos Seletivos Anteriores
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>Seleção</span>
              : 0
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Inscrições
              </span>
              : 0
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Entrevista ISBET
              </span>
              : 0
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Entrevista Empresa
              </span>
              : 0
            </div>
            <div style={{ marginTop: '5px' }}>
              <span style={{ color: 'black', fontWeight: '700' }}>
                Contratação
              </span>
              : 0
            </div>
          </div>
        </CustomModal>
      )}
      <DefaultTableStyle.CustomTable
        striped={options?.striped}
        className="w-100"
      >
        <thead
          style={{
            height: '60px',
            position: 'sticky',
            top: '0',
            zIndex: '1'
          }}
        >
          <tr>
            {tableHeaders.map((header: any) =>
              options.skipItems.length &&
              !options.skipItems.includes(header.label.toLowerCase()) ? (
                <th
                  style={{ paddingBottom: '17px', background: '#FCFCFC' }}
                  key={header.id}
                  onClick={() => sortByClick(header)}
                >
                  {header.label}
                  {header.sortable ? (
                    <IoIosArrowDown
                      className={header.isSort ? 'rotate' : 'reverse'}
                    />
                  ) : null}
                </th>
              ) : null
            )}
          </tr>
        </thead>
        <tbody>
          {body.map((row: any) => (
            <tr key={row.id}>
              {Object.keys(row)
                .filter(key => !options.skipItems.includes(key))
                .map((key, index) => (
                  <td
                    style={{
                      color: index > 0 ? '#7D8398' : 'black',
                      fontSize: '14px',
                      paddingTop: '14px',
                      width: index === 1 ? '135px' : 'auto'
                    }}
                    key={key}
                  >
                    {index === 0 ? (
                      <DefaultTableStyle.CustomSpan
                        backgroundColor={options.backgroundColor}
                        onClick={() => handleOpenModal(row)}
                      >
                        {row[key]}
                      </DefaultTableStyle.CustomSpan>
                    ) : (
                      <span style={{ whiteSpace: index === 1 ? 'nowrap' : '' }}>
                        {index === 2 ? getStatusComponent(row[key]) : row[key]}
                      </span>
                    )}
                  </td>
                ))}
              <td style={{ paddingTop: '14px' }}>
                <div className="d-flex">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">Editar</Tooltip>}
                  >
                    <DefaultTableStyle.CustomMiniButton
                      backgroundColor={options.backgroundColor}
                      onClick={() => editVacancy(row)}
                    >
                      <MdOutlineEdit />
                    </DefaultTableStyle.CustomMiniButton>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">Clonar</Tooltip>}
                  >
                    <DefaultTableStyle.CustomMiniButton
                      backgroundColor={options.backgroundColor}
                      onClick={() => cloneVacancy(row)}
                    >
                      <BsCopy />
                    </DefaultTableStyle.CustomMiniButton>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">Excluir</Tooltip>}
                  >
                    <DefaultTableStyle.CustomMiniButton
                      backgroundColor={options.backgroundColor}
                      onClick={() => deleteVacancy(row)}
                    >
                      <IoMdClose />
                    </DefaultTableStyle.CustomMiniButton>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </DefaultTableStyle.CustomTable>
    </div>
  )
}
