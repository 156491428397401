import { ScheduleTypeEnum } from '../../../core/enums/internship.enum'
import { TOpportunityForm } from '../../../core/models'
import http from '../../../core/services/api/request'
import { EPeriodsEnum, EScholarshipTypeEnum } from '../../enums'
const basePath = 'internship/'

export const internshipService = {
  opportunityType: [{ id: 1, label: 'Estágio', value: 'E' }],
  opportunityModality: [
    { id: 1, label: 'Presencial', value: 'P' },
    { id: 2, label: 'Hibrido', value: 'H' },
    { id: 3, label: 'EAD', value: 'E' }
  ],

  opportunityReasons: [
    { id: 1, label: 'Nova', value: 'N' },
    { id: 2, label: 'Reposição', value: 'R' },
    { id: 3, label: 'Transferência', value: 'T' }
  ],

  scholarshipType: [
    { id: 1, label: 'Valor mensal', value: EScholarshipTypeEnum.MONTHLY },
    { id: 2, label: 'Valor hora', value: EScholarshipTypeEnum.HOURLY },
    {
      id: 3,
      label: 'Valor % referente ao salário mínimo',
      value: EScholarshipTypeEnum.PERCENTEGE
    }
  ],
  educationalLevels: [
    { id: 1, label: 'Ensino Médio', value: '1' },
    { id: 2, label: 'Ensino Técnico', value: '2' },
    { id: 3, label: 'Ensino Superior', value: '3' }
  ],
  situationTypes: [
    { value: 1, label: 'Tipo 1' },
    { value: 2, label: 'Tipo 2' }
  ],
  areaTypes: [
    { value: 1, label: 'area 1' },
    { value: 2, label: 'area 2' }
  ],
  courseTypes: [
    { id: EPeriodsEnum.SEMIANNUAL, label: 'Semestral' },
    { id: EPeriodsEnum.ANNUAL, label: 'Anual' }
  ],
  openingReasonTypes: [
    { value: 1, label: 'Nova' },
    { value: 2, label: 'Reposição' },
    { value: 3, label: 'Transferência' }
  ],
  availabilities: [
    { id: 1, label: 'Manhã/Tarde', value: 'MT' },
    { id: 2, label: 'Tarde/Noite', value: 'TN' },
    { id: 3, label: 'Integral', value: 'IN' }
  ],
  scheduleTypes: [
    { value: ScheduleTypeEnum.Fixed, label: 'Fixo' },
    { value: ScheduleTypeEnum.Flexible, label: 'Flexível' }
  ],

  async createIntership(data: TOpportunityForm): Promise<any> {
    return await http.post(`${basePath}createInternship`, data)
  },

  async getAllIntership(pagination: any): Promise<any> {
    let url = `${basePath}getAllInternship`
    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async deleteIntership(jobVacancyId: number): Promise<any> {
    return await http.put(
      `${basePath}deleteInternship?jobVacancyId=${jobVacancyId}`
    )
  },
  async getIntershipById(jobVacancyId: number): Promise<any> {
    return await http.get(
      `${basePath}GetInternshipById?jobVacancyId=${jobVacancyId}`
    )
  },
  async updateInternship(data: TOpportunityForm): Promise<any> {
    return await http.put(`${basePath}updateInternship`, data)
  }
}
