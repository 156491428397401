import styled, { keyframes } from 'styled-components'

const slideIn = keyframes`
  from {
    top: 23px; 
  }
  to {
    top: 53px;
  }
`

export const RightTopConner = styled.div`
  position: fixed;
  top: 53px;
  right: -24px;
  width: 577px;
  height: 89px;
  transform: rotate(13deg);
  margin-top: -115px;
  border-radius: 0 0 108px 0;
  background-color: #004699;
  /* animation: ${slideIn} 1s forwards; Animar a partir da posição inicial para a posição final em 1 segundo */
`
