import Sidebar from '../../shared/components/common/side-menu'
import { TLayoutProps } from '../../shared/models'

const BaseLayout = ({ children }: TLayoutProps) => {
  return (
    <div className="d-flex content w-100 h-100 ">
      <div>
        <Sidebar />
      </div>
      <div className="flex-grow-1  mx-1 ">{children}</div>
    </div>
  )
}

export default BaseLayout
