import React from 'react'
// import BradCrumbs from '../../shared/components/common/breadcrumbs'
// import { useLocation } from 'react-router-dom'
import { Container } from './style'
import BuidingPage from '../building-page'

const Registrations: React.FC = () => {
  // const location = useLocation()
  return (
    <Container className="w-100">
      {/* <BradCrumbs
        names={['Cadastros']}
        paths={[]}
        disabled={false}
        showArrow={false}
        backGroundColor={location.state}
      /> */}
      <BuidingPage />
    </Container>
  )
}

export default Registrations
