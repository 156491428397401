import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

function Teste() {
  const [fruitAndCar, setFruitAndCar] = useState<{
    fruitId: number | null
    carId: number | null
  }>({ fruitId: null, carId: null })
  const fruits = [
    { id: 1, name: 'Apple' },
    { id: 2, name: 'Banana' },
    { id: 3, name: 'Cherry' },
    { id: 4, name: 'Date' },
    { id: 5, name: 'Elderberry' },
    { id: 6, name: 'Fig' },
    { id: 7, name: 'Grape' }
  ]
  const cars = [
    { id: 1, name: 'Audi' },
    { id: 2, name: 'BMW' },
    { id: 3, name: 'Mercedes' },
    { id: 4, name: 'Toyota' },
    { id: 5, name: 'Honda' },
    { id: 6, name: 'Ford' },
    { id: 7, name: 'Chevrolet' }
  ]

  const handleFruitChange = (
    event: React.ChangeEvent<{}>,
    newValue: { id: number; name: string } | null
  ) => {
    setFruitAndCar(prev => ({
      ...prev,
      fruitId: newValue ? newValue.id : null
    }))
  }

  const handleCarChange = (
    event: React.ChangeEvent<{}>,
    newValue: { id: number; name: string } | null
  ) => {
    setFruitAndCar(prev => ({ ...prev, carId: newValue ? newValue.id : null }))
  }

  const handleRandomSelection = () => {
    const randomFruit = fruits[Math.floor(Math.random() * fruits.length)]
    const randomCar = cars[Math.floor(Math.random() * cars.length)]
    setFruitAndCar({ fruitId: randomFruit.id, carId: randomCar.id })
  }
  return (
    <div className="mt-5">
      <Autocomplete
        value={
          fruitAndCar.fruitId !== null
            ? fruits.find(fruit => fruit.id === fruitAndCar.fruitId)
            : null
        }
        onChange={handleFruitChange}
        options={fruits}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} label="Select Fruit" />}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
      />

      <Autocomplete
        value={
          fruitAndCar.carId !== null
            ? cars.find(car => car.id === fruitAndCar.carId)
            : null
        }
        onChange={handleCarChange}
        options={cars}
        getOptionLabel={option => option.name}
        renderInput={params => <TextField {...params} label="Select Car" />}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
      />

      <div>
        <strong>Selected Fruit:</strong>{' '}
        {fruitAndCar.fruitId !== null
          ? fruits.find(fruit => fruit.id === fruitAndCar.fruitId)?.name
          : ''}
      </div>

      <div>
        <strong>Selected Car:</strong>{' '}
        {fruitAndCar.carId !== null
          ? cars.find(car => car.id === fruitAndCar.carId)?.name
          : ''}
      </div>
      <div className="mt-2">
        <button onClick={handleRandomSelection}>
          Select Random Fruit and Car
        </button>
      </div>
    </div>
  )
}

export default Teste
