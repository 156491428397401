import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import addFileIcon from '../../assets/svgs/add-file-icon.svg'
import clipBoardIcon from '../../assets/svgs/clipboard-icon.svg'
import editFileIcon from '../../assets/svgs/edit-file-icon.svg'
import removeFileIcon from '../../assets/svgs/remove-file-icon.svg'
import searchFileIcon from '../../assets/svgs/search-file-icon.svg'
import { Container, Content, Grid, Item } from './style'
import BradCrumbs from '../../shared/components/common/breadcrumbs'

const DashboardPage: React.FC = () => {
  const location = useLocation()

  const navigate = useNavigate()
  return (
    <Container className="w-100">
      <BradCrumbs
        names={['Sistema de estágio']}
        paths={[]}
        disabled={false}
        showArrow={false}
        backGroundColor={location.state}
      />
      <Content className="w-100 mt-3">
        <Grid className="p-5">
          <Item
            onClick={() => {
              navigate('/intership/opportunity-record')
            }}
            className="p-3"
          >
            <img src={addFileIcon} alt="" />
            <h3 className="mt-3">Cadastrar nova oportunidade</h3>
          </Item>
          <Item className="p-3">
            <img src={editFileIcon} alt="" />
            <h3 className="mt-3">Modificar oportunidade</h3>
          </Item>
          <Item className="p-3">
            <img src={removeFileIcon} alt="" />
            <h3 className="mt-3">Modificar oportunidade</h3>
          </Item>
          <Item className="p-3">
            <img src={searchFileIcon} alt="" />
            <h3 className="mt-3">Pesquisar oportunidade</h3>
          </Item>
          <Item className="p-3">
            <img src={clipBoardIcon} alt="" />
            <h3 className="mt-3">Relatórios</h3>
          </Item>
        </Grid>
      </Content>
    </Container>
  )
}

export default DashboardPage
