export enum EKnowledgeTypeEnum {
  INFORMATICS = 1,
  LANGUAGES = 2,
  CERTIFICATIONS = 3,
  COURSES = 4,
  TELEMARKETING = 5,
  GENERALCLASSIFICATION = 6,
  REFERREDBY = 7,
  OBSERVATION = 8,
  EADRESOURCES = 9,
  FROMPROTECTIONAGENCIES = 12
}

export enum EEducationLevelEnum {
  HIGHSCHOOL = 1,
  TECHNICALEDUCATION = 2,
  HIGHEREDUCATION = 3,
  ELEMENTARYEDUCATION = 4
}

export enum EPeriodsEnum {
  ANNUAL = 1,
  SEMIANNUAL = 2
}

export enum EScholarshipTypeEnum {
  MONTHLY = 'M',
  HOURLY = 'H',
  PERCENTEGE = 'S'
}
