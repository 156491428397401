import React, { ChangeEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'
import { Button, TextField } from '@mui/material'
import { IoIosArrowBack } from 'react-icons/io'
import {
  Container,
  FormLogo,
  CustomForm,
  Logo,
  MainContent,
  Subtitle,
  Title
} from './style'
import { TUserForgotRequest } from '../../../core/models'
import { authService } from '../../../core/services'
import { notification } from '../../../shared/services'
import { StyledLink } from '../login/style'
import {
  RightTopConner,
  LeftBottomConner
} from '../../../shared/components/common/'
import logo from '../../../assets/images/logo.png'
import formLogo from '../../../assets/images/password.png'
import { Helmet } from 'react-helmet'

const ForgotPasswordPage: React.FC = () => {
  const navigate = useNavigate()
  const [isloading, setLoading] = useState(false)

  const [user, setUser] = useState<TUserForgotRequest>({
    login: ''
  })
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  const validate = () => {
    if (!user.login) {
      notification.error('Dados inválidos', 'por favor informe seu email')
      return false
    }
    return true
  }

  const recoverPassword = async (): Promise<void> => {
    if (!validate()) return
    try {
      setLoading(true)
      const result: any = await authService.recoverPassword({
        login: user.login
      })
      notification.success('Sucesso!', result.message)
      setLoading(false)
      navigate('/login')
    } catch {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Logo src={logo} />
      <RightTopConner style={{ backgroundColor: '#9E071E' }} />
      <MainContent className="p-0 p-md-4">
        <CustomForm className="w-100 w-md-50 me-0 me-md-4">
          {isloading ? (
            <div className="d-flex align-items-center justify-content-center mt-4 h-100">
              <FadeLoader color="#bc0823" loading={isloading} />
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <IoIosArrowBack className="me-2" size={20} />
                <StyledLink to="/login">Voltar para Login</StyledLink>
              </div>
              <Title className="mt-5 mt-md-3">Esqueceu sua senha?</Title>
              <Subtitle>Não se preocupe, isso acontece.</Subtitle>
              <Subtitle>
                Digite seu e-mail abaixo para recuperar sua senha
              </Subtitle>
              <TextField
                className="mt-3"
                color="error"
                label="E-Mail"
                type="email"
                name="login"
                value={user.login}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                type="button"
                color="error"
                className="mt-4 w-100"
                onClick={recoverPassword}
              >
                Enviar
              </Button>
            </>
          )}
        </CustomForm>
        <FormLogo className="d-none d-md-block" src={formLogo} />
      </MainContent>
      <LeftBottomConner style={{ backgroundColor: '#bc0823' }} />
    </Container>
  )
}

export default ForgotPasswordPage
