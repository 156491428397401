import React from 'react'
import { IoIosLogOut } from 'react-icons/io'
import { MdKeyboardArrowRight } from 'react-icons/md'
import { Container, RightConner } from './style'
import { FaRegUser } from 'react-icons/fa'
import './style.css'
import { TNavProps } from '../../../../core/models'
import logo from '../../../../assets/images/logo.png'
import { authService } from '../../../../core/services'
import { useNavigate } from 'react-router-dom'
import { RightTopConner } from '../right-top-conner'

const BradCrumbs = (props: TNavProps) => {
  const navigate = useNavigate()
  const backGroundColor = props.backGroundColor

  const logOut = () => {
    authService.removeToken()
    navigate('/')
  }

  return (
    <Container>
      <img
        style={{ width: '60px', marginLeft: '30px', marginRight: '30px' }}
        src={logo}
        alt="logo"
      />

      {props.names.map((name: any, index: number) => (
        <React.Fragment key={index}>
          <span>{name}</span>
          <MdKeyboardArrowRight
            className="mx-1"
            size={17}
            key={`arrow-${index}`}
          />
        </React.Fragment>
      ))}

      <RightConner>
        <RightTopConner style={{ backgroundColor: backGroundColor }} />
        <div className="actions">
          <button
            className="btn btn-access"
            style={{ color: 'white', marginRight: '5px' }}
          >
            <FaRegUser />
          </button>
          <button
            className="btn btn-access"
            style={{ color: 'white' }}
            onClick={logOut}
          >
            <IoIosLogOut />
          </button>
        </div>
      </RightConner>
    </Container>
  )
}
export default BradCrumbs
