import Swal from 'sweetalert2'

const notification = {
  success(title: string, message: string, timer: number = 3000): void {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: timer,
      timerProgressBar: true,
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: title,
      text: message
    })
  },

  async error(
    title: string,
    message: string,
    timer: number = 3000,
    awaitClick: boolean = false,
    backGroundColor?: string
  ) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: awaitClick ? 0 : timer,

      timerProgressBar: true,
      confirmButtonColor: backGroundColor,
      confirmButtonText: 'OK',
      didOpen: toast => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    await Toast.fire({
      icon: 'error',
      title: title,
      html: message,
      showConfirmButton: awaitClick
    })
  }
  // async teste() {
  //   Swal.fire({
  //     icon: 'success',
  //     title: 'Toast that stays open',
  //     toast: true,
  //     position: 'top-end',
  //     showConfirmButton: false,
  //     timer: false,
  //     timerProgressBar: true,
  //     background: '#fff',
  //     text: 'This toast will stay open until you click OK',
  //     showCloseButton: true
  //   }).then(result => {
  //     if (result.isConfirmed) {
  //       // Aqui você pode adicionar ações adicionais ao clicar em "OK"
  //     }
  //   })
  // }
}
export { notification }
