import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #bc0823;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Logo = styled.img`
  width: 100px;
  height: auto;
  position: absolute;
  left: 30px;
  top: 25px;
`
export const MainContent = styled.div`
  width: 60%;
  max-width: 820px;
  min-width: 350px;
  height: 50vh;
  min-height: 460px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 0.249px 0.747px 0px rgba(0, 0, 0, 0.02),
    0px 0.599px 1.796px 0px rgba(0, 0, 0, 0.03),
    0px 1.127px 3.381px 0px rgba(0, 0, 0, 0.04),
    0px 2.01px 6.031px 0px rgba(0, 0, 0, 0.04),
    0px 3.76px 11.28px 0px rgba(0, 0, 0, 0.05),
    0px 9px 27px 0px rgba(0, 0, 0, 0.07);
`
export const CustomForm = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
`
export const FormLogo = styled.img`
  width: 48%;
  min-width: 350px;
  max-width: 350px;
  height: 100%;
  border-radius: 15px;
`
export const Title = styled.h1`
  color: var(--T, #313131);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const Subtitle = styled.span`
  color: var(--T, #313131);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.75;
`
export const StyledFormCheck = styled(Form.Check)`
  label {
    color: var(--T, #313131);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
export const StyledLink = styled(Link)`
  color: var(--T, #313131);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  opacity: 0.75;

  &:hover {
    color: #bc0823;
  }
`
export const RegisterLink = styled(Link)`
  color: var(--T, #313131);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  opacity: 0.75;
`
