import styled from 'styled-components'

export const LeftBottomConner = styled.div`
  position: fixed;
  left: 4px;
  bottom: -47px;
  width: 521px;
  height: 89px;
  transform: rotate(10deg);
  margin-top: -115px;
  border-radius: 108px 0 0 0;
  border: white 1px solid;
`
