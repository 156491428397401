import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { TUserLogin } from '../../../core/models'
import { notification } from '../../../shared/services'
import { authService } from '../../../core/services'
import {
  Container,
  CustomForm,
  FormLogo,
  Logo,
  MainContent,
  StyledFormCheck,
  StyledLink,
  Subtitle,
  Title
} from './style'
import {
  RightTopConner,
  LeftBottomConner
} from '../../../shared/components/common/'
import formLogo from '../../../assets/images/loginImage.png'
import logo from '../../../assets/images/logo.png'
import CustomSpinner from '../../../shared/components/common/custom-spinner'

const LoginPage: React.FC = () => {
  const navigate = useNavigate()
  const [isloading, setLoading] = useState(false)
  const [showPassword, setPassword] = useState(false)

  const [user, setUser] = useState<TUserLogin>({
    login: '',
    password: '',
    keep: false
  })

  useEffect(() => {
    const isAuthenticated = authService.isAuthenticated()
    if (isAuthenticated) {
      navigate('/intership')
    }
  }, [navigate])

  const handleTogglePassword = () => {
    setPassword(!showPassword)
  }

  const validate = () => {
    if (!user.login || !user.password) {
      notification.error('Dados inválidos', 'por favor informe email e senha.')
      return false
    }
    return true
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value

    setUser({ ...user, [event.target.name]: value })
  }

  const authentication = async (): Promise<void> => {
    if (!validate()) return
    try {
      setLoading(true)
      const result = await authService.login({
        login: user.login,
        password: user.password
      })
      authService.setToken(result.token, user.keep)
      navigate('/intership')
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Logo src={logo} />
      <RightTopConner style={{ backgroundColor: '#9E071E' }} />
      <MainContent className="p-0 p-md-4">
        <CustomForm className="w-100 me-0 me-md-4">
          <CustomSpinner state={'#9E071E'} show={isloading} />
          <Title>Login</Title>
          <Subtitle>Faça login para acessar sua conta ISBET</Subtitle>
          <TextField
            className="mt-3"
            color="error"
            label="E-Mail"
            type="email"
            name="login"
            value={user.login}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true
            }}
          />
          <TextField
            className="mt-4"
            color="error"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleTogglePassword}>
                    {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            label="Senha"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={user.password}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true
            }}
          />

          <div className="d-flex align-items-center justify-content-between mt-2">
            <StyledFormCheck
              label="Lembrar de mim"
              type="checkbox"
              className="shadow-none"
              name="keep"
              value={user.keep ? 1 : 0}
              onChange={handleChange}
            />
            <StyledLink to="/forgot-password">Esqueceu a senha?</StyledLink>
          </div>

          <Button
            variant="contained"
            type="button"
            color="error"
            className="mt-4 w-100"
            onClick={authentication}
          >
            Login
          </Button>
        </CustomForm>
        <FormLogo className="d-none d-md-block" src={formLogo} />
      </MainContent>
      <LeftBottomConner style={{ backgroundColor: '#bc0823' }} />
    </Container>
  )
}

export default LoginPage
