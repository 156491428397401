import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { ChangeEvent, useEffect, useState } from 'react'
import { MdEditCalendar } from 'react-icons/md'
import { FormStyle } from '../../../../shared/styles/form.style'
import CalendarIcon from '../../../../assets/svgs/calendar.svg'
import { FaUser } from 'react-icons/fa'
import { LiaClipboardListSolid } from 'react-icons/lia'
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import { IoIosArrowDown } from 'react-icons/io'
import { TFacility, TInterviwerFilters } from '../../../../shared/models'
import { commonService, notification } from '../../../../shared/services'
import CustomSpinner from '../../../../shared/components/common/custom-spinner'
import './style.css'
import {
  calendarService,
  candidatesPdf
} from '../../../../shared/services/calendar'
import CustomModal from '../../../../shared/components/common/custom-modal'
import { HiOutlineTrash } from 'react-icons/hi'
import CandidatesModal from '../candidates-modal'
import dayjs from 'dayjs'

const EventModal = ({
  show,
  onClose,
  onSave,
  state,
  item,
  facility,
  interviewer
}: any) => {
  const [formData, setFormData] = useState<any>({
    id: 0,
    description: '',
    date: null,
    start: null,
    end: null,
    vacancies: 0,
    candidates: 20,
    meetingType: 'P',
    classType: 'E',
    local: '',
    interviewerId: null,
    facilityId: null
  })
  const [title, setTitle] = useState('')
  const [isEdition, setIsEdition] = useState(false)
  const [isloading, setLoading] = useState<boolean>(false)
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [showCandidatesModal, setCandidatesModal] = useState(false)
  const [interViwerFilters, setInterViwerFilters] = useState<
    TInterviwerFilters[]
  >([])
  function calendarIcon() {
    return (
      <img
        className="me-1"
        src={CalendarIcon}
        style={{ width: 20 }}
        alt="Date picker opening icon"
        width={32}
      />
    )
  }

  const handlerDelete = async () => {
    setConfirmDeleteModal(true)
  }

  const validation = (): boolean => {
    const data = { ...formData }
    if (!data.description || data.description.trim() === '') {
      notification.error('Atenção', 'Descrição é obrigatória.')
      return false
    }

    if (!data.date || !dayjs(data.date).isValid()) {
      notification.error('Atenção', 'Data inválida.')
      return false
    }

    if (!data.start || !dayjs(data.start).isValid()) {
      notification.error('Atenção', 'Horário de início inválido.')
      return false
    }

    if (!data.end || !dayjs(data.end).isValid()) {
      notification.error('Atenção', 'Horário de término inválido.')
      return false
    }

    if (data.vacancies <= 0 || data.vacancies === '') {
      notification.error('Atenção', 'Número de vagas deve ser maior a zero.')
      return false
    }

    if (!data.local || data.local.trim() === '') {
      notification.error('Atenção', 'Local é obrigatório.')
      return false
    }

    if (data.interviewerId === null) {
      notification.error('Atenção', 'Selecione entrevistador.')
      return false
    }
    return true
  }

  const prepareToSave = () => {
    const newObject = { ...formData }
    const dateFormatted = dayjs(newObject.date).format('YYYY-MM-DD')
    newObject.start = dayjs(newObject.start).subtract(3, 'hour').toISOString()
    newObject.end = dayjs(newObject.end).subtract(3, 'hour').toISOString()
    newObject.start = dateFormatted + newObject.start.slice(10)
    newObject.end = dateFormatted + newObject.end.slice(10)
    return newObject
  }

  const handlerSave = async () => {
    if (!validation()) return
    try {
      setLoading(true)
      const data = prepareToSave()
      await calendarService.createEvent(data)
      notification.success('Sucesso.', 'Evento criado com sucesso.')
      onSave()
    } catch (e: any) {
      console.log(e.message)
    } finally {
      setLoading(false)
    }
  }

  const handlerEdit = async () => {
    if (!validation()) return
    try {
      setLoading(true)
      const data = prepareToSave()
      await calendarService.updateEvent(data)
      notification.success('Sucesso.', 'Evento editado com sucesso.')
      onSave()
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerConfirmedDeleteEvent = async () => {
    try {
      setLoading(true)
      await calendarService.deleteEvent(formData.id)
      notification.success('Sucesso.', 'Evento excluído com sucesso.')
      onSave()
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerLoadFilters = async (): Promise<void> => {
    try {
      setLoading(true)
      const interviersResult = await commonService.getInterviwersFilters()
      setInterViwerFilters(interviersResult)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerLoadEvent = async (): Promise<void> => {
    try {
      setLoading(true)
      setFormData((prevData: any) => ({
        ...prevData,
        id: item.id,
        description: item.description,
        date: dayjs(item.date),
        start: dayjs(item.start),
        end: dayjs(item.end),
        vacancies: item.vacancies,
        candidates: item.candidates,
        meetingType: item.meetingType,
        classType: item.classType,
        local: item.local,
        interviewerId: item.interviewerId,
        facilityId: item.facilityId
      }))
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerModalCandidates = async () => {
    setCandidatesModal(true)
  }

  const handleCloseModalCandidates = () => {
    setCandidatesModal(false)
  }

  const handlerMakePDF = async () => {
    setLoading(true)
    const event = await calendarService.getEventCandidates(1)
    await candidatesPdf.makePdf(event, formData)
    setLoading(false)
  }

  const handleInputChangeDefault = (event: ChangeEvent<any>) => {
    const { name, value } = event.target
    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }

  const handleInputChangeDate = (date: any) => {
    setFormData((prevData: any) => ({ ...prevData, date: date }))
  }

  const handleInputChangeTime = (field: any, newValue: any) => {
    setFormData((prevData: any) => ({ ...prevData, [field]: newValue }))
  }

  const handlerSetInterviewer = (value: any) => {
    setFormData((prevData: any) => ({
      ...prevData,
      interviewerId: value.id
    }))
  }

  const handleCloseConfirmDeleteModal = () => {
    setConfirmDeleteModal(false)
  }

  const teste = () => {
    console.log(formData)
  }

  useEffect(() => {
    setIsEdition(item ? true : false)
    setTitle(item ? 'Editar Entrevista' : 'Criar Entrevista')
    handlerLoadFilters()
    if (item) {
      handlerLoadEvent()
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        facilityId: facility
      }))
    }
  }, [item])

  return (
    <Modal show={show} onHide={onClose} centered>
      {showConfirmDeleteModal && (
        <CustomModal
          show={showConfirmDeleteModal}
          onClose={handleCloseConfirmDeleteModal}
          onSave={handlerConfirmedDeleteEvent}
          title="Confirmação"
          icon={<HiOutlineTrash size={20} />}
          state={state || '#004699'}
          onlyCloseBtn={false}
          buttonTitle={'Continuar'}
        >
          <h5>Tem certeza que deseja excluir?</h5> Esta ação não poderá ser
          desfeita.
        </CustomModal>
      )}

      {showCandidatesModal && (
        <CandidatesModal
          show={showCandidatesModal}
          onClose={handleCloseModalCandidates}
          title="Candidatos Agendados"
          icon={<FaUser size={20} />}
          state={state || '#004699'}
          eventId={item.publicId}
        ></CandidatesModal>
      )}

      <div
        style={{
          display:
            showConfirmDeleteModal || showCandidatesModal ? 'none' : 'block'
        }}
      >
        <CustomSpinner state={state || '#004699'} show={isloading} />
        <Modal.Header closeButton>
          <span
            className="icon me-2"
            style={{
              border: '1px solid #c1c1c1',
              padding: '5px 7px',
              borderRadius: '5px'
            }}
          >
            <MdEditCalendar size={20} />
          </span>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col">
            <div className="d-flex flex-column mt-1">
              <FormStyle.Label>Descrição</FormStyle.Label>
              <FormStyle.Field
                name="description"
                value={formData.description}
                onChange={handleInputChangeDefault}
              />
            </div>
          </div>
          <div className="row">
            <div className="mt-2 col-6">
              <FormStyle.Label>Data</FormStyle.Label>
              <FormStyle.DatePickerStyle
                slots={{ openPickerIcon: calendarIcon }}
                sx={{ fontSize: '15px' }}
                format="DD/MM/YYYY"
                name="date"
                value={formData.date}
                onChange={handleInputChangeDate}
              />
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-between mt-3 w-100 ">
              <FormStyle.TimePickerContainer>
                <FormStyle.Label>Início</FormStyle.Label>
                <FormStyle.TimePickerEventStyle
                  ampm={false}
                  onChange={value => handleInputChangeTime('start', value)}
                  value={formData.start}
                />
              </FormStyle.TimePickerContainer>
              <FormStyle.TimePickerContainer>
                <FormStyle.Label>Fim</FormStyle.Label>
                <FormStyle.TimePickerEventStyle
                  ampm={false}
                  onChange={value => handleInputChangeTime('end', value)}
                  value={formData.end}
                />
              </FormStyle.TimePickerContainer>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6 d-flex flex-column ">
              <FormStyle.Label>Número de Vagas</FormStyle.Label>
              <FormStyle.Field
                name="vacancies"
                type="number"
                value={formData.vacancies}
                onChange={handleInputChangeDefault}
              />
            </div>
            {isEdition && (
              <div
                className="col-6 d-flex "
                style={{ marginTop: '22px', marginLeft: '-10px' }}
              >
                <Button
                  onClick={handlerModalCandidates}
                  className="custom-button"
                  style={{
                    backgroundColor: state,
                    border: state,
                    whiteSpace: 'nowrap',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <FaUser size={14} />
                  <span style={{ marginLeft: '5px' }}>
                    {formData.candidates}
                  </span>
                </Button>
                <Button
                  onClick={handlerMakePDF}
                  className="custom-button ms-2"
                  style={{
                    backgroundColor: state,
                    border: state,
                    whiteSpace: 'nowrap',
                    width: '50px',
                    padding: 0
                  }}
                >
                  <LiaClipboardListSolid size={20} />
                </Button>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-3">
              <RadioGroup
                className="mt-3"
                name="classType"
                value={formData.classType}
                onChange={handleInputChangeDefault}
              >
                <FormStyle.Label>Tipo de Turma</FormStyle.Label>
                <div className="d-flex align-items-center">
                  <FormControlLabel
                    value="E"
                    control={<Radio style={{ color: state }} />}
                    label={
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Estágio
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="J"
                    control={<Radio style={{ color: state }} />}
                    label={
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Jovem Aprendiz
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="M"
                    control={<Radio style={{ color: state }} />}
                    label={
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Misto
                      </span>
                    }
                  />
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <RadioGroup
                className="mt-3"
                name="meetingType"
                value={formData.meetingType}
                onChange={handleInputChangeDefault}
              >
                <FormStyle.Label>Tipo de Reunião</FormStyle.Label>
                <div className="d-flex align-items-center">
                  <FormControlLabel
                    value="P"
                    control={<Radio style={{ color: state }} />}
                    label={
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Presencial
                      </span>
                    }
                  />
                  <FormControlLabel
                    value="R"
                    control={<Radio style={{ color: state }} />}
                    label={
                      <span
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Remoto
                      </span>
                    }
                  />
                </div>
              </RadioGroup>
            </div>
          </div>
          <div className="col mt-1">
            <div className="d-flex flex-column mt-1">
              <FormStyle.Label>Local</FormStyle.Label>
              <FormStyle.Field
                name="local"
                value={formData.local}
                onChange={handleInputChangeDefault}
              />
            </div>
          </div>
          <div className="col mt-1">
            <FormStyle.Label>Entrevistador</FormStyle.Label>
            <Autocomplete
              disablePortal
              options={interViwerFilters}
              getOptionLabel={option => option.name}
              getOptionKey={option => option.id}
              value={
                interViwerFilters.find(
                  (f: any) => f.id === formData.interviewerId
                ) || { id: 0, name: '' }
              }
              onChange={(event, newValue) => handlerSetInterviewer(newValue)}
              sx={{
                minWidth: 200,
                '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
              }}
              disableClearable={true}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Selecione Entrevistador"
                  InputLabelProps={{ shrink: false }}
                />
              )}
              renderOption={(props, option: TFacility, state, ownerState) => (
                <li {...props}>{option.name}</li>
              )}
              popupIcon={<IoIosArrowDown />}
            />
          </div>
          {/* <button type="button" onClick={teste}>
            Teste
          </button> */}
        </Modal.Body>
        <Modal.Footer>
          {isEdition ? (
            <div className="d-flex justify-content-between w-100">
              <Button
                onClick={handlerEdit}
                className="custom-button"
                style={{ backgroundColor: state, border: state }}
              >
                Salvar
              </Button>
              <Button
                onClick={handlerDelete}
                className="custom-button"
                style={{ backgroundColor: state, border: state }}
              >
                Excluir
              </Button>
              <Button
                className="custom-button"
                style={{ backgroundColor: state, border: state }}
                onClick={onClose}
              >
                Fechar
              </Button>
            </div>
          ) : (
            <div className="d-flex justify-content-between w-100">
              <Button
                onClick={handlerSave}
                className="custom-button-new"
                style={{ backgroundColor: state, border: state }}
              >
                Salvar
              </Button>
              <Button
                className="custom-button-new"
                style={{ backgroundColor: state, border: state }}
                onClick={onClose}
              >
                Cancelar
              </Button>
            </div>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  )
}

export default EventModal
