import { Table } from 'react-bootstrap'
import styled from 'styled-components'

export namespace DefaultTableStyle {
  interface Theme {
    backgroundColor: string
  }

  export const CustomTable = styled(Table)`
    border: solid 1px #ccc;
    border-radius: 15px;
  `
  export const CustomMiniButton = styled.div<Theme>`
    padding: 0px 5px;
    border: solid 1px #ccc;
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
    display: inline-block;
    background-color: transparent;
    color: #333; /* Cor do texto */

    &:hover {
      background: ${({ backgroundColor }) => backgroundColor};
      color: #fff;
    }
  `

  export const CustomSpan = styled.span<Theme>`
    cursor: pointer;
    &:hover {
      color: ${({ backgroundColor }) => backgroundColor};
      font-weight: 500;
      text-decoration: underline;
    }
  `

  export const StatusInscription = styled.div`
    display: flex;
    color: #d98d1a;
    border-radius: 15px;
    background-color: rgba(217, 141, 26, 0.12) !important;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 5px;
    width: 115px;
    margin-top: -5px;
  `
  export const StatusInterviewIsbet = styled.div`
    display: flex;
    color: #d98d1a;
    border-radius: 15px;
    background-color: rgba(217, 141, 26, 0.12) !important;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 5px;
    width: 162px;
    margin-top: -5px;
  `
  export const StatusInterviewCompany = styled.div`
    display: flex;
    color: #d98d1a;
    border-radius: 15px;
    background-color: rgba(217, 141, 26, 0.12) !important;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 5px;
    width: 184px;
    margin-top: -5px;
  `
  export const StatusFinished = styled.div`
    display: flex;
    color: #61c043;
    border-radius: 15px;
    background-color: rgba(64, 168, 27, 0.12) !important;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 5px;
    width: 125px;
    margin-top: -5px;
  `
  export const StatusHiring = styled.div`
    display: flex;
    color: #61c043;
    border-radius: 15px;
    background-color: rgba(64, 168, 27, 0.12) !important;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 5px;
    width: 140px;
    margin-top: -5px;
  `
  export const StatusCanceled = styled.div`
    display: flex;
    color: #d82e38;
    border-radius: 15px;
    background-color: rgba(216, 46, 56, 0.12) !important;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    padding: 5px;
    width: 125px;
    margin-top: -5px;
  `
}
