import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Autocomplete, TextField } from '@mui/material'
import { HiOutlineAdjustmentsHorizontal, HiOutlineTrash } from 'react-icons/hi2'
import { IoIosArrowDown, IoMdAddCircleOutline } from 'react-icons/io'
import { IntershipStyle } from './style'
import BradCrumbs from '../../shared/components/common/breadcrumbs'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FormStyle } from '../../shared/styles/form.style'
import { Tooltip } from 'react-bootstrap'
import CustomModal from '../../shared/components/common/custom-modal'
import FilterModal from './components/filter-modal'
import {
  TDefaultTableBody,
  TFacilitiesFilters,
  TFacility,
  TModalFilter,
  TRequestPagination
} from '../../shared/models'
import { DefaultTable } from '../../shared/components/common'
import {
  commonService,
  internshipService,
  notification
} from '../../shared/services'
import CustomSpinner from '../../shared/components/common/custom-spinner'

const Internship: React.FC = () => {
  let debounceTimer: any
  const navigate = useNavigate()
  const location = useLocation()
  const [isloading, setLoading] = useState<boolean>(false)
  const [facilitiesFilters, setFacilitiesFilters] = useState<
    TFacilitiesFilters[]
  >([])

  const [currentFacility, setCurrentFacility] = useState<number | null>(null)
  const [lastFacility, setLastFacility] = useState<number | null>(null)
  const [selectedVacancyId, setSelectedVacancyId] = useState<number | null>(
    null
  )
  const [search, setSearch] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [sendData, setSendData] = useState(false)
  const [formData, setFormData] = useState<TModalFilter>({
    startDate: null,
    endDate: null,
    filters: []
  })

  const [pagination, setPagination] = useState<TRequestPagination>({
    searchTerm: '',
    page: 1,
    pageSize: 12,
    facilityId: null,
    interShipfilters: [],
    startDate: '',
    endDate: '',
    isAsc: false,
    orderCollumnName: ''
  })
  const [interShips, setInterShips] = useState<TDefaultTableBody[]>([])

  const tableInfo = {
    options: {
      striped: true,
      sortable: true,
      hover: true,
      responsive: true,
      size: 'sm',
      showEdit: true,
      showDelete: true,
      skipItems: ['id', 'jobVacancyId'],
      clickable: ['Empresa'],
      backgroundColor: location.state || '#004699'
    },

    headers: [
      {
        id: 1,
        label: 'Id',
        sortable: true,
        isSort: true,
        originalName: 'JobVacancyId'
      },
      {
        id: 2,
        label: 'Empresa',
        sortable: true,
        isSort: true,
        originalName: 'CompanyName'
      },
      {
        id: 3,
        label: 'Código',
        sortable: true,
        isSort: false,
        originalName: 'JobCode'
      },
      {
        id: 4,
        label: 'Status',
        sortable: true,
        isSort: false,
        originalName: 'Status'
      },
      {
        id: 5,
        label: 'Descrição',
        sortable: true,
        isSort: false,
        originalName: 'JobDescription'
      },
      {
        id: 6,
        label: 'Bairro',
        sortable: true,
        isSort: false,
        originalName: 'WorkAddressNeighborhood'
      },
      {
        id: 7,
        label: 'Total de Vagas',
        sortable: true,
        isSort: false,
        originalName: 'TotalVacancies'
      },
      {
        id: 8,
        label: 'Vagas Preenchidas',
        sortable: true,
        isSort: false,
        originalName: 'TotalFilledVacancies'
      },
      {
        id: 9,
        label: 'Ações',
        sortable: false,
        isSort: false
      }
    ]
  }

  const handlerSetFacility = (value: any) => {
    setCurrentFacility(value.id)
    setPagination(prevData => ({
      ...prevData,
      facilityId: value.id,
      page: 1
    }))
  }

  const handlerSetSort = (value: any) => {
    setPagination(prevData => ({
      ...prevData,
      orderCollumnName: value ? value.originalName : 'JobVacancyId',
      isAsc: value ? value.isSort : true,
      page: 1
    }))
  }

  const handlerScroll = (event: any) => {
    setPagination(prevData => ({
      ...prevData,
      page: (prevData.page += 1)
    }))
  }

  const handlerClone = (value: any) => {
    navigate(`/intership/opportunity-record/${value.jobVacancyId}/true`)
  }

  const handleEdit = (value: any) => {
    navigate(`/intership/opportunity-record/${value.jobVacancyId}`)
  }

  const handlerDelete = (value: any) => {
    setSelectedVacancyId(value.jobVacancyId)
    setConfirmDeleteModal(true)
  }

  const handleCloseConfirmDeleteModal = () => {
    setConfirmDeleteModal(false)
    setSelectedVacancyId(null)
  }

  const handlerConfirmDeleteVacancy = async () => {
    try {
      if (selectedVacancyId) {
        setLoading(true)
        await internshipService.deleteIntership(selectedVacancyId)
        notification.success('Sucesso.', 'oportunidade removida com sucesso.')
        handleCloseConfirmDeleteModal()
        handlerLoadInterShips()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setSendData(false)
  }

  const handleSaveModal = () => {
    setSendData(true)
  }

  const handleOpenModal = () => setShowModal(true)

  const handleInputSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || ''

    setSearch(value)
    if (value.length === 0 || value.length > 2) debounceSearch(value)
  }

  const debounceSearch = (value: string) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      setPagination(prevData => ({
        ...prevData,
        searchTerm: value
      }))
    }, 500)
  }

  const handleFilterChange = (event: TModalFilter) => {
    setFormData(event)
    setShowModal(false)
    setSendData(false)
    setPagination(prevData => ({
      ...prevData,
      startDate: event.startDate ?? '',
      endDate: event.endDate ?? '',
      interShipfilters: event.filters
    }))
  }

  const handlerLoadInterShips = async (): Promise<void> => {
    try {
      if (!pagination.facilityId) return
      setLoading(true)
      const interShips = await internshipService.getAllIntership(pagination)
      if (pagination.page > 1) {
        setInterShips(prevInterShips => [...prevInterShips, ...interShips])
      } else {
        setInterShips(interShips)
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerLoadFilters = async (): Promise<void> => {
    try {
      setLoading(true)
      const facilitiesResult = await commonService.getFacilitiesFilters()
      setFacilitiesFilters(facilitiesResult)
      handlerSetFacility(facilitiesResult[0].id)
      setPagination(prevData => ({
        ...prevData,
        facilityId: facilitiesResult[0].id
      }))
    } catch {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handlerLoadFilters()
  }, [])

  useEffect(() => {
    handlerLoadInterShips()
  }, [pagination])
  return (
    <IntershipStyle.Container className="w-100">
      <BradCrumbs
        names={['Sistema de estágio']}
        paths={[]}
        disabled={false}
        showArrow={false}
        backGroundColor={location.state || '#004699'}
      />
      <div style={{ paddingLeft: '2.6%', paddingRight: '2.6%' }}>
        <IntershipStyle.HeaderContent className="row mt-4">
          <IntershipStyle.PageTitle className="col-1 ">
            Estágios
          </IntershipStyle.PageTitle>

          <div className="col d-flex align-items-center justify-content-center">
            <IntershipStyle.SelectedFacility>
              Unidade:
            </IntershipStyle.SelectedFacility>
            <Autocomplete
              disablePortal
              options={facilitiesFilters}
              getOptionLabel={option => option.name}
              getOptionKey={option => option.id}
              value={
                facilitiesFilters.find(
                  (f: any) => f.id === pagination.facilityId
                ) || { id: 0, name: '', situationCode: '' }
              }
              onChange={(event, newValue) => handlerSetFacility(newValue)}
              sx={{
                minWidth: 200,
                '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
              }}
              disableClearable={true}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Selecione Unidade"
                  InputLabelProps={{ shrink: false }}
                />
              )}
              renderOption={(props, option: TFacility, state, ownerState) => (
                <li {...props}>{option.name}</li>
              )}
              popupIcon={<IoIosArrowDown />}
            />
          </div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col">
            <IntershipStyle.NewOportunityBtn
              backgroundColor={location.state || '#004699'}
              className="px-3"
            >
              <Link
                className="dropdown-item"
                to="/intership/opportunity-record"
                state={location.state || '#004699'}
              >
                <IoMdAddCircleOutline size={25} className="me-2" /> Cadastrar
                Nova Oportunidade
              </Link>
            </IntershipStyle.NewOportunityBtn>
          </div>
        </IntershipStyle.HeaderContent>
        {showModal && (
          <CustomModal
            show={showModal}
            onClose={handleCloseModal}
            onSave={handleSaveModal}
            title="Filtros"
            icon={<HiOutlineAdjustmentsHorizontal size={20} />}
            state={location.state || '#004699'}
            onlyCloseBtn={false}
            buttonTitle={'Salvar'}
          >
            <FilterModal
              state={location.state || '#004699'}
              outPutData={handleFilterChange}
              pulse={sendData}
            />
          </CustomModal>
        )}
        {showConfirmDeleteModal && (
          <CustomModal
            show={showConfirmDeleteModal}
            onClose={handleCloseConfirmDeleteModal}
            onSave={handlerConfirmDeleteVacancy}
            title="Confirmação"
            icon={<HiOutlineTrash size={20} />}
            state={location.state || '#004699'}
            onlyCloseBtn={false}
            buttonTitle={'Continuar'}
          >
            <h5>Tem certeza que deseja excluir?</h5> Esta ação não poderá ser
            desfeita.
          </CustomModal>
        )}
        <IntershipStyle.FilterContent className="mt-3 ">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">Filtros</Tooltip>}
          >
            <IntershipStyle.FilterBtn
              backgroundColor={location.state || '#004699'}
              className="me-2"
              onClick={handleOpenModal}
            >
              <HiOutlineAdjustmentsHorizontal size={25} />
            </IntershipStyle.FilterBtn>
          </OverlayTrigger>
          <div className="w-100 " style={{ position: 'relative' }}>
            <FormStyle.SearchIcon />
            <FormStyle.SearchInput
              type="search"
              placeholder="Busca"
              name="search"
              value={search}
              onChange={handleInputSearchChange}
            />
          </div>
        </IntershipStyle.FilterContent>
        <CustomSpinner state={location.state || '#004699'} show={isloading} />
        <div
          className="mt-3 w-100"
          style={{
            overflowX: 'hidden',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderTop: '1px solid #ccc'
          }}
        >
          <DefaultTable
            headers={tableInfo.headers}
            body={interShips}
            options={tableInfo.options}
            sortHeader={handlerSetSort}
            scroll={handlerScroll}
            editVacancy={handleEdit}
            deleteVacancy={handlerDelete}
            cloneVacancy={handlerClone}
          ></DefaultTable>
        </div>
      </div>
    </IntershipStyle.Container>
  )
}

export default Internship
