import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import './style.css'
import {
  candidateService,
  commonService,
  notification
} from '../../../../shared/services'
import { CandidateRecordStyle } from './style'
import CustomSpinner from '../../../../shared/components/common/custom-spinner'
import BradCrumbs from '../../../../shared/components/common/breadcrumbs'
import { IoIosArrowDown, IoIosInformationCircleOutline } from 'react-icons/io'
import { FormStyle } from '../../../../shared/styles/form.style'
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography,
  Tooltip,
  debounce,
  Checkbox
} from '@mui/material'
import {
  ECandidateStepsEnum,
  EHowDidYouHearAboutISBETEnum
} from '../../../../shared/enums/candidate'
import {
  TAddressResponse,
  TCourse,
  TEducationInstitution,
  TFacilitiesFilters,
  TNameValueOption,
  TNationality,
  TSpecificSkills,
  TValueOption
} from '../../../../shared/models'
import {
  EEducationLevelEnum,
  EKnowledgeTypeEnum,
  EPeriodsEnum
} from '../../../../shared/enums'
import CalendarIcon from '../../../../assets/svgs/calendar.svg'
import { TCandidateForm } from '../../../../core/models/candidate.model'
import { MdCancel } from 'react-icons/md'
import CustomAutocomplete from '../../../../shared/components/common/custom-autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { FaChevronDown } from 'react-icons/fa'
import dayjs from 'dayjs'
import { Helmet } from 'react-helmet'

const CandateRecordPage: React.FC = () => {
  let { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const MAX = 70
  const MIN = 0
  const MAXANN = 6
  const MINANN = 1
  const MAXSEMI = 12
  const MINSEMI = 1

  const marks = [
    {
      value: MIN,
      label: ''
    },
    {
      value: MAX,
      label: ''
    }
  ]

  const breadCrumps = !id
    ? ['Candidatos', 'Novo Candidato']
    : ['Candidatos', 'Edição de Candidato']
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isUnderage, setIsUnderage] = useState<boolean>(false)
  const [hasSocialName, setHasSocialName] = useState<boolean>(false)
  const [activeNumbers, setActiveNumbers] = useState([1])
  const [currentPage, setCurrentPage] = useState(0)
  const [open, setOpen] = useState(false)
  const [isloading, setLoading] = useState<boolean>(false)
  const [loadingIntitutionalInput, setLoadingIntitutionalInput] =
    useState<boolean>(false)

  const [touchedFields, setTouchedFields] = useState<any>({
    name: false,
    description: false
  })
  const stepNames = ['Dados pessoais', 'Contato', 'Dados Complementares']
  if (!location.state) {
    location.state = '#bc0823'
  }
  const [languagesSkills, setLanguagesSkills] = useState<TSpecificSkills[]>([])
  const [informaticSkills, setInformaticSkills] = useState<TSpecificSkills[]>(
    []
  )
  const [courses, setCourses] = useState<TCourse[]>([])
  const [educationInstitutions, setEducationInstitutions] = useState<
    TEducationInstitution[]
  >([])
  const [nacionalities, setNacionalities] = useState<TNationality[]>([])
  const [genders, setGenders] = useState<TNameValueOption[]>([])
  const [maritalStatuses, setMaritalStatuses] = useState<TNameValueOption[]>([])
  const [socialMidias, setSocialMidias] = useState<any>([])
  const [periods, setPeriods] = useState<any>([])
  const [sexualOrientations, setSexualOrientations] = useState<TValueOption[]>(
    []
  )
  const [legalRelationShips, setLegalRelationShips] = useState<TValueOption[]>(
    []
  )
  const [howDidYouHearAboutISBETs, setHowDidYouHearAboutISBETs] = useState<any>(
    []
  )
  const [facilitiesFilters, setFacilitiesFilters] = useState<
    TFacilitiesFilters[]
  >([])
  const [formData, setFormData] = useState<TCandidateForm>(
    candidateService.getInicialForm()
  )
  function calendarIcon() {
    return (
      <img
        className="me-1"
        src={CalendarIcon}
        style={{ width: 20 }}
        alt="Date picker opening icon"
        width={32}
      />
    )
  }

  const changeTab = (nextStep: number) => {
    setCurrentPage(nextStep - 1)
    setActiveNumbers(Array.from({ length: nextStep }, (_, i) => i + 1))
  }
  const goBackPage = () => {
    navigate('/candidate')
  }

  const handleNextClick = () => {
    const currentStep = activeNumbers[activeNumbers.length - 1]
    const nextStep =
      currentStep < stepNames.length ? currentStep + 1 : currentStep
    changeTab(nextStep)
  }

  const handlerSocialName = (event: any) => {
    setHasSocialName(event)
  }

  const handleFacilityChange = (
    _: ChangeEvent<{}>,
    value: TFacilitiesFilters | null
  ) => {
    setFormData(prevData => ({
      ...prevData,
      selectedFacility: value?.id || null
    }))
  }

  const handleInputChangeAutoComplete = (
    field: keyof TCandidateForm,
    value: any
  ) => {
    switch (field) {
      case 'howDidYouHearAboutISBET':
        setFormData((prevData: any) => ({
          ...prevData,
          [field]: value.id
        }))
        break
      case 'gender':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.value
        }))
        break
      case 'nationality':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.name
        }))
        break
      case 'sexualOrientation':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.value
        }))
        break
      case 'maritalStatus':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.value
        }))
        break
      case 'legalRelationshipDegree':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.value
        }))
        break
      case 'educationInstitution':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.educationalInstitutionId
        }))
        break
      case 'courseId':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.courseId
        }))
        break
      case 'selectedSocialMedia':
        setFormData(prevData => ({
          ...prevData,
          [field]: value.id
        }))
        break
    }
  }
  const isValidDate = (date: any) => {
    const parsedDate = new Date(date)
    return !isNaN(parsedDate.getTime())
  }
  const getFieldValidation = (field: keyof TCandidateForm) => {
    if (
      field === 'birthDate' ||
      field === 'rgIssueDate' ||
      field === 'graduationDate'
    ) {
      return (
        touchedFields[field] &&
        (formData[field] === null || formData[field] === '') &&
        !isValidDate(formData[field])
      )
    }
    return (
      touchedFields[field] &&
      (formData[field] === null || formData[field] === '')
    )
  }

  const handleInputChangeDefault = (event: ChangeEvent<any>) => {
    const { name, value } = event.target
    if (name === 'documentType') {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
        cpfNumber: '',
        rgIssueDate: '',
        rgNumber: ''
      }))
      return
    }

    setFormData((prevData: any) => ({ ...prevData, [name]: value }))
  }

  const handleInputChange = (event: ChangeEvent<any>, index?: number) => {
    const { name, value } = event.target
    if (name === 'rgNumber') {
      if (/^\d*$/.test(value) && value.length <= 11) {
        // if (/^[\d.-]*$/.test(value) && value.length <= 11) { caso queira incluir traco
        setFormData((prevData: any) => ({ ...prevData, [name]: value }))
      }
    } else {
      setFormData((prevData: any) => ({ ...prevData, [name]: value }))
    }
  }

  const handleInputChangeDate = (date: any) => {
    checkIsMinor(date)
    setFormData((prevData: any) => ({ ...prevData, birthDate: date }))
  }

  const handleInputChangeDateRg = (date: any) => {
    setFormData((prevData: any) => ({ ...prevData, rgIssueDate: date }))
  }

  const handleInputChangeDateGraduation = (date: any) => {
    setFormData((prevData: any) => ({ ...prevData, graduationDate: date }))
  }

  const handleInputAvailabilitiesChange = (_: ChangeEvent<{}>, item: any) => {
    setFormData({
      ...formData,
      period: item.value || null
    })
  }

  const checkIsMinor = (birthDate: Date) => {
    const today = new Date()
    let age = today.getFullYear() - new Date(birthDate).getFullYear()
    const monthDifference = today.getMonth() - new Date(birthDate).getMonth()
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < new Date(birthDate).getDate())
    ) {
      age--
    }
    const result = age < 18
    setIsUnderage(result)
    if (!result) {
      setFormData(prevData => ({
        ...prevData,
        legalGuardianCellPhone: '',
        legalGuardianEmail: '',
        legalGuardianName: '',
        legalRelationshipDegree: ''
      }))
    }
  }

  const handleLanguageChange = (value: number): void => {
    const newLanguages = formData.languages.includes(value)
      ? formData.languages.filter(item => item !== value)
      : [...formData.languages, value]

    setFormData(prevState => ({
      ...prevState,
      languages: newLanguages
    }))
  }

  const handleCoursesAndSpecializationsChange = (value: number): void => {
    const newEspecializations = formData.coursesAndSpecializations.includes(
      value
    )
      ? formData.coursesAndSpecializations.filter(item => item !== value)
      : [...formData.coursesAndSpecializations, value]

    setFormData(prevState => ({
      ...prevState,
      coursesAndSpecializations: newEspecializations
    }))
  }

  const handleImputLevel = (_: ChangeEvent<any>, value: any) => {
    handlerLoadInstitutions(value.id)
    if (
      value.id === EEducationLevelEnum.HIGHEREDUCATION ||
      value.id === EEducationLevelEnum.TECHNICALEDUCATION
    ) {
      handlerLoadCourses(value.id)
    } else {
      setCourses([])
      setFormData(prevData => ({
        ...prevData,
        courses: []
      }))
    }
    setFormData(prevData => ({
      ...prevData,
      educationLevel: value.id
    }))
  }

  const handleInputDistanceChange = (_: Event, newValue: any) => {
    setFormData(prevData => ({
      ...prevData,
      studying: newValue
    }))
  }

  const handleInputCourseTypeChange = (
    _: ChangeEvent<any>,
    value: any
  ): void => {
    if (value.id !== EPeriodsEnum.ANNUAL) {
    } else {
    }
    setFormData(prevData => ({
      ...prevData,
      courseType: value.id
    }))
  }

  const handleInputChangeComplementarCourses = (event: any) => {
    if (event.key === 'Enter') {
      const newCourse = event.target.value.trim()
      if (newCourse !== '') {
        setFormData(prevData => ({
          ...prevData,
          complementarCourses: [...prevData.complementarCourses, newCourse]
        }))
        event.target.value = ''
      }
    }
  }

  const removeComplementarCourse = (index: number) => {
    setFormData(prevData => ({
      ...prevData,
      complementarCourses: prevData.complementarCourses.filter(
        (_, i) => i !== index
      )
    }))
  }

  const handleBlur = (field: keyof TCandidateForm) => {
    setTouchedFields({ ...touchedFields, [field]: true })
    if (field === 'zipCode') {
      handleAddressByZip()
    }
    if (field === 'streetNumber') {
      getLatLngFromAddress(false)
    }
  }

  const getLatLngFromAddress = async (warning: boolean = true) => {
    const fotmatedAddress = formatAddress()
    if (fotmatedAddress) {
      setLoading(true)
      const result = await commonService.getCordinatesByAddress(fotmatedAddress)
      setFormData((formData: any) => ({
        ...formData,
        latitude: result.lat,
        longitude: result.lng
      }))
      setLoading(false)
    } else {
      // if (warning) notification.error('Atenção', 'Endereço incompleto')
    }
  }

  const formatAddress = () => {
    const { streetAddress, city, streetNumber, state, neighborhood } = formData
    const addressParts = [
      streetAddress,
      streetNumber,
      neighborhood,
      city,
      state
    ]
    const nonEmptyParts = addressParts.filter(
      part => part && part.trim() !== ''
    )
    return nonEmptyParts.length === 5
      ? `${nonEmptyParts.join(', ')}, Brasil`
      : false
  }

  const handleAddressByZip = async (
    zipCode: string = formData.zipCode
  ): Promise<void> => {
    if (zipCode?.length > 7) {
      setLoading(true)
      const result = await commonService.getAddressByZipCode(zipCode)

      if (!result.erro) {
        await bindAddress(result)
      } else {
        notification.error(
          'Atenção',
          'Endereço não localizado pelo cep informado'
        )
        setFormData((formData: any) => ({
          ...formData,
          city: '',
          streetAddress: '',
          state: '',
          neighborhood: '',
          ibgeCode: '',
          latitude: null,
          longitude: null
        }))
      }
      setLoading(false)
    }
  }

  async function bindAddress(receivedAddress: TAddressResponse) {
    setFormData((formData: any) => ({
      ...formData,
      zipCode: receivedAddress.cep,
      city: receivedAddress.localidade,
      streetAddress: receivedAddress.logradouro,
      state: receivedAddress.uf,
      neighborhood: receivedAddress.bairro,
      ibgeCode: receivedAddress.ibge
    }))
    return true
  }

  const validation = async (): Promise<boolean> => {
    let mandatoryItens = [
      'name',
      'gender',
      'sexualOrientation',
      'maritalStatus',
      'birthDate',
      'email',
      'cpfNumber',
      'rgNumber',
      'rgIssueDate',
      'nationality',
      'educationalLevels',
      'educationInstitution',
      'graduationDate'
    ]
    if (isUnderage) {
      mandatoryItens = [
        ...mandatoryItens,
        'legalGuardianName',
        'legalGuardianEmail',
        'legalRelationshipDegree',
        'legalRelationshipDegree'
      ]
    }
    if (
      formData.educationLevel === EEducationLevelEnum.TECHNICALEDUCATION ||
      formData.educationLevel === EEducationLevelEnum.HIGHEREDUCATION
    ) {
      mandatoryItens.push('courseId')
    }
    const invalidFields: string[] = []
    for (const [key, value] of Object.entries(formData) as [
      keyof TCandidateForm,
      any
    ][]) {
      if (mandatoryItens.includes(key) && (value === null || value === '')) {
        invalidFields.push(key)
      }
    }

    if (invalidFields.length > 0) {
      const allTrueTouchedFields = Object.fromEntries(
        Object.keys(touchedFields).map(key => [key, true])
      )
      setTouchedFields(allTrueTouchedFields)
      await errorNotification(invalidFields)
      return false
    }
    return true
  }
  const errorNotification = async (invalidFields: any) => {
    const fieldLabels: { [key: string]: string } = {
      name: 'Nome',
      gender: 'Gênero',
      sexualOrientation: 'Orientação Sexual',
      maritalStatus: 'Estado Civil',
      birthDate: 'Data de Nascimento',
      email: 'Email',
      cpfNumber: 'Número de CPF',
      rgNumber: 'Número de RG',
      rgIssueDate: 'Data de Emissão do RG',
      nationality: 'Nacionalidade',
      educationalLevels: 'Nível Educacional',
      educationInstitution: 'Instituição de Ensino',
      graduationDate: 'Data de Graduação',
      legalGuardianName: 'Nome do Responsável Legal',
      legalGuardianEmail: 'Email do Responsável Legal',
      legalRelationshipDegree: 'Grau de Parentesco Legal',
      courseId: 'Curso'
    }
    const invalidFieldsInPortuguese = invalidFields.map(
      (field: string) => fieldLabels[field] || field
    )

    const errorMessage = `Os seguintes campos estão inválidos:<br>${invalidFieldsInPortuguese.join(
      '<br>'
    )}`

    await notification.error('Atenção', errorMessage)
  }

  const prepareToSave = async (form: TCandidateForm) => {
    const newObject: any = Object.assign({}, form)
    return convertEmptyStringsToNull(newObject)
  }
  function convertEmptyStringsToNull(obj: any): any {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '') {
          obj[key] = null
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          convertEmptyStringsToNull(obj[key])
        }
      }
    }
    return obj
  }

  const handleSave = async () => {
    if (!(await validation())) return
    try {
      getLatLngFromAddress()
      const preparedForm = await prepareToSave(formData)
      setLoading(true)
      const result = !id
        ? await candidateService.createCandidate(preparedForm)
        : await candidateService.updateCandidate(preparedForm)
      if (result) {
        notification.success('Sucesso', result)
        goBackPage()
        setLoading(false)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const checkLoadedAddress = async () => {
    if (!formData.ibgeCode && formData.zipCode) {
      const result = await commonService.getAddressByZipCode(formData.zipCode)
      if (!result.erro) {
        await bindAddress(result)
      } else {
        notification.error(
          'Atenção',
          'Endereço não localizado pelo cep informado'
        )
        setFormData((formData: any) => ({
          ...formData,
          ibgeCode: ''
        }))
        getLatLngFromAddress()
      }
    }
  }

  const handlerLoadRequestData = useCallback(async (result: TCandidateForm) => {
    if (result.educationLevel) {
      handlerLoadCourses(Number(result.educationLevel))
      if (result.educationInstitution) {
        await handlerLoadInstitutions(
          Number(result.educationLevel),
          result.educationInstitution
        )
      }
    }

    if (result.socialName) {
      setHasSocialName(true)
    }

    checkIsMinor(result.birthDate)
    setFormData((prevData: any) => ({
      personId: result.personId,
      name: result.name,
      selectedFacility: result.selectedFacility,
      gender: result.gender,
      sexualOrientation: result.sexualOrientation,
      maritalStatus: result.maritalStatus,
      socialName: result.socialName,
      birthDate: dayjs(result.birthDate),
      legalGuardianName: result.legalGuardianName,
      legalGuardianEmail: result.legalGuardianEmail,
      legalRelationshipDegree: result.legalRelationshipDegree,
      legalGuardianCellPhone: result.legalGuardianCellPhone,
      documentType: result.documentType,
      cpfNumber: result.cpfNumber,
      rgNumber: result.rgNumber,
      rgIssueDate: dayjs(result.rgIssueDate),
      nationality: result.nationality,
      allowSpecialNeeds: result.allowSpecialNeeds || 'N',
      specialNeedsType: result.specialNeedsType,
      complementarCourses: result.complementarCourses || [],
      educationInstitution: result.educationInstitution,
      email: result.email,
      phone: result.phone,
      mobile: result.mobile,
      emergencyPhone: result.emergencyPhone,
      zipCode: result.zipCode,
      city: result.city,
      streetAddress: result.streetAddress,
      streetNumber: result.streetNumber,
      state: result.state,
      neighborhood: result.neighborhood,
      complement: result.complement,
      ibgeCode: result.ibgeCode,
      latitude: result.latitude !== 0 ? result.latitude : null,
      longitude: result.longitude !== 0 ? result.longitude : null,
      educationLevel: result.educationLevel,
      courseType: result.courseType,
      studying: result.studying,
      voterId: result.voterId,
      militarId: result.militarId,
      employmentCardDigital: result.employmentCardDigital || 'S',
      employmentNumber: result.employmentNumber,
      employmentSerie: result.employmentSerie,
      courseId: result.courseId !== 0 ? result.courseId : null,
      coursesAndSpecializations: result.coursesAndSpecializations,
      languages: result.languages,
      howDidYouHearAboutISBET: result.howDidYouHearAboutISBET || 1,
      selectedSocialMedia: result.selectedSocialMedia,
      description: result.description,
      isGraduated: result.isGraduated,
      graduationDate: dayjs(result.graduationDate),
      period: result.period
    }))
    checkLoadedAddress()
  }, [])

  const handlerLoadCourses = async (level: number): Promise<void> => {
    try {
      const result = await commonService.getFormationCoursesByLevel(level)
      setCourses(result)
    } catch {}
  }

  const handlerLoadInstitutions = async (
    level: number,
    targetId: null | number = null
  ): Promise<void> => {
    try {
      setLoading(true)
      const result = await commonService.getEducationalInstitutionByLevel({
        searchTerm: '',
        level: level,
        targetId: targetId || '',
        page: 1,
        pageSize: 15
      })
      setEducationInstitutions(result)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerGetCandidate = useCallback(
    async (id: number): Promise<void> => {
      try {
        setLoading(true)
        const result = await candidateService.getCandidateById(id)
        handlerLoadRequestData(result)
      } catch {
      } finally {
        setLoading(false)
      }
    },
    [handlerLoadRequestData]
  )

  const handlerLoadKnowledgeItems = async (): Promise<void> => {
    try {
      setLoading(true)
      const result: TSpecificSkills[] = await commonService.getSpecificSkills({
        pageSize: 100,
        page: 1
      })
      if (result) {
        setLanguagesSkills(
          result.filter(r => r.skillId === EKnowledgeTypeEnum.LANGUAGES)
        )
        setInformaticSkills(
          result.filter(r => r.skillId === EKnowledgeTypeEnum.INFORMATICS)
        )
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const isCourseDisabled = (): boolean => {
    return (
      !formData.educationLevel ||
      formData.educationLevel === EEducationLevelEnum.HIGHSCHOOL
    )
  }

  const handleOpen = async () => {
    setOpen(true)
    if (educationInstitutions.length === 0) {
      const result = await commonService.getEducationalInstitutionByLevel({
        searchTerm: '',
        level: formData.educationLevel
      })
      setEducationInstitutions(result)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleInputCustomAutocomplete = async (_event: any, value: any) => {
    setFormData((formData: any) => ({
      ...formData,
      educationInstitution: value.educationalInstitutionId
    }))
  }

  const handleInputChangeCustomAutocomplete = debounce(
    async (_event: any, value: any) => {
      if (_event?.type !== 'click') {
        try {
          if (!formData.educationLevel) return
          if (value.length > 2 || value.length === 0) {
            setLoadingIntitutionalInput(true)
            const result = await commonService.getEducationalInstitutionByLevel(
              {
                searchTerm: value,
                level: formData.educationLevel
              }
            )
            setEducationInstitutions(result)
          }
        } finally {
          setLoadingIntitutionalInput(false)
        }
      }
    },
    300
  )

  const handlerLoadFilters = async (): Promise<void> => {
    try {
      setGenders(await commonService.getGenders())
      setSocialMidias(await commonService.getSocialMedias())
      setNacionalities(await commonService.getNationalities())
      setMaritalStatuses(await commonService.getMaritalStatuses())
      setFacilitiesFilters(await commonService.getFacilitiesFilters())
      setSexualOrientations(await commonService.getSexualOrientations())
      setHowDidYouHearAboutISBETs(
        await commonService.getHowDidYouHearAboutISBETs()
      )
      setLegalRelationShips(await commonService.getLegalRelationShip())
      setPeriods(await candidateService.availabilities)
    } finally {
    }
  }
  useEffect(() => {
    handlerLoadFilters()
    handlerLoadKnowledgeItems()
  }, [])

  useEffect(() => {
    id && handlerGetCandidate(Number(id))
  }, [handlerGetCandidate, id])

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />{' '}
      </Helmet>
      <CandidateRecordStyle.Container className="w-100">
        <BradCrumbs
          names={breadCrumps}
          paths={[]}
          disabled={false}
          showArrow={true}
          backGroundColor={location.state}
        />
        <CustomSpinner state={location.state} show={isloading} />
        <CandidateRecordStyle.Title>
          {!id ? 'Novo Candidato' : 'Edição de Candidato '}
        </CandidateRecordStyle.Title>
        <CandidateRecordStyle.Content>
          <CandidateRecordStyle.Form>
            <CandidateRecordStyle.FormHeader
              className="py-2 mx-3"
              backgroundColor={location.state}
            >
              <div className="left-side d-flex align-items-center">
                {activeNumbers.length > 0 && (
                  <span
                    className="number-form py-2"
                    style={{
                      backgroundColor: location.state,
                      paddingRight:
                        activeNumbers[activeNumbers.length - 1] === 1
                          ? '16.5px'
                          : '15px',
                      paddingLeft:
                        activeNumbers[activeNumbers.length - 1] === 1
                          ? '16.5px'
                          : '15px'
                    }}
                  >
                    {activeNumbers[activeNumbers.length - 1]}
                  </span>
                )}
                <h5 className="m-0 ms-2">
                  {stepNames[activeNumbers[activeNumbers.length - 1] - 1]}
                </h5>
              </div>
              <div className="right-side d-flex align-items-center">
                {!isDisabled &&
                  [1, 2, 3].map(number => (
                    <span
                      key={number}
                      className={`number-form  py-1 ms-2 ${
                        activeNumbers.includes(number) ? 'active' : ''
                      }`}
                      style={{
                        paddingRight: number === 1 ? '9.5px' : '8px',
                        paddingLeft: number === 1 ? '9.5px' : '8px'
                      }}
                      onClick={() => changeTab(number)}
                    >
                      {number}
                    </span>
                  ))}
              </div>
            </CandidateRecordStyle.FormHeader>
            <div className="w-100 content-container p-3 pt-0 overflow-auto">
              <div
                style={{
                  display:
                    currentPage + 1 === ECandidateStepsEnum.FISTSTEP
                      ? 'block'
                      : 'none'
                }}
              >
                {/* #region FirstStepHTML start */}
                <div className="w-100 ">
                  <div>
                    <div className="row">
                      <div className="col-2">
                        <div className="mt-2">
                          <FormStyle.Label>Unidade ISBET*</FormStyle.Label>
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={facilitiesFilters}
                            sx={{
                              // minWidth: 200,
                              '& .MuiInputBase-root': {
                                height: 40,
                                paddingTop: 0,
                                border: getFieldValidation('selectedFacility')
                                  ? 'solid red 1px'
                                  : ''
                              }
                            }}
                            value={
                              facilitiesFilters.find(
                                option =>
                                  option.id === formData.selectedFacility
                              ) || null
                            }
                            getOptionLabel={option => option.name}
                            getOptionKey={option => option.id}
                            onChange={handleFacilityChange}
                            onBlur={() => handleBlur('selectedFacility')}
                            noOptionsText="Nenhum resultado encontrado"
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Selecione "
                                InputLabelProps={{ shrink: false }}
                              />
                            )}
                            renderOption={(props, option) => (
                              <Box
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                ({option.name})
                              </Box>
                            )}
                            popupIcon={<IoIosArrowDown />}
                          />
                        </div>
                      </div>
                      <div className="col-4 d-flex flex-column mt-3">
                        <FormStyle.Label>Nome Completo</FormStyle.Label>
                        <FormStyle.Field
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          style={{
                            border: getFieldValidation('name')
                              ? 'solid red 1px'
                              : ''
                          }}
                          onBlur={() => handleBlur('name')}
                        />
                      </div>
                      <div className="col-2" style={{ marginTop: '34px' }}>
                        <div className="d-flex">
                          <FormControlLabel
                            control={
                              <Checkbox
                                style={{ color: location.state }}
                                checked={hasSocialName}
                                onChange={event =>
                                  handlerSocialName(event.target.checked)
                                }
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap'
                                }}
                              >
                                Usar Nome Social
                              </span>
                            }
                          />
                        </div>
                      </div>
                      {hasSocialName && (
                        <>
                          <div
                            className="col-4 d-flex flex-column "
                            style={{ marginTop: '13px' }}
                          >
                            <Tooltip
                              title={
                                'Nome social é o nome pelo qual uma pessoa se identifica e quer ser chamada no seu dia a dia, especialmente em contextos sociais e profissionais, e que pode diferir do nome registrado em documentos oficiais. Esse conceito é especialmente relevante para pessoas trans e não-binárias, que podem escolher um nome que reflita melhor sua identidade de gênero'
                              }
                              placement="left"
                              arrow
                            >
                              <FormStyle.Label>
                                Nome Social
                                <IoIosInformationCircleOutline
                                  className="ms-2"
                                  style={{ color: 'orangered' }}
                                  size={20}
                                />
                              </FormStyle.Label>
                            </Tooltip>
                            <FormStyle.Field
                              name="socialName"
                              value={formData.socialName}
                              onChange={handleInputChange}
                              style={{
                                border: getFieldValidation('socialName')
                                  ? 'solid red 1px'
                                  : ''
                              }}
                              onBlur={() => handleBlur('socialName')}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <div className="mt-2">
                          <FormStyle.Label>Sexo*</FormStyle.Label>
                          <Autocomplete
                            id="genders"
                            options={genders}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 40,
                                paddingTop: 0,
                                border: getFieldValidation('gender')
                                  ? 'solid red 1px'
                                  : ''
                              }
                            }}
                            getOptionLabel={option => option.name}
                            getOptionKey={option => option.value}
                            value={
                              genders.find(
                                (option: any) =>
                                  option.value === formData.gender
                              ) || { name: '', value: '' }
                            }
                            disableClearable={true}
                            freeSolo={false}
                            noOptionsText="Nenhum resultado encontrado"
                            onChange={(_, value) =>
                              handleInputChangeAutoComplete('gender', value)
                            }
                            onBlur={() => handleBlur('gender')}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Selecione "
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: true
                                }}
                              />
                            )}
                            popupIcon={<IoIosArrowDown />}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="mt-2">
                          <FormStyle.Label>Genero*</FormStyle.Label>
                          <Autocomplete
                            id="sexualOrientations"
                            options={sexualOrientations}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 40,
                                paddingTop: 0,
                                border: getFieldValidation('sexualOrientation')
                                  ? 'solid red 1px'
                                  : ''
                              }
                            }}
                            getOptionLabel={option => option.value}
                            getOptionKey={option => option.value}
                            value={
                              sexualOrientations.find(
                                (option: any) =>
                                  option.value === formData.sexualOrientation
                              ) || { value: '' }
                            }
                            disableClearable={true}
                            freeSolo={false}
                            noOptionsText="Nenhum resultado encontrado"
                            onChange={(event, value) =>
                              handleInputChangeAutoComplete(
                                'sexualOrientation',
                                value
                              )
                            }
                            onBlur={() => handleBlur('sexualOrientation')}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Selecione "
                                InputLabelProps={{ shrink: false }}
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: true
                                }}
                              />
                            )}
                            popupIcon={<IoIosArrowDown />}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="mt-2">
                          <FormStyle.Label>Estado Civil*</FormStyle.Label>
                          <Autocomplete
                            id="maritalStatuses"
                            options={maritalStatuses}
                            sx={{
                              '& .MuiInputBase-root': {
                                height: 40,
                                paddingTop: 0,
                                border: getFieldValidation('maritalStatus')
                                  ? 'solid red 1px'
                                  : ''
                              }
                            }}
                            getOptionLabel={option => option.name}
                            getOptionKey={option => option.value}
                            value={
                              maritalStatuses.find(
                                (option: any) =>
                                  option.value === formData.maritalStatus
                              ) || { name: '', value: '' }
                            }
                            disableClearable={true}
                            noOptionsText="Nenhum resultado encontrado"
                            onChange={(event, value) =>
                              handleInputChangeAutoComplete(
                                'maritalStatus',
                                value
                              )
                            }
                            onBlur={() => handleBlur('maritalStatus')}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Selecione "
                                // InputvalueProps={{ shrink: false }}
                                inputProps={{
                                  ...params.inputProps,
                                  readOnly: true
                                }}
                              />
                            )}
                            popupIcon={<IoIosArrowDown />}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex flex-column mt-3">
                        <FormStyle.Label>Data da Nascimento*</FormStyle.Label>
                        <FormStyle.DatePickerStyle
                          slots={{ openPickerIcon: calendarIcon }}
                          sx={{
                            height: 40,
                            paddingTop: 0,
                            border: getFieldValidation('birthDate')
                              ? 'solid red 1px'
                              : '',
                            borderRadius: '4px'
                          }}
                          format="DD/MM/YYYY"
                          name="openingRequestDate"
                          value={formData.birthDate}
                          onChange={handleInputChangeDate}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: false,
                              onBlur: () => handleBlur('birthDate')
                            }
                          }}
                        />
                      </div>
                      <div className="col-3 d-flex flex-column mt-3">
                        <FormStyle.Label>E-mail</FormStyle.Label>
                        <FormStyle.Field
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          onBlur={() => handleBlur('email')}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      {isUnderage && (
                        <>
                          <div className="col-4 d-flex flex-column mt-3">
                            <FormStyle.Label>
                              Nome do responsável legal
                            </FormStyle.Label>
                            <FormStyle.Field
                              name="legalGuardianName"
                              value={formData.legalGuardianName || ''}
                              onChange={handleInputChange}
                              style={{
                                border:
                                  isUnderage &&
                                  getFieldValidation('legalGuardianName')
                                    ? 'solid red 1px'
                                    : ''
                              }}
                              onBlur={() => handleBlur('legalGuardianName')}
                            />
                          </div>
                          <div className="col-4 d-flex flex-column mt-3">
                            <FormStyle.Label>
                              E-mail do responsável legal
                            </FormStyle.Label>
                            <FormStyle.Field
                              name="legalGuardianEmail"
                              value={formData.legalGuardianEmail || ''}
                              onChange={handleInputChange}
                              style={{
                                border:
                                  isUnderage &&
                                  getFieldValidation('legalGuardianEmail')
                                    ? 'solid red 1px'
                                    : ''
                              }}
                              onBlur={() => handleBlur('legalGuardianEmail')}
                            />
                          </div>
                          <div className="col-2 d-flex flex-column mt-3">
                            <FormStyle.Label>
                              Grau de parentesco legal
                            </FormStyle.Label>
                            <Autocomplete
                              id="legalRelationshipDegree"
                              options={legalRelationShips}
                              sx={{
                                '& .MuiInputBase-root': {
                                  height: 40,
                                  paddingTop: 0,
                                  border: getFieldValidation(
                                    'legalRelationshipDegree'
                                  )
                                    ? 'solid red 1px'
                                    : ''
                                }
                              }}
                              getOptionLabel={option => option.value}
                              getOptionKey={option => option.value}
                              value={
                                legalRelationShips.find(
                                  (option: any) =>
                                    option.value ===
                                    formData.legalRelationshipDegree
                                ) || { value: '' }
                              }
                              disableClearable={true}
                              freeSolo={false}
                              noOptionsText="Nenhum resultado encontrado"
                              onChange={(event, value) =>
                                handleInputChangeAutoComplete(
                                  'legalRelationshipDegree',
                                  value
                                )
                              }
                              onBlur={() =>
                                handleBlur('legalRelationshipDegree')
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Selecione "
                                  InputLabelProps={{ shrink: false }}
                                  inputProps={{
                                    ...params.inputProps,
                                    readOnly: true
                                  }}
                                />
                              )}
                              popupIcon={<IoIosArrowDown />}
                            />
                          </div>
                          <div className="col-2 d-flex flex-column mt-3">
                            <FormStyle.Label>
                              Celular responsável legal
                            </FormStyle.Label>
                            <FormStyle.FieldMask
                              name="legalGuardianCellPhone"
                              value={formData.legalGuardianCellPhone}
                              onChange={handleInputChange}
                              onBlur={() =>
                                handleBlur('legalGuardianCellPhone')
                              }
                              autoComplete="off"
                              mask="(00) 00000-0000"
                              style={{
                                border:
                                  isUnderage &&
                                  getFieldValidation('legalGuardianCellPhone')
                                    ? 'solid red 1px'
                                    : ''
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <RadioGroup
                          className="mt-3"
                          aria-labelledby="documentType"
                          name="documentType"
                          value={formData.documentType}
                          onChange={handleInputChangeDefault}
                        >
                          <FormStyle.Label>Tipo de documento</FormStyle.Label>
                          <div className="d-flex align-items-center">
                            <FormControlLabel
                              value="C"
                              control={
                                <Radio style={{ color: location.state }} />
                              }
                              label={
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  CPF
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="P"
                              control={
                                <Radio style={{ color: location.state }} />
                              }
                              label={
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Passaporte
                                </span>
                              }
                            />
                          </div>
                        </RadioGroup>
                      </div>
                      {formData.documentType === 'P' && (
                        <div className="col-2 d-flex flex-column mt-3">
                          <FormStyle.Label>Passaporte</FormStyle.Label>
                          <FormStyle.Field
                            name="cpfNumber"
                            value={formData.cpfNumber || ''}
                            onChange={handleInputChange}
                            style={{
                              border:
                                formData.allowSpecialNeeds === 'S' &&
                                getFieldValidation('cpfNumber')
                                  ? 'solid red 1px'
                                  : ''
                            }}
                            onBlur={() => handleBlur('cpfNumber')}
                          />
                        </div>
                      )}
                      {formData.documentType === 'C' && (
                        <>
                          <div className="col-2 d-flex flex-column mt-3">
                            <FormStyle.Label>CPF</FormStyle.Label>
                            <FormStyle.FieldMask
                              name="cpfNumber"
                              value={formData.cpfNumber}
                              onChange={handleInputChange}
                              onBlur={() => handleBlur('cpfNumber')}
                              autoComplete="off"
                              mask="000.000.000-00"
                              style={{
                                border: getFieldValidation('cpfNumber')
                                  ? 'solid red 1px'
                                  : ''
                              }}
                            />
                          </div>
                          <div className="col-2 d-flex flex-column mt-3">
                            <FormStyle.Label>RG</FormStyle.Label>
                            <FormStyle.Field
                              name="rgNumber"
                              type="text"
                              value={formData.rgNumber || ''}
                              onChange={handleInputChange}
                              maxLength={11}
                              style={{
                                border:
                                  formData.allowSpecialNeeds === 'S' &&
                                  getFieldValidation('rgNumber')
                                    ? 'solid red 1px'
                                    : ''
                              }}
                              onBlur={() => handleBlur('rgNumber')}
                            />
                          </div>
                          <div className="col-2 d-flex flex-column mt-3">
                            <FormStyle.Label>
                              Data Emissão do RG
                            </FormStyle.Label>
                            <FormStyle.DatePickerStyle
                              slots={{ openPickerIcon: calendarIcon }}
                              sx={{
                                height: 40,
                                paddingTop: 0,
                                border: getFieldValidation('rgIssueDate')
                                  ? 'solid red 1px'
                                  : '',
                                borderRadius: '4px'
                              }}
                              format="DD/MM/YYYY"
                              name="rgIssueDate"
                              value={formData.rgIssueDate}
                              onChange={handleInputChangeDateRg}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  error: false,
                                  onBlur: () => handleBlur('rgIssueDate')
                                }
                              }}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-2 d-flex flex-column mt-3">
                        <FormStyle.Label>Nacionalidade</FormStyle.Label>
                        <Autocomplete
                          id="combo-box-demo"
                          options={nacionalities}
                          sx={{
                            // minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0,
                              border: getFieldValidation('nationality')
                                ? 'solid red 1px'
                                : ''
                            }
                          }}
                          getOptionLabel={option => option.name}
                          getOptionKey={option => option.name}
                          value={
                            nacionalities.find(
                              (option: any) =>
                                option.name === formData.nationality
                            ) || { id: 0, name: '' }
                          }
                          disableClearable={true}
                          noOptionsText="Nenhum resultado encontrado"
                          onChange={(event, value) =>
                            handleInputChangeAutoComplete('nationality', value)
                          }
                          onBlur={() => handleBlur('nationality')}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione "
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-3">
                        <RadioGroup
                          className="mt-3"
                          aria-labelledby="allowSpecialNeeds"
                          name="allowSpecialNeeds"
                          value={formData.allowSpecialNeeds}
                          onChange={handleInputChangeDefault}
                        >
                          <FormStyle.Label>
                            Portador de Necessidades Especiais
                          </FormStyle.Label>
                          <div className="d-flex align-items-center">
                            <FormControlLabel
                              value="S"
                              control={
                                <Radio style={{ color: location.state }} />
                              }
                              label={
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Sim
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="N"
                              control={
                                <Radio style={{ color: location.state }} />
                              }
                              label={
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Não
                                </span>
                              }
                            />
                          </div>
                        </RadioGroup>
                      </div>
                      {formData.allowSpecialNeeds === 'S' && (
                        <div className="col-3 d-flex flex-column mt-3">
                          <FormStyle.Label>
                            Tipo de necessidades Especiais:
                          </FormStyle.Label>
                          <FormStyle.Field
                            name="specialNeedsType"
                            value={formData.specialNeedsType || ''}
                            onChange={handleInputChange}
                            style={{
                              border:
                                formData.allowSpecialNeeds === 'S' &&
                                getFieldValidation('specialNeedsType')
                                  ? 'solid red 1px'
                                  : ''
                            }}
                            onBlur={() => handleBlur('specialNeedsType')}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-2 mt-2">
                        <FormStyle.Label>Nível de ensino</FormStyle.Label>
                        <Autocomplete
                          disablePortal
                          id="educationalLevels"
                          options={candidateService.educationalLevels}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': {
                              height: 40,
                              paddingTop: 0
                            }
                          }}
                          value={
                            candidateService.educationalLevels.find(
                              option => option.id === formData.educationLevel
                            ) || { id: 0, value: '', label: '' }
                          }
                          getOptionLabel={option => option.label}
                          getOptionKey={option => option.id}
                          disableClearable={true}
                          noOptionsText="Nenhum resultado encontrado"
                          onChange={handleImputLevel}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione"
                              InputLabelProps={{ shrink: false }}
                            />
                          )}
                          popupIcon={<IoIosArrowDown />}
                        />
                      </div>
                      <div className="col-4 d-flex flex-column mt-3">
                        <FormStyle.Label>Nome da instituição</FormStyle.Label>
                        <CustomAutocomplete
                          freeSolo={false}
                          clearIcon={false}
                          options={educationInstitutions}
                          onOpen={handleOpen}
                          onClose={handleClose}
                          getOptionLabel={(option: any) =>
                            option.educationalInstitutionName || ''
                          }
                          getOptionKey={(option: any) =>
                            option.educationalInstitutionId
                          }
                          value={
                            educationInstitutions.find(
                              option =>
                                option.educationalInstitutionId ===
                                formData.educationInstitution
                            ) || {
                              educationalInstitutionId: 0,
                              educationalInstitutionName: ''
                            }
                          }
                          renderOption={(props, option: any) => (
                            <li
                              {...props}
                              key={`${option.educationalInstitutionName}-${option.educationalInstitutionId}`}
                            >
                              {option.educationalInstitutionName}
                            </li>
                          )}
                          sx={{
                            minWidth: 200,
                            '& .MuiInputBase-root': { height: 40 }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              placeholder="Selecione"
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  fontSize: '14px',
                                  fontWeight: 'bold'
                                }
                              }}
                              InputProps={{
                                ...params.InputProps,
                                style: {
                                  fontSize: '14px',
                                  padding: '0 12px'
                                },
                                endAdornment: (
                                  <>
                                    {loadingIntitutionalInput && (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    )}
                                    {params.InputProps.endAdornment}
                                    <FaChevronDown
                                      style={{
                                        transform: open
                                          ? 'rotate(180deg)'
                                          : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease'
                                      }}
                                    />
                                  </>
                                )
                              }}
                            />
                          )}
                          onInputChange={handleInputChangeCustomAutocomplete}
                          onChange={handleInputCustomAutocomplete}
                        />
                      </div>
                      {(formData.educationLevel ===
                        EEducationLevelEnum.HIGHEREDUCATION ||
                        formData.educationLevel ===
                          EEducationLevelEnum.TECHNICALEDUCATION) && (
                        <div className="col mt-2">
                          <FormStyle.Label>Curso</FormStyle.Label>
                          <Autocomplete
                            disabled={isCourseDisabled()}
                            id="courses"
                            options={courses}
                            value={
                              courses.find(
                                option => option.courseId === formData.courseId
                              ) || {
                                courseId: 0,
                                courseName: ''
                              }
                            }
                            disableClearable={true}
                            getOptionLabel={option => option.courseName}
                            getOptionKey={option => option.courseId}
                            noOptionsText="Nenhum resultado encontrado"
                            onChange={(event, value) =>
                              handleInputChangeAutoComplete('courseId', value)
                            }
                            sx={{
                              '& .MuiInputBase-root': {
                                minHeight: 30,
                                paddingTop: 0,
                                paddingBottom: 0
                              },
                              '& .MuiInputBase-root .MuiAutocomplete-tag': {
                                height: '25px'
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                placeholder="Selecione"
                                InputLabelProps={{ shrink: false }}
                              />
                            )}
                            popupIcon={<IoIosArrowDown />}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-2">
                        <RadioGroup
                          className="mt-3"
                          aria-labelledby="isGraduated"
                          name="isGraduated"
                          value={formData.isGraduated}
                          onChange={handleInputChangeDefault}
                        >
                          <FormStyle.Label>Formado</FormStyle.Label>
                          <div className="d-flex align-items-center">
                            <FormControlLabel
                              value="S"
                              control={
                                <Radio style={{ color: location.state }} />
                              }
                              label={
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Sim
                                </span>
                              }
                            />
                            <FormControlLabel
                              value="N"
                              control={
                                <Radio style={{ color: location.state }} />
                              }
                              label={
                                <span
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    whiteSpace: 'nowrap'
                                  }}
                                >
                                  Não
                                </span>
                              }
                            />
                          </div>
                        </RadioGroup>
                      </div>
                      {formData.isGraduated === 'N' && (
                        <>
                          <div className="col-2 mt-2">
                            <FormStyle.Label>Período</FormStyle.Label>
                            <Autocomplete
                              disablePortal
                              id="availabilities"
                              options={periods}
                              sx={{
                                minWidth: 200,
                                '& .MuiInputBase-root': {
                                  height: 40,
                                  paddingTop: 0
                                }
                              }}
                              getOptionLabel={option => option.label}
                              getOptionKey={option => option.value}
                              disableClearable={true}
                              noOptionsText="Nenhum resultado encontrado"
                              onChange={handleInputAvailabilitiesChange}
                              value={
                                periods.find(
                                  (option: any) =>
                                    option.value === formData.period
                                ) || { id: 0, value: '', label: '' }
                              }
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Selecione "
                                  InputLabelProps={{ shrink: false }}
                                />
                              )}
                              popupIcon={<IoIosArrowDown />}
                            />
                          </div>
                          <div className="col-2 mt-2">
                            <FormStyle.Label>Tipo de Curso</FormStyle.Label>
                            <Autocomplete
                              disablePortal
                              id="courseTypes"
                              options={candidateService.courseTypes}
                              sx={{
                                minWidth: 200,
                                '& .MuiInputBase-root': {
                                  height: 40,
                                  paddingTop: 0
                                }
                              }}
                              value={candidateService.courseTypes.find(
                                (option: any) =>
                                  option.id === formData.courseType
                              )}
                              getOptionLabel={option => option.label}
                              getOptionKey={option => option.id}
                              disableClearable={true}
                              noOptionsText="Nenhum resultado encontrado"
                              onChange={handleInputCourseTypeChange}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  placeholder="Selecione "
                                  InputLabelProps={{ shrink: false }}
                                />
                              )}
                              popupIcon={<IoIosArrowDown />}
                            />
                          </div>
                          <div className="col-2 d-flex flex-column mt-3">
                            <FormStyle.Label>
                              Cursando ({' '}
                              {formData.courseType === EPeriodsEnum.ANNUAL
                                ? 'anual '
                                : 'semestre '}
                              )
                            </FormStyle.Label>
                            <Box>
                              <Slider
                                className="mt-1"
                                sx={{ color: location.state }}
                                marks={marks}
                                step={1}
                                value={formData.studying}
                                valueLabelDisplay="auto"
                                min={
                                  formData.courseType === EPeriodsEnum.ANNUAL
                                    ? MINANN
                                    : MINSEMI
                                }
                                max={
                                  formData.courseType === EPeriodsEnum.ANNUAL
                                    ? MAXANN
                                    : MAXSEMI
                                }
                                onChange={handleInputDistanceChange}
                              />
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  {formData.courseType === EPeriodsEnum.ANNUAL
                                    ? MINANN
                                    : MINSEMI}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ cursor: 'pointer' }}
                                >
                                  {formData.courseType === EPeriodsEnum.ANNUAL
                                    ? MAXANN
                                    : MAXSEMI}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        </>
                      )}
                      <div className="col-2 d-flex flex-column mt-3">
                        <FormStyle.Label>Término do Curso</FormStyle.Label>
                        <FormStyle.DatePickerStyle
                          slots={{ openPickerIcon: calendarIcon }}
                          sx={{
                            height: 40,
                            paddingTop: 0,
                            border: getFieldValidation('graduationDate')
                              ? 'solid red 1px'
                              : '',
                            borderRadius: '4px'
                          }}
                          format="MM/YYYY"
                          name="graduationDate"
                          value={formData.graduationDate}
                          onChange={handleInputChangeDateGraduation}
                          slotProps={{
                            textField: {
                              size: 'small',
                              error: false,
                              onBlur: () => handleBlur('graduationDate')
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* endregion */}
              </div>
              <div
                style={{
                  display:
                    currentPage + 1 === ECandidateStepsEnum.SECONDSTEP
                      ? 'block'
                      : 'none'
                }}
              >
                {/* #region SecondStepHTML start */}
                <div className="row ">
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>Telefone</FormStyle.Label>
                    <FormStyle.FieldMask
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('phone')}
                      autoComplete="off"
                      mask="(00) 0000-0000"
                    />
                  </div>
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>Celular</FormStyle.Label>
                    <FormStyle.FieldMask
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('mobile')}
                      autoComplete="off"
                      mask="(00) 00000-0000"
                    />
                  </div>
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>Telefone Emergência</FormStyle.Label>
                    <FormStyle.FieldMask
                      name="emergencyPhone"
                      value={formData.emergencyPhone}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('emergencyPhone')}
                      autoComplete="off"
                      mask="(00) 00000-0000"
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>CEP</FormStyle.Label>
                    <FormStyle.FieldMask
                      name="zipCode"
                      value={formData.zipCode}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('zipCode')}
                      autoComplete="off"
                      mask="00000-000"
                    />
                  </div>
                  <div className="col-6 d-flex flex-column mt-3">
                    <FormStyle.Label>Rua*</FormStyle.Label>
                    <FormStyle.Field
                      name="streetAddress"
                      value={formData.streetAddress}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('streetAddress')}
                    />
                  </div>
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>Número*</FormStyle.Label>
                    <FormStyle.Field
                      name="streetNumber"
                      value={formData.streetNumber}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('streetNumber')}
                    />
                  </div>
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>Complemento</FormStyle.Label>
                    <FormStyle.Field
                      name="complement"
                      value={formData.complement}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>Estado*</FormStyle.Label>
                    <FormStyle.Field
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('state')}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column mt-3">
                    <FormStyle.Label>Cidade*</FormStyle.Label>
                    <FormStyle.Field
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('city')}
                    />
                  </div>
                  <div className="col-3 d-flex flex-column mt-3">
                    <FormStyle.Label>Bairro*</FormStyle.Label>
                    <FormStyle.Field
                      name="neighborhood"
                      value={formData.neighborhood}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('neighborhood')}
                    />
                  </div>
                </div>
                {/* endregion */}
              </div>
              <div
                style={{
                  display:
                    currentPage + 1 === ECandidateStepsEnum.THIRDSTEP
                      ? 'block'
                      : 'none'
                }}
              >
                {/* #region ThirdStepHTML start */}
                <div className="row ">
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>Título de eleitor</FormStyle.Label>
                    <FormStyle.Field
                      name="voterId"
                      value={formData.voterId}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('voterId')}
                    />
                  </div>
                  <div className="col-2 d-flex flex-column mt-3">
                    <FormStyle.Label>
                      Certificado de Reservista{' '}
                    </FormStyle.Label>
                    <FormStyle.Field
                      name="militarId"
                      value={formData.militarId}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur('militarId')}
                    />
                  </div>
                  <div className="col-2 ">
                    <RadioGroup
                      className="mt-3 ps-4"
                      name="employmentCardDigital"
                      value={formData.employmentCardDigital}
                      onChange={handleInputChangeDefault}
                    >
                      <FormStyle.Label>Carteira digital </FormStyle.Label>
                      <div className="d-flex align-items-center">
                        <FormControlLabel
                          value="S"
                          control={<Radio style={{ color: location.state }} />}
                          label={
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Sim
                            </span>
                          }
                        />
                        <FormControlLabel
                          value="N"
                          control={<Radio style={{ color: location.state }} />}
                          label={
                            <span
                              style={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                whiteSpace: 'nowrap'
                              }}
                            >
                              Não
                            </span>
                          }
                        />
                      </div>
                    </RadioGroup>
                  </div>
                  {formData.employmentCardDigital === 'N' && (
                    <>
                      <div className="col-2 d-flex flex-column mt-3">
                        <FormStyle.Label>Número</FormStyle.Label>
                        <FormStyle.Field
                          name="employmentNumber"
                          value={formData.employmentNumber}
                          onChange={handleInputChange}
                          onBlur={() => handleBlur('employmentNumber')}
                        />
                      </div>
                      <div className="col-2 d-flex flex-column mt-3">
                        <FormStyle.Label>Série</FormStyle.Label>
                        <FormStyle.Field
                          name="employmentSerie"
                          value={formData.employmentSerie}
                          onChange={handleInputChange}
                          onBlur={() => handleBlur('employmentSerie')}
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-column mt-3">
                      <FormStyle.Label>
                        Cursos e especializações
                      </FormStyle.Label>
                      <div
                        className="d-flex w-100 flex-wrap"
                        style={{
                          border: '1px solid #CCC',
                          borderRadius: '5px',
                          padding: '8px'
                        }}
                      >
                        {informaticSkills.map((lang, index) => (
                          <button
                            key={index}
                            className="me-2 mb-1 mt-1"
                            style={{
                              height: 30,
                              backgroundColor:
                                formData.coursesAndSpecializations.includes(
                                  lang.id
                                )
                                  ? location.state
                                  : 'white',
                              border: '1px solid gray',
                              color:
                                formData.coursesAndSpecializations.includes(
                                  lang.id
                                )
                                  ? 'white'
                                  : 'black',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              borderRadius: '25px',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              fontSize: '13px'
                            }}
                            type="button"
                            onClick={() =>
                              handleCoursesAndSpecializationsChange(lang.id)
                            }
                          >
                            {lang.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="d-flex flex-column mt-4">
                      <FormStyle.Label>
                        Cursos Complementares{' '}
                        <small style={{ fontSize: '10px', marginLeft: '10px' }}>
                          (Após digitar pressione a tecla enter)
                        </small>
                      </FormStyle.Label>

                      <FormStyle.Field
                        onKeyDown={handleInputChangeComplementarCourses}
                        placeholder="Após digitar pressione a tecla enter"
                      />
                      <div className="d-flex flex-wrap">
                        {formData.complementarCourses.map((activity, index) => (
                          <div
                            key={index}
                            style={{
                              marginTop: '5px',
                              marginRight: '5px',
                              minHeight: 30,
                              backgroundColor: location.state,
                              border: '1px solid gray',
                              color: 'white',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              borderRadius: '25px',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              fontSize: '13px'
                            }}
                          >
                            {activity}
                            <MdCancel
                              className="ms-2"
                              size={20}
                              onClick={() => removeComplementarCourse(index)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="d-flex flex-column mt-4">
                      <FormStyle.Label>Idiomas</FormStyle.Label>
                      <div
                        className="d-flex w-100 flex-wrap"
                        style={{
                          border: '1px solid #CCC',
                          borderRadius: '5px',
                          padding: '8px'
                        }}
                      >
                        {languagesSkills.map((lang, index) => (
                          <button
                            key={index}
                            className="me-2 mb-1 mt-1"
                            style={{
                              height: 30,
                              backgroundColor: formData.languages.includes(
                                lang.id
                              )
                                ? location.state
                                : 'white',
                              border: '1px solid gray',
                              color: formData.languages.includes(lang.id)
                                ? 'white'
                                : 'black',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              cursor: 'pointer',
                              borderRadius: '25px',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              fontSize: '13px'
                            }}
                            type="button"
                            onClick={() => handleLanguageChange(lang.id)}
                          >
                            {lang.name}
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-3">
                    <RadioGroup
                      className="mt-3"
                      aria-labelledby="howDidYouHearAboutISBET"
                      name="howDidYouHearAboutISBET"
                      value={formData.howDidYouHearAboutISBET}
                      onChange={handleInputChangeDefault}
                    >
                      <FormStyle.Label>Como conheceu o ISBET?</FormStyle.Label>
                      <Autocomplete
                        id="howDidYouHearAboutISBET"
                        options={howDidYouHearAboutISBETs}
                        sx={{
                          minWidth: 200,
                          '& .MuiInputBase-root': {
                            height: 40,
                            paddingTop: 0,
                            border: getFieldValidation(
                              'howDidYouHearAboutISBET'
                            )
                              ? 'solid red 1px'
                              : ''
                          }
                        }}
                        getOptionLabel={option => option.value}
                        getOptionKey={option => option.id}
                        value={
                          howDidYouHearAboutISBETs.find(
                            (option: any) =>
                              option.id === formData.howDidYouHearAboutISBET
                          ) || { id: 0, value: '' }
                        }
                        disableClearable={true}
                        noOptionsText="Nenhum resultado encontrado"
                        onChange={(event, value) =>
                          handleInputChangeAutoComplete(
                            'howDidYouHearAboutISBET',
                            value
                          )
                        }
                        onBlur={() => handleBlur('howDidYouHearAboutISBET')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Selecione "
                            InputLabelProps={{ shrink: false }}
                          />
                        )}
                        popupIcon={<IoIosArrowDown />}
                      />
                    </RadioGroup>
                  </div>
                  {formData.howDidYouHearAboutISBET ===
                    EHowDidYouHearAboutISBETEnum.OTHERS && (
                    <div className="col-3 d-flex flex-column mt-3">
                      <FormStyle.Label>Descreva</FormStyle.Label>
                      <FormStyle.Field
                        name="description"
                        value={formData.description || ''}
                        onChange={handleInputChange}
                        style={{
                          border:
                            formData.howDidYouHearAboutISBET ===
                              EHowDidYouHearAboutISBETEnum.OTHERS &&
                            getFieldValidation('description')
                              ? 'solid red 1px'
                              : ''
                        }}
                        onBlur={() => handleBlur('description')}
                      />
                    </div>
                  )}
                  {formData.howDidYouHearAboutISBET ===
                    EHowDidYouHearAboutISBETEnum.SOCIALMIDIA && (
                    <div className="col-3 mt-2 ">
                      <FormStyle.Label>Selecione</FormStyle.Label>
                      <Autocomplete
                        id="combo-box-demo"
                        options={socialMidias}
                        sx={{
                          minWidth: 200,
                          '& .MuiInputBase-root': {
                            height: 40,
                            paddingTop: 0,
                            border:
                              getFieldValidation('selectedSocialMedia') &&
                              formData.howDidYouHearAboutISBET ===
                                EHowDidYouHearAboutISBETEnum.SOCIALMIDIA
                                ? 'solid red 1px'
                                : ''
                          }
                        }}
                        getOptionLabel={option => option.name}
                        getOptionKey={option => option.id}
                        value={
                          socialMidias.find(
                            (option: any) =>
                              option.id === formData.selectedSocialMedia
                          ) || { id: 0, name: '' }
                        }
                        disableClearable={true}
                        noOptionsText="Nenhum resultado encontrado"
                        onChange={(_, value) =>
                          handleInputChangeAutoComplete(
                            'selectedSocialMedia',
                            value
                          )
                        }
                        onBlur={() => handleBlur('selectedSocialMedia')}
                        renderInput={params => (
                          <TextField
                            {...params}
                            placeholder="Selecione "
                            InputLabelProps={{ shrink: false }}
                          />
                        )}
                        popupIcon={<IoIosArrowDown />}
                      />
                    </div>
                  )}
                </div>
                <div className="row"></div>
                {/* endregion */}
              </div>
            </div>
            {currentPage < 2 ? (
              <div className="d-flex w-100 next-form justify-content-end p-3 mt-3">
                <span
                  className="me-3"
                  onClick={goBackPage}
                  style={{
                    backgroundColor: location.state,
                    borderColor: location.state
                  }}
                >
                  Cancelar
                </span>
                {!isDisabled && (
                  <span
                    onClick={handleNextClick}
                    style={{
                      backgroundColor: location.state,
                      borderColor: location.state
                    }}
                  >
                    Próximo <IoIosArrowDown />
                    <div className="form-name">
                      <span
                        style={{
                          backgroundColor: location.state,
                          borderColor: location.state
                        }}
                      >
                        {stepNames[currentPage + 1]}
                      </span>
                    </div>
                  </span>
                )}
              </div>
            ) : (
              <div className="d-flex w-100 next-form justify-content-end p-3 mt-3">
                <span
                  className="me-3"
                  onClick={goBackPage}
                  style={{
                    backgroundColor: location.state,
                    borderColor: location.state
                  }}
                >
                  Cancelar
                </span>
                <div className="form-name">
                  <button
                    disabled={isDisabled}
                    className="btn"
                    type="button"
                    onClick={handleSave}
                    style={{
                      backgroundColor: location.state,
                      color: 'white'
                    }}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            )}
          </CandidateRecordStyle.Form>
        </CandidateRecordStyle.Content>
      </CandidateRecordStyle.Container>
    </>
  )
}

export default CandateRecordPage
