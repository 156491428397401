import { Navigate } from 'react-router-dom'
import LoginPage from '../../pages/authentication/login'
import Internship from '../../pages/Internship'
import { Fragment, useState } from 'react'
import { TPrivateRouteProps } from '../models'
import BaseLayout from '../../pages/templates/baseLayout'
import ForgotPasswordPage from '../../pages/authentication/forgot-password'
import NewPasswordPage from '../../pages/authentication/new-password'
import Guidance from '../../pages/guidance'
import { authService } from '../services'
import DashboardPage from '../../pages/dashboard'
import Apprendice from '../../pages/apprentice'
import Reports from '../../pages/guidance'
import NotFoundPage from '../../pages/not-found'
import Registrations from '../../pages/registrations'
import BuidingPage from '../../pages/building-page'
import Teste from '../../pages/Internship/components/teste'
import OpportunityRecord from '../../pages/Internship/components/opportunity-record'
import Calendar from '../../pages/calendar'
import IndicatorsPage from '../../pages/indicators'
import IndicatorRecord from '../../pages/indicators/components/indicator-record'
import ScoreRangePage from '../../pages/score-range'
import ScoreRangeRecord from '../../pages/score-range/components/score-range-record'
import CandidatePage from '../../pages/candidate'
import CandateRecordPage from '../../pages/candidate/components/candidate-record'

const PrivateRoute: React.FC<TPrivateRouteProps> = ({ children }) => {
  const [isAuthenticated] = useState(authService.isAuthenticated())
  if (!isAuthenticated) {
    return <Navigate to={'/login'} replace />
  }

  if (window.location.pathname.includes('not-implemented')) {
    return (
      <BaseLayout>
        <BuidingPage />
      </BaseLayout>
    )
  }

  return <Fragment>{children}</Fragment>
}

const routes = [
  {
    path: '/',
    element: <Navigate to="/login" />
  },
  {
    path: '/teste',
    element: <Teste />
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />
  },
  {
    path: '/new-password/:token/:id',
    element: <NewPasswordPage />
  },
  {
    path: '/dashboard',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <DashboardPage />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/indicators',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <IndicatorsPage />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/indicators/indicators-record',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <IndicatorRecord />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/indicators/indicators-record/:id',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <IndicatorRecord />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/score-range',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <ScoreRangePage />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/score-range/score-range-record',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <ScoreRangeRecord />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/score-range/score-range-record/:id',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <ScoreRangeRecord />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/registration/calendar',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <Calendar />
        </BaseLayout>
      </PrivateRoute>
    )
  },

  {
    path: '/intership',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <Internship />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/intership/opportunity-record',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <OpportunityRecord />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/intership/opportunity-record/:id',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <OpportunityRecord />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/intership/opportunity-record/:id/:clone',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <OpportunityRecord />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/guidance',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <Guidance />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/apprendice',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <Apprendice />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/reports',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <Reports />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/registrations',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <Registrations />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/candidate',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <CandidatePage />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/candidate/candidate-record',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <CandateRecordPage />
        </BaseLayout>
      </PrivateRoute>
    )
  },
  {
    path: '/candidate/candidate-record/:id',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <CandateRecordPage />
        </BaseLayout>
      </PrivateRoute>
    )
  },

  {
    path: '*',
    element: (
      <PrivateRoute>
        <BaseLayout>
          <CandateRecordPage />
        </BaseLayout>
      </PrivateRoute>
    )
  }
]

export default routes
