import Modal from 'react-bootstrap/Modal'
import { DefaultTableStyle } from '../../../../shared/components/common/default-table/style'
import { IoIosArrowDown } from 'react-icons/io'
import { useEffect, useState } from 'react'
import './style.css'
import { calendarService } from '../../../../shared/services/calendar'
import CustomSpinner from '../../../../shared/components/common/custom-spinner'

const CandidatesModal = ({
  show,
  onClose,
  title,
  icon,
  state,
  eventId
}: any) => {
  const options = {
    striped: true,
    sortable: true,
    hover: true,
    responsive: true,
    size: 'sm',
    showEdit: true,
    showDelete: true,
    skipItems: ['id', 'jobVacancyId'],
    clickable: ['Empresa'],
    backgroundColor: state || '#004699'
  }

  const headers: any = [
    {
      id: 1,
      label: 'Nome',
      sortable: true,
      isSort: true
    },
    {
      id: 2,
      label: 'Email',
      sortable: true,
      isSort: false
    },
    {
      id: 3,
      label: 'Telefone',
      sortable: true,
      isSort: false
    },
    {
      id: 4,
      label: 'Tipo',
      sortable: true,
      isSort: false
    },
    {
      id: 5,
      label: 'Área',
      sortable: true,
      isSort: false
    },
    {
      id: 6,
      label: 'Empresa',
      sortable: true,
      isSort: false
    }
  ]

  const [tableHeaders, setTableHeaders] = useState(headers)
  const [body, setBody] = useState(headers)
  const [isloading, setLoading] = useState<boolean>(false)

  const sortByClick = (clickedHeader: any) => {
    if (clickedHeader.sortable) {
      const updatedHeaders = headers.map((header: any) => ({
        ...header,
        isSort: header.id === clickedHeader.id ? !header.isSort : false
      }))
      setTableHeaders(updatedHeaders)
      // sortHeader(clickedHeader)
    }
  }

  const handlerLoadCandidates = async () => {
    try {
      setLoading(true)
      const event = await calendarService.getEventCandidates(eventId)
      setBody(event)
    } catch {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handlerLoadCandidates()
  }, [])
  return (
    <Modal show={show} onHide={onClose} centered dialogClassName="modal-xl">
      <CustomSpinner state={state || '#004699'} show={isloading} />
      <Modal.Header closeButton>
        {icon && (
          <span
            className="icon me-2"
            style={{
              border: '1px solid #c1c1c1',
              padding: '5px 7px',
              borderRadius: '5px'
            }}
          >
            {icon}
          </span>
        )}
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div
          className="mt-3 w-100"
          style={{
            maxHeight: '400px',
            overflowY: 'auto',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderTop: '1px solid #ccc',
            marginTop: '-4px',
            paddingTop: 0
          }}
        >
          <DefaultTableStyle.CustomTable
            striped={options?.striped}
            className="w-100"
          >
            <thead
              style={{
                height: '60px',
                backgroundColor: '#FCFCFC',
                position: 'sticky',
                top: '0',
                zIndex: '1'
              }}
            >
              <tr>
                {tableHeaders.map((header: any) =>
                  options.skipItems.length &&
                  !options.skipItems.includes(header.label.toLowerCase()) ? (
                    <th
                      style={{ paddingBottom: '17px', background: '#FCFCFC' }}
                      key={header.id}
                      onClick={() => sortByClick(header)}
                    >
                      {header.label}
                      {header.sortable ? (
                        <IoIosArrowDown
                          className={header.isSort ? 'rotate' : 'reverse'}
                        />
                      ) : null}
                    </th>
                  ) : null
                )}
              </tr>
            </thead>
            <tbody>
              {body.map((row: any) => (
                <tr key={row.id}>
                  {Object.keys(row)
                    .filter(key => !options.skipItems.includes(key))
                    .map((key, index) => (
                      <td
                        style={{
                          color: '#7D8398',
                          fontSize: '14px',
                          paddingTop: '14px',
                          width: index === 1 ? '135px' : 'auto'
                        }}
                        key={key}
                      >
                        {row[key]}
                      </td>
                    ))}
                </tr>
              ))}
            </tbody>
          </DefaultTableStyle.CustomTable>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CandidatesModal
