import { Select } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { FaSearch } from 'react-icons/fa'
import { IMaskInput } from 'react-imask'
import styled from 'styled-components'

export namespace FormStyle {
  export const Dropdown = styled(Select)`
    height: 41px !important;
    border-radius: 6px !important;
    font-size: 0.9rem;
    svg {
      font-size: 20px;
      fill: var(--icons-primary, #262e3d);
    }
    .MuiSelect-select {
      font-size: 0.7rem;
      font-family: Inter !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid var(--base-line, #e6e7ec) !important;
    }
  `

  export const TimePickerStyle = styled(TimePicker)`
    width: 100%;
    .MuiInputLabel-root {
      top: -5px !important;
    }
    .MuiInputBase-root {
      border-radius: 6px !important;
      border: 1px solid var(--base-line, #e6e7ec);
      .MuiOutlinedInput-input {
        height: 0.6rem !important;
      }
    }
  `

  export const TimePickerEventStyle = styled(TimePicker)`
    width: 98%;

    .MuiInputLabel-root {
      top: -5px !important;
    }
    .MuiInputBase-root {
      border-radius: 4px !important;
      border: 1px solid var(--base-line, #e6e7ec);
      .MuiOutlinedInput-input {
        height: 0.6rem !important;
      }
    }
  `
  export const DatePickerStyle = styled(DatePicker)`
    .MuiInputLabel-root {
      top: -5px !important;
    }
    .MuiInputBase-root {
      height: 41px;

      border-radius: 4px !important;
      border: 1px solid var(--base-line, #e6e7ec);
      .MuiOutlinedInput-input {
        font-size: 15px;
        height: 0.6rem !important;
        border-right: 1px solid var(--base-line, #e6e7ec);
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: rgb(0 0 0 / 0%);
    }
  `

  export const TimePickerContainer = styled.div`
    width: 90%;
  `

  export const Field = styled.input`
    height: 41px !important;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-radius: 3px !important;
    border: 1px solid var(--base-line, #e6e7ec);
    transition: 0.25s;
    &:focus {
      outline: 1px solid gray;
    }
  `
  export const SearchInput = styled.input`
    width: 100%;
    height: 41px !important;
    padding-left: 2rem;
    border-radius: 5px !important;
    border: 1px solid var(--base-line, #e6e7ec);
    transition: 0.25s;
    &:focus {
      outline: 1px solid gray;
    }
  `

  export const SearchIcon = styled(FaSearch)`
    position: absolute;
    top: 50%;
    left: 0.7rem;
    transform: translateY(-50%);
    color: #888;
  `

  export const FieldMask = styled(IMaskInput)`
    height: 41px !important;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-radius: 3px !important;
    border: 1px solid var(--base-line, #e6e7ec);
    transition: 0.25s;
    &:focus {
      outline: 1px solid gray;
    }
  `

  export const TextArea = styled.textarea`
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    border-radius: 6px !important;
    border: 1px solid var(--base-line, #e6e7ec);
    transition: 0.25s;
    &:focus {
      outline: 1px solid gray;
    }
  `

  export const Label = styled.label`
    color: #151515;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.5rem;
    margin-top: -6px;
    white-space: nowrap;
    @media screen and (max-width: 1700px) {
      font-size: 14px;
    }
  `
}
