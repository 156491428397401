import Modal from 'react-bootstrap/Modal'
import { FadeLoader } from 'react-spinners'
import './style.css'
const CustomSpinner = ({ show, onClose, state }: any) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      style={{
        left: window.innerWidth > 1366 ? '13%' : '17%'
      }}
      animation={false}
    >
      <FadeLoader
        style={{ background: 'transparent' }}
        color={state}
        loading={show}
      />
    </Modal>
  )
}

export default CustomSpinner
