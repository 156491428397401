import { createTheme } from '@mui/material/styles'

//configuracao de cor padrao para o componente com floating label
export const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'gray'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'gray'
          }
        }
      }
    }
  }
})
