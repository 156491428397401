import { Accordion } from '@mui/material'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import styled, { keyframes } from 'styled-components'

export namespace IndicatorRecordStyle {
  interface Theme {
    backgroundColor: string
  }

  export const Container = styled.div`
    height: 100% !important;
    overflow: hidden;
  `
  export const Content = styled.div`
    height: 90%;
    width: 97%;
    margin-left: 1.5%;
    background: #fff;
    .next-form {
      span {
        cursor: pointer;
        transition: 0.25s;
        display: flex;
        align-items: center;
        border-radius: 12px;
        border: 1px solid;
        padding: 5px 20px;
        color: white;
        &:hover {
          .form-name {
            span {
              opacity: 0.8;
            }
          }
        }
      }

      .form-name {
        span {
          transition: 0.25s;
          border-radius: 12px;
          border: 1px solid;
          :hover {
            opacity: 0.8;
          }
        }
      }
      svg {
        margin-top: 0.1rem;
        margin-left: 1rem;
        margin-right: 1rem;
        transform: scale(1.4) rotateZ(270deg);
      }
    }
  `
  export const Title = styled.h2`
    font-size: 28px;
    margin-left: 2.6%;
    margin-top: 2%;
    font-weight: 500;
  `
  export const Form = styled.form`
    width: 100%;
    height: 100%;
    .content-container {
      overflow-y: auto;
      overflow-x: hidden !important;
      height: 75%;
      width: 100%;
      @media screen and (max-width: 1700px) {
        height: 70%;
      }
    }
  `

  export const FormHeader = styled.div<Theme>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e7ec;
    .left-side {
      .number-form {
        color: #fff;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        border-radius: 50%;
      }
      h5 {
        color: #151515;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .right-side {
      .number-form {
        transition: 0.25s;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        color: black;
        background: #e4e4e4;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        &.active {
          color: #fff;
          background: ${({ backgroundColor }) => backgroundColor};
          &::after {
            position: absolute;
            content: '';
            left: -10px;
            top: 8px;
            width: 10px;
            height: 4px;
            background: ${({ backgroundColor }) => backgroundColor};
          }
          &:first-child {
            &::after {
              position: absolute;
              content: '';
              right: -10px !important;
              left: 21px;
              top: 8px;
              width: 10px;
              height: 4px;
              background: ${({ backgroundColor }) => backgroundColor};
            }
          }

          &:last-child {
            &::after {
              display: initial;
            }
          }
        }
        &:last-child {
          &::after {
            display: none;
          }
        }
        &::after {
          position: absolute;
          content: '';
          right: -10px;
          top: 8px;
          width: 10px;
          height: 4px;
          background: #e4e4e4;
        }
      }
    }
  `
}

export namespace FirstStepStyle {
  export const Title = styled.h2`
    color: black;
    opacity: 0.6;
    font-size: 1.4rem;
    font-weight: bold;
  `
  export const AccordionContent = styled(Accordion)`
    box-shadow: none !important;
    border: 1px solid #e6e7ec;
    &.active {
      border: 1px solid #004699 !important;
    }
  `

  export const CustomAutoComplete = styled(AsyncTypeahead)`
    height: 41px !important;
    border-radius: 3px !important;
    border: 1px solid var(--base-line, #e6e7ec);
    box-shadow: none !important;
    &:focus {
      outline: 1px solid gray;
    }
  `

  export const DayTitle = styled.h6`
    font-size: 1rem;
  `

  const rippleAnimation = keyframes`
    to {
      transform: scale(2);
      opacity: 0;
    }
  `
  export const ActivityList = styled.ul`
    list-style: decimal;

    button {
      position: relative;
      overflow: hidden;
      border: none;
      background: none;
      font-size: 1.3rem;
      cursor: pointer;
      border-radius: 50%;
      transition: 0.25s;
      padding: 3px 10px;
      outline: none;

      &:after {
        content: '';
        position: absolute;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        transform: scale(0);
        transform-origin: center;
        animation: ${rippleAnimation} 0.6s linear;
      }

      &.cancel {
        color: red !important;

        &:hover {
          background: red;
          color: white !important;
        }
      }

      &.delete {
        color: red !important;

        &:hover {
          background: red;
          color: white !important;
        }
      }

      &.edit {
        color: #004699;

        &:hover {
          background: #004699;
          color: white !important;
        }
      }

      &.save {
        color: green;

        &:hover {
          background: green;
          color: white !important;
        }
      }

      &:focus:after,
      &:active:after {
        transform: scale(2);
        opacity: 0;
      }
    }
  `
}

export namespace SecondStepStyle {
  export const Container = styled.div`
    width: 100%;
  `

  export const Label = styled.span`
    font-size: 16px;
    font-weight: 600;
  `
}

export namespace ThirdStepStyle {
  export const Container = styled.div`
    width: 100%;
  `

  export const Label = styled.span`
    font-size: 16px;
    font-weight: 600;
  `
}

export namespace FourthStepStyle {
  export const Container = styled.div`
    width: 100%;
  `

  export const Label = styled.span`
    font-size: 16px;
    font-weight: 600;
  `
}
