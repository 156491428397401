import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import BradCrumbs from '../../../../shared/components/common/breadcrumbs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { notification, scoreRangeService } from '../../../../shared/services'
import { FormStyle } from '../../../../shared/styles/form.style'
import { FormControlLabel, Checkbox } from '@mui/material'
import CustomSpinner from '../../../../shared/components/common/custom-spinner'
import { TIndicatorForm } from '../../../../core/models/indicator.model'
import { ScoreRangeRecordStyle } from './style'
import './style.css'
import { TScoreRangeForm } from '../../../../core/models'

const ScoreRangeRecord: React.FC = () => {
  let { id } = useParams()
  const location = useLocation()
  const navigate = useNavigate()

  if (!location.state) {
    location.state = '#004699'
  }

  const breadCrumps = !id
    ? ['Faixa de Pontuação', 'Cadastro de Faixa']
    : ['Faixa de Pontuação', 'Edição de Faixa']

  const [isloading, setLoading] = useState<boolean>(false)
  const form: TScoreRangeForm = {
    id: null,
    name: '',
    minimumScore: 0,
    maximumScore: 0,
    active: true
  }

  const goBackPage = () => {
    navigate('/score-range')
  }

  const [formData, setFormData] = useState<TScoreRangeForm>(form)

  const validation = (): boolean => {
    const mandatoryItens = ['name', 'minimumScore', 'maximumScore']
    const invalidFields: string[] = []
    for (const [key, value] of Object.entries(formData) as [
      keyof TIndicatorForm,
      any
    ][]) {
      if (mandatoryItens.includes(key) && (value === null || value === '')) {
        invalidFields.push(key)
      }
    }

    if (invalidFields.length > 0) {
      const allTrueTouchedFields = Object.fromEntries(
        Object.keys(touchedFields).map(key => [key, true])
      )
      setTouchedFields(allTrueTouchedFields)
      notification.error('Atenção', 'Campos obrigatórios não informados')
      return false
    }
    return true
  }

  const prepareToSave = async (form: TScoreRangeForm) => {
    const newObject: any = Object.assign({}, form)
    return newObject
  }

  const [touchedFields, setTouchedFields] = useState<any>({
    name: false,
    description: false
  })

  const handlerCheckbox = (name: string, isChecked: boolean) => {
    setFormData(prevData => ({ ...prevData, [name]: isChecked }))
  }

  const handleInputChangeDefault = (event: ChangeEvent<any>) => {
    const { name, value } = event.target
    setFormData(prevData => ({ ...prevData, [name]: value }))
  }

  const getFieldValidation = (field: keyof TScoreRangeForm) => {
    return (
      touchedFields[field] &&
      (formData[field] === null || formData[field] === '')
    )
  }

  const handleBlur = (field: keyof TScoreRangeForm) => {
    setTouchedFields({ ...touchedFields, [field]: true })
  }

  const handleSave = async () => {
    if (!validation()) return
    try {
      const preparedForm = await prepareToSave(formData)
      setLoading(true)
      const result = !id
        ? await scoreRangeService.createScoreRange(preparedForm)
        : await scoreRangeService.updateScoreRange(preparedForm)

      if (result) {
        notification.success('Sucesso', result.message)
        goBackPage()
        setLoading(false)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handlerLoadRequestData = useCallback((result: any) => {
    setFormData(prevData => ({
      ...prevData,
      id: result.id,
      name: result.name,
      minimumScore: result.minimumScore,
      maximumScore: result.maximumScore,
      active: result.active
    }))
  }, [])

  const handlerGetScoreRange = useCallback(
    async (id: number): Promise<void> => {
      try {
        setLoading(true)
        const result = await scoreRangeService.getScoreRangeById(id)
        handlerLoadRequestData(result)
      } catch {
      } finally {
        setLoading(false)
      }
    },
    [handlerLoadRequestData]
  )

  useEffect(() => {
    id && handlerGetScoreRange(Number(id))
  }, [handlerGetScoreRange, id])

  return (
    <ScoreRangeRecordStyle.Container className="w-100">
      <BradCrumbs
        names={breadCrumps}
        paths={['/indicators']}
        disabled={false}
        showArrow={true}
        backGroundColor={location.state}
      />
      <CustomSpinner state={location.state} show={isloading} />
      <ScoreRangeRecordStyle.Title>
        {!id ? 'Nova Faixa de Pontuação' : 'Edição de Faixa de Pontuação'}
      </ScoreRangeRecordStyle.Title>
      <ScoreRangeRecordStyle.Content>
        <ScoreRangeRecordStyle.Form>
          <div className="w-100 content-container p-3 pt-0 overflow-auto">
            <div>
              <div className="w-100 ">
                <div>
                  <div className="col  d-flex mt-3">
                    <div className="col-3 d-flex flex-column">
                      <FormStyle.Label>Nome do Indicador</FormStyle.Label>
                      <FormStyle.Field
                        name="name"
                        value={formData.name}
                        onChange={handleInputChangeDefault}
                        style={{
                          border: getFieldValidation('name')
                            ? 'solid red 1px'
                            : ''
                        }}
                        onBlur={() => handleBlur('name')}
                      />
                    </div>

                    <div className="col-2 d-flex ms-3 flex-column">
                      <FormStyle.Label>Mínimo</FormStyle.Label>
                      <FormStyle.Field
                        name="minimumScore"
                        value={formData.minimumScore}
                        onChange={handleInputChangeDefault}
                        type="number"
                        style={{
                          border: getFieldValidation('minimumScore')
                            ? 'solid red 1px'
                            : ''
                        }}
                        onBlur={() => handleBlur('minimumScore')}
                      />
                    </div>
                    <div className="col-2 d-flex ms-3 flex-column">
                      <FormStyle.Label>Máximo</FormStyle.Label>
                      <FormStyle.Field
                        name="maximumScore"
                        type="number"
                        value={formData.maximumScore}
                        onChange={handleInputChangeDefault}
                        style={{
                          border: getFieldValidation('maximumScore')
                            ? 'solid red 1px'
                            : ''
                        }}
                        onBlur={() => handleBlur('maximumScore')}
                      />
                    </div>
                    <div className="col-1 d-flex flex-column  ">
                      <FormControlLabel
                        style={{ marginTop: '19px' }}
                        className="ms-3 "
                        control={
                          <Checkbox
                            style={{ color: location.state }}
                            checked={formData.active}
                            onChange={event =>
                              handlerCheckbox('active', event.target.checked)
                            }
                          />
                        }
                        label={
                          <span
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            Ativo
                          </span>
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex w-100 next-form justify-content-end p-3 mt-3">
                    <span
                      className="me-3"
                      onClick={goBackPage}
                      style={{
                        backgroundColor: location.state,
                        borderColor: location.state
                      }}
                    >
                      Cancelar
                    </span>
                    <div className="form-name">
                      <button
                        className="btn"
                        type="button"
                        onClick={handleSave}
                        style={{
                          backgroundColor: location.state,
                          color: 'white'
                        }}
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScoreRangeRecordStyle.Form>
      </ScoreRangeRecordStyle.Content>
    </ScoreRangeRecordStyle.Container>
  )
}

export default ScoreRangeRecord
