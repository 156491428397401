export enum EIntershipStepsEnum {
  FISTSTEP = 1,
  SECONDSTEP = 2,
  THIRDSTEP = 3,
  FOURTHSTEP = 4
}

export enum EIntershipStatusEnum {
  INSCRIPTION = 1,
  INTERVIEW_ISBET = 2,
  INTERVIEW_COMPANY = 3,
  FINISHED = 4,
  HIRING = 5,
  CANCELED = 10
}

export enum EOverallClassificationEnum {
  A = 1,
  B = 2,
  C = 3,
  D = 4,
  E = 5
}

export enum EWeekDayEnum {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
  FIXED = 8
}
