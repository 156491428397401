import styled from 'styled-components'

export namespace CandidateStyle {
  interface Theme {
    backgroundColor: string
  }

  export const Container = styled.div`
    height: 95dvh !important;
  `
  export const HeaderContent = styled.div`
    display: flex;
    align-items: center;
  `

  export const NewOportunityBtn = styled.button<Theme>`
    width: 300px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid;
    background: ${({ theme }) => theme.backgroundColor || '#ffffff'};
    &:hover {
      color: #fff;
      background: ${({ backgroundColor }) => backgroundColor};
    }
  `
  export const PageTitle = styled.h2`
    font-size: 30px;
    margin-top: 2px;
    font-weight: 500;
    min-width: 150px;
  `

  export const SelectedFacility = styled.h5`
    white-space: nowrap;
    margin-top: 4px;
    margin-right: 15px;
  `
  export const Content = styled.div`
    height: 92%;
    background: #fff;
  `
  export const FilterContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
  `
  export const FilterBtn = styled.div<Theme>`
    border-radius: 5px;
    padding: 7px 8px;
    border: 1px solid gray;
    &:hover {
      color: #fff;
      background: ${({ backgroundColor }) => backgroundColor};
      border-color: ${({ backgroundColor }) => backgroundColor};
    }
  `
}
