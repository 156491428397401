import React from 'react'
import BradCrumbs from '../../shared/components/common/breadcrumbs'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container } from './style'
import image from '../../assets/images/pagina_em_construcao.jpg'

const BuidingPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const goBackLastRoute = () => {
    navigate(-1)
  }

  return (
    <Container className="w-100">
      <BradCrumbs
        names={['404']}
        paths={[]}
        disabled={false}
        showArrow={false}
        backGroundColor={location.state}
      />
      <div className="d-flex align-items-start justify-content-center mt-5 h-100">
        <div className="d-flex flex-column mt-5 align-items-center">
          <img src={image} alt="" />
          <button
            className="btn btn-outline-danger mt-3 col-2"
            onClick={goBackLastRoute}
          >
            Voltar
          </button>
        </div>
      </div>
    </Container>
  )
}

export default BuidingPage
