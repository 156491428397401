import styled from 'styled-components'

export const Container = styled.div`
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  height: 75px;
  span {
    color: #151515;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 41px;
    display: flex;
    align-items: center;
    line-height: normal;
    svg {
      transform: rotateY(180deg);
    }
  }
`

export const RightConner = styled.div`
  width: 300px;
  height: 75px;
  position: absolute;
  right: 0;
`
