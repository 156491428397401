import { FormControlLabel } from '@mui/material'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, { ChangeEvent, useState } from 'react'
import { IToggleComponentProps } from '../../../models/toggle.model'
import { ToggleStyle } from './style'

const ToggleComponent: React.FC<IToggleComponentProps> = ({
  value,
  onChange,
  ...switchProps
}) => {
  const [checked, setChecked] = useState(value || false)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    onChange && onChange(event.target.checked)
  }

  return (
    <FormControlLabel
      className="m-0"
      control={
        <ToggleStyle.SwitchToggle
          checked={checked}
          onChange={handleChange}
          {...switchProps}
        />
      }
      label=""
    />
  )
}

export default ToggleComponent
