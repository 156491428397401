import React, { ChangeEvent, useEffect, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import { IoMdAddCircleOutline } from 'react-icons/io'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import BradCrumbs from '../../shared/components/common/breadcrumbs'
import CustomModal from '../../shared/components/common/custom-modal'
import CustomSpinner from '../../shared/components/common/custom-spinner'
import {
  TIndicatorTableBody,
  TIndicatorRequestPagination
} from '../../shared/models'
import { notification } from '../../shared/services'
import { FormStyle } from '../../shared/styles/form.style'
import { indicatorService } from '../../shared/services/indicators'
import { IndicatorTable } from './components/indicator-table'
import { IndicatorStyle } from './style'

const IndicatorsPage: React.FC = () => {
  let debounceTimer: any
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = useState<string>('')
  const [isloading, setLoading] = useState<boolean>(false)
  const [selectedIndicatorId, setSelectedIndicatorId] = useState<number | null>(
    null
  )
  const [showConfirmActivationModal, setShowConfirmActivationModal] =
    useState(false)

  const [pagination, setPagination] = useState<TIndicatorRequestPagination>({
    searchTerm: '',
    page: 1,
    pageSize: 12,
    isAsc: true,
    orderCollumnName: ''
  })
  const [indicators, setIndicators] = useState<TIndicatorTableBody[]>([])

  const tableInfo = {
    options: {
      striped: true,
      sortable: true,
      hover: true,
      responsive: true,
      size: 'sm',
      showEdit: true,
      showDelete: false,
      skipItems: ['id'],
      clickable: [],
      backgroundColor: location.state || '#004699'
    },

    headers: [
      {
        id: 1,
        label: 'Id',
        sortable: true,
        isSort: true,
        originalName: 'id'
      },
      {
        id: 2,
        label: 'Nome',
        sortable: true,
        isSort: true,
        originalName: 'name'
      },
      {
        id: 3,
        label: 'Descrição',
        sortable: true,
        isSort: false,
        originalName: 'description'
      },
      {
        id: 4,
        label: 'Status',
        sortable: true,
        isSort: false,
        originalName: 'active'
      },
      {
        id: 9,
        label: 'Ações',
        sortable: false,
        isSort: false
      }
    ]
  }

  const handlerSetSort = (value: any) => {
    setPagination(prevData => ({
      ...prevData,
      orderCollumnName: value
        ? value.originalName.charAt(0).toUpperCase() +
          value.originalName.slice(1)
        : 'Id',
      isAsc: value ? value.isSort : true,
      page: 1
    }))
  }

  const handlerScroll = (event: any) => {
    setPagination(prevData => ({
      ...prevData,
      page: (prevData.page += 1)
    }))
  }

  const handleEdit = (value: any) => {
    navigate(`/indicators/indicators-record/${value.id}`)
  }

  const handleCloseConfirmActivationModal = () => {
    setShowConfirmActivationModal(false)
    setSelectedIndicatorId(null)
  }

  const handlerConfirmEditModal = (event: any) => {
    setShowConfirmActivationModal(true)
    setSelectedIndicatorId(event.id)
  }

  const handlerConfirmActivation = async () => {
    try {
      if (selectedIndicatorId) {
        const indicator = indicators.find(
          (i: TIndicatorTableBody) => i.id === selectedIndicatorId
        )
        if (!indicator) return
        setLoading(true)
        const result = await indicatorService.activateIndicator({
          indicatorId: selectedIndicatorId,
          active: !indicator.active
        })
        notification.success('Sucesso.', result.message)
        handleCloseConfirmActivationModal()
        handlerLoadIndicators()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleInputSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || ''
    setSearch(value)
    if (value.length === 0 || value.length > 2) debounceSearch(value)
  }

  const debounceSearch = (value: string) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      setPagination(prevData => ({
        ...prevData,
        searchTerm: value
      }))
    }, 500)
  }

  const handlerLoadIndicators = async (): Promise<void> => {
    try {
      setLoading(true)
      const indicators = await indicatorService.getAllIndicator(pagination)
      if (pagination.page > 1) {
        setIndicators(prevIndicators => [...prevIndicators, ...indicators])
      } else {
        setIndicators(indicators)
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const getIndicatorState = () => {
    const indicator = indicators.find(
      (i: TIndicatorTableBody) => i.id === selectedIndicatorId
    )
    return indicator!.active ? 'Inativar' : 'Ativar'
  }

  useEffect(() => {
    handlerLoadIndicators()
  }, [pagination])

  return (
    <IndicatorStyle.Container className="w-100">
      <BradCrumbs
        names={['Indicadores']}
        paths={[]}
        disabled={false}
        showArrow={false}
        backGroundColor={location.state || '#004699'}
      />
      <div style={{ paddingLeft: '2.6%', paddingRight: '2.6%' }}>
        <IndicatorStyle.HeaderContent className="row mt-4">
          <IndicatorStyle.PageTitle className="col-1 ">
            Indicadores
          </IndicatorStyle.PageTitle>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col"></div>
          <div className="col">
            <IndicatorStyle.NewOportunityBtn
              backgroundColor={location.state || '#004699'}
              className="px-3"
            >
              <Link
                className="dropdown-item"
                to="/indicators/indicators-record"
                state={location.state || '#004699'}
              >
                <IoMdAddCircleOutline size={25} className="me-2" /> Cadastrar
                Novo Indicador
              </Link>
            </IndicatorStyle.NewOportunityBtn>
          </div>
        </IndicatorStyle.HeaderContent>
        {showConfirmActivationModal && (
          <CustomModal
            show={showConfirmActivationModal}
            onClose={handleCloseConfirmActivationModal}
            onSave={handlerConfirmActivation}
            title="Confirmação"
            icon={<HiOutlineTrash size={20} />}
            state={location.state || '#004699'}
            onlyCloseBtn={false}
            buttonTitle={'Continuar'}
          >
            <h5>Tem certeza que deseja {getIndicatorState()}?</h5>
          </CustomModal>
        )}
        <IndicatorStyle.FilterContent className="mt-3 ">
          <div className="w-100 " style={{ position: 'relative' }}>
            <FormStyle.SearchIcon />
            <FormStyle.SearchInput
              type="search"
              placeholder="Busca"
              name="search"
              value={search}
              onChange={handleInputSearchChange}
            />
          </div>
        </IndicatorStyle.FilterContent>
        <CustomSpinner state={location.state || '#004699'} show={isloading} />
        <div
          className="mt-3 w-100"
          style={{
            overflowX: 'hidden',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            borderTop: '1px solid #ccc'
          }}
        >
          <IndicatorTable
            headers={tableInfo.headers}
            body={indicators}
            options={tableInfo.options}
            sortHeader={handlerSetSort}
            scroll={handlerScroll}
            editVacancy={handleEdit}
            activation={handlerConfirmEditModal}
          ></IndicatorTable>
        </div>
      </div>
    </IndicatorStyle.Container>
  )
}

export default IndicatorsPage
