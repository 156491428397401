import React, { ChangeEvent, useState } from 'react'
import {
  Container,
  FormLogo,
  CustomForm,
  Logo,
  MainContent,
  Subtitle,
  Title,
  StyledLink
} from './style'
import logo from '../../../assets/images/logo.png'
import formLogo from '../../../assets/images/password.png'
import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { FadeLoader } from 'react-spinners'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import { TUserNewPassord } from '../../../core/models'
import { IoIosArrowBack } from 'react-icons/io'
import { authService } from '../../../core/services'
import { notification } from '../../../shared/services'
import {
  LeftBottomConner,
  RightTopConner
} from '../../../shared/components/common'
const NewPasswordPage: React.FC = () => {
  const { id, token } = useParams()
  const navigate = useNavigate()
  const [isloading, setLoading] = useState(false)
  const [showPassword, setPassword] = useState(false)
  const [user, setUser] = useState<TUserNewPassord>({
    password: '',
    confirmPassword: '',
    token: '',
    id: ''
  })

  const handleTogglePassword = () => {
    setPassword(!showPassword)
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setUser({ ...user, [event.target.name]: event.target.value })
  }

  const validate = () => {
    if (!user.password || !user.confirmPassword) {
      notification.error(
        'Dados inválidos',
        'por favor informe sua senha e confirme senha'
      )
      return false
    }

    if (user.password !== user.confirmPassword) {
      notification.error('Dados inválidos', 'Senhas informadas são diferentes')
      return false
    }

    if (user.password.length < 8) {
      notification.error(
        'Dados inválidos',
        'Sua senha precisa ter pelo menos 8 dígitos'
      )
      return false
    }

    if (id && token) {
      user.id = id
      user.token = token
    }
    return true
  }

  const resetPassword = async (): Promise<void> => {
    if (!validate()) return
    try {
      setLoading(true)
      const result: any = await authService.resetPassword({
        id: user.id,
        token: user.token,
        password: user.password
      })
      notification.success('Sucesso!', result.message)
      navigate('/login')
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  return (
    <Container>
      <Logo src={logo} />
      <RightTopConner style={{ backgroundColor: '#9E071E' }} />
      <MainContent className="p-0 p-md-4">
        <CustomForm className="w-100 w-md-50 me-0 me-md-4">
          {isloading ? (
            <div className="d-flex align-items-center justify-content-center mt-4'">
              <FadeLoader color="#bc0823" loading={isloading} />
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center">
                <IoIosArrowBack className="me-2" size={20} />
                <StyledLink to="/login">Voltar para Login</StyledLink>
              </div>
              <Title className="mt-2">Cadastro de senha</Title>
              <Subtitle>Sua senha precisa ter pelo menos 8 dígitos</Subtitle>
              <TextField
                className="mt-4"
                color="error"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="Senha"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={user.password}
                onChange={handleChange}
              />
              <TextField
                className="mt-4"
                color="error"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword}>
                        {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                label="Confirme sua senha"
                name="confirmPassword"
                type={showPassword ? 'text' : 'password'}
                value={user.confirmPassword}
                onChange={handleChange}
              />

              <Button
                variant="contained"
                type="button"
                color="error"
                className="mt-4 w-100"
                onClick={resetPassword}
              >
                Cadastrar
              </Button>
            </>
          )}
        </CustomForm>
        <FormLogo className="d-none d-md-block" src={formLogo} />
      </MainContent>
      <LeftBottomConner style={{ backgroundColor: '#bc0823' }} />
    </Container>
  )
}

export default NewPasswordPage
