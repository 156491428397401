import { TOpportunityForm } from '../../../core/models'
import http from '../../../core/services/api/request'
const basePath = 'scorerange/'

export const scoreRangeService = {
  async getAllScoreRange(pagination: any): Promise<any> {
    let url = `${basePath}getAllScoreRanger`
    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getScoreRangeById(scoreRangeId: number): Promise<any> {
    return await http.get(
      `${basePath}getScoreRangerById?scoreRangeId=${scoreRangeId}`
    )
  },

  async activateScoreRanger(data: {
    scoreRangeId: number
    active: boolean
  }): Promise<any> {
    return await http.put(`${basePath}activation`, data)
  },

  async createScoreRange(data: TOpportunityForm): Promise<any> {
    return await http.post(`${basePath}createScoreRanger`, data)
  },

  async updateScoreRange(data: TOpportunityForm): Promise<any> {
    return await http.put(`${basePath}updateScoreRanger`, data)
  }
}
