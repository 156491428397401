import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import './style.css'
const CustomModal = ({
  show,
  onClose,
  onSave,
  onClear,
  title,
  icon,
  children,
  state,
  onlyCloseBtn,
  buttonTitle
}: any) => {
  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        {icon && (
          <span
            className="icon me-2"
            style={{
              border: '1px solid #c1c1c1',
              padding: '5px 7px',
              borderRadius: '5px'
            }}
          >
            {icon}
          </span>
        )}
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        {/* <Button
          onClick={onClear}
          className="custom-button-save"
          style={{ backgroundColor: state }}
        >
          Limpar Filtros
        </Button> */}
        {onlyCloseBtn === false ? (
          <>
            <Button
              variant="outline-secondary"
              onClick={onClose}
              className="custom-button-save"
            >
              Cancelar
            </Button>
            <Button
              onClick={onSave}
              className="custom-button-save"
              style={{ backgroundColor: state }}
            >
              {buttonTitle}
            </Button>
          </>
        ) : (
          <Button
            onClick={onClose}
            className="custom-button-save"
            style={{ backgroundColor: state }}
          >
            Fechar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default CustomModal
