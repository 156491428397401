import styled from 'styled-components'
import FullCalendar from '@fullcalendar/react'

export namespace CalendarStyle {
  interface Theme {
    backgroundColor: string
  }

  export const Container = styled.div`
    height: 95dvh !important;
  `

  export const SelectedFacility = styled.h5`
    white-space: nowrap;
    margin-top: 4px;
    margin-right: 15px;
  `

  export const SelectedInterviewer = styled.h5`
    white-space: nowrap;
    margin-top: 4px;
    margin-right: 15px;
  `
  export const CalendarContainer = styled.div``
  export const CalendarArea = styled.div`
    width: 100%;
    background-color: white;
    border-radius: 14px;
    box-shadow: 10px 15px 40px #3b4b761f;
    padding: 25px;

    .fc-toolbar-chunk {
      display: flex;
    }

    .fc-prev-button {
      color: black;
      padding: 0px 7px;
      margin-right: 10px;
      padding-left: 4px;
      padding-right: 5px;
      background: white !important;
      transition: 0.25s;
      box-shadow: none !important;
      &:hover {
        background: rgb(188, 8, 35) !important;
        border: 1px solid rgb(188, 8, 35);
        color: white !important;
      }
    }

    .fc-dayGridMonth-button,
    .fc-timeGridWeek-button {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 0px;
      padding-bottom: 0px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      height: 30px;
      color: black !important;
      font-weight: 600;
      border: none !important;
      box-shadow: none;
      background: #f0f0f2 !important;
      box-shadow: none !important;
      transition: 0.25 !important;
    }

    .fc-customButton-button {
      background: black !important;
      color: white !important;
      border: none !important;
      box-shadow: none !important;
      padding: 3px 20px !important;
      transition: 0.25s;
      &:hover {
        background: white !important;
        border: 1px solid black !important;
        box-shadow: 10px 15px 40px #3b4b761f !important;
        color: black !important;
      }
    }

    .fc-next-button {
      color: black;
      border: 1px solid #21252965;
      padding: 0px 7px;
      margin-right: 10px;
      padding-left: 4px;
      padding-right: 5px;
      background: white !important;
      transition: 0.25s;
      box-shadow: none !important;
      &:hover {
        background: rgb(188, 8, 35) !important;
        border: 1px solid rgb(188, 8, 35);
        color: white !important;
      }
    }

    .fc-scrollgrid {
      border: none !important;
    }

    .fc-scrollgrid-sync-inner {
      text-align: end;
    }
    .fc-toolbar-title {
      text-transform: capitalize;
    }

    a {
      color: gray;
      cursor: pointer;
      font-weight: 500;
      text-transform: capitalize;
      text-decoration: none;
    }

    .fc-col-header-cell {
      border: none !important;
    }
  `
  export const StyledFullCalendar = styled(FullCalendar)`
    /* .fc-daygrid-day {
    border: gray;
  } */
  `
}
