import styled from 'styled-components'

export namespace FilterModalStyle {
  interface Theme {
    backgroundColor: string
  }

  export const Container = styled.div`
    height: 100% !important;
  `
  export const Label = styled.span`
    font-size: 16px;
    font-weight: 600;
  `
  export const StatusButton = styled('button')(({ theme, status }: any) => ({
    height: 30,
    backgroundColor: status ? theme.palette.primary.main : 'white',
    color: status ? 'white' : 'black',
    border: '1px solid gray',
    borderRadius: '25px',
    padding: '0 12px',
    fontSize: '13px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: status ? theme.palette.primary.dark : '#f0f0f0'
    }
  }))
}
