import http from '../../../core/services/api/request'
import { EIntershipStatusEnum } from '../../enums'
import {
  ECandidatesStatusEnum,
  EHowDidYouHearAboutISBETEnum
} from '../../enums/candidate'
import {
  TAddressResponse,
  TCandidateStatus,
  TKnowledgeItem,
  TLatLongResponse,
  TNameValueOption,
  TNationality,
  TSpecificSkills,
  TStatus,
  TValueOption
} from '../../models'
const basePath: string = 'common'

export const commonService = {
  async getStatus(): Promise<TStatus[]> {
    return [
      {
        id: EIntershipStatusEnum.INSCRIPTION,
        label: 'Inscrição',
        status: false
      },
      {
        id: EIntershipStatusEnum.INTERVIEW_ISBET,
        label: 'Entrevista ISBET',
        status: false
      },
      {
        id: EIntershipStatusEnum.INTERVIEW_COMPANY,
        label: 'Entrevista Empresa',
        status: false
      },
      { id: EIntershipStatusEnum.FINISHED, label: 'Finalizado', status: false },
      { id: EIntershipStatusEnum.HIRING, label: 'Contratação', status: false },
      { id: EIntershipStatusEnum.CANCELED, label: 'Cancelado', status: false }
    ]
  },

  async getCandidateStatus(): Promise<TCandidateStatus[]> {
    return [
      {
        id: ECandidatesStatusEnum.SYSTEM,
        label: 'Sistema',
        value: 'S',
        status: true
      },
      {
        id: ECandidatesStatusEnum.PORTAL,
        label: 'Portal',
        value: 'P',
        status: true
      }
    ]
  },

  async getAddressByZipCode(zipcode: string) {
    return await http.get<unknown, TAddressResponse>(
      `https://viacep.com.br/ws/${zipcode}/json/`
    )
  },

  async getCordinatesByAddress(address: string) {
    return await http.get<unknown, TLatLongResponse>(
      `/common/getCordinatesByAddress?address=${address}`
    )
  },

  async getLatLngFromAddress(address: string) {
    return await http.post<unknown, TLatLongResponse>(
      `${basePath}/getLatLngFromAddress`,
      address
    )
  },
  async getFacilitiesFilters() {
    return await http.get<unknown, any>(`/facility/getAllFacilitiesFilter`)
  },

  async getInterviwersFilters() {
    return [
      { id: 0, name: 'Todos' },
      { id: 1, name: 'Alexandre Rosa' },
      { id: 2, name: 'Marcio Motoshima' }
    ]
    // return await http.get<unknown, any>(`/facility/getAllFacilitiesFilter`)
  },

  async getCompaniesFilters(pagination: any) {
    let url = '/company/getAllCompaniesFilter'

    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getCompanyOverdueStatus(companyId: number, facilityId: number) {
    return await http.get<unknown, any>(
      `/company/getCompanyOverdueStatus?companyId=${companyId}&facilityId=${facilityId}`
    )
  },

  async getCompaniesByFacilityId(facilityId: number) {
    return await http.get<unknown, any>(
      `/facility/getCompaniesByFacilityId?facilityId=${facilityId}`
    )
  },

  async getOpportunityAreas() {
    return await http.get<unknown, any>(`/opportunityArea/getAllOportunityArea`)
  },

  async getIndicatorsFilter(search: string = '') {
    return await http.get<unknown, any>(
      `/indicator/GetAllIndicatorsFilter?searchTerm=${search}`
    )
  },

  async getScoreRanges(search: string = '') {
    return await http.get<unknown, any>(
      `/scorerange/GetAllScoreRangerFilter?searchTerm=${search}`
    )
  },

  async getAllCourses() {
    return await http.get<unknown, any>(`/course/getAllCourses`)
  },

  async getFormationCoursesByLevel(level: number) {
    return await http.get<unknown, any>(
      `/courseFormation/getFormationCoursesByLevel?level=${level}`
    )
  },
  // async getEducationalInstitutionByLevel(level: number) {
  //   return await http.get<unknown, any>(
  //     `/educationalInstitution/getEducationalInstitutionByLevel?level=${level}`
  //   )
  // },

  async getEducationalInstitutionByLevel(pagination: any) {
    let url = '/educationalInstitution/getEducationalInstitutionByLevel'

    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getAllKnowledgeItems(): Promise<TKnowledgeItem[]> {
    return await http.get(`/knowledgeItem/getAllKnowledgeItems`)
  },

  async getNationalities(): Promise<TNationality[]> {
    return [
      { id: 1, name: 'AFEGÃO' },
      { id: 2, name: 'ALEMÃO' },
      { id: 3, name: 'AMERICANO' },
      { id: 4, name: 'ANGOLANO' },
      { id: 5, name: 'ARGENTINO' },
      { id: 6, name: 'AUSTRALIANO' },
      { id: 7, name: 'BELGA' },
      { id: 8, name: 'BOLIVIANO' },
      { id: 9, name: 'BRASILEIRO' },
      { id: 10, name: 'BRITÂNICO' },
      { id: 11, name: 'CANADENSE' },
      { id: 12, name: 'CHILENO' },
      { id: 13, name: 'CHINÊS' },
      { id: 14, name: 'COLOMBIANO' },
      { id: 15, name: 'CUBANO' },
      { id: 16, name: 'DINAMARQUÊS' },
      { id: 17, name: 'EGÍPCIO' },
      { id: 18, name: 'EQUATORIANO' },
      { id: 19, name: 'ESPANHOL' },
      { id: 20, name: 'FILIPINO' },
      { id: 21, name: 'FRANCÊS' },
      { id: 22, name: 'GREGO' },
      { id: 23, name: 'HAITIANO' },
      { id: 24, name: 'HOLANDÊS' },
      { id: 25, name: 'HONDURENHO' },
      { id: 26, name: 'HÚNGARO' },
      { id: 27, name: 'INDIANO' },
      { id: 28, name: 'INDONÉSIO' },
      { id: 29, name: 'IRAQUIANO' },
      { id: 30, name: 'IRANIANO' },
      { id: 31, name: 'IRLANDÊS' },
      { id: 32, name: 'ITALIANO' },
      { id: 33, name: 'JAMAICANO' },
      { id: 34, name: 'JAPONÊS' },
      { id: 35, name: 'LIBANÊS' },
      { id: 36, name: 'MALAIO' },
      { id: 37, name: 'MARROQUINO' },
      { id: 38, name: 'MEXICANO' },
      { id: 39, name: 'MOÇAMBICANO' },
      { id: 40, name: 'NIGERIANO' },
      { id: 41, name: 'NORUEGUÊS' },
      { id: 42, name: 'PAQUISTANÊS' },
      { id: 43, name: 'PARAGUAIO' },
      { id: 44, name: 'PERUANO' },
      { id: 45, name: 'POLONÊS' },
      { id: 46, name: 'PORTUGUÊS' },
      { id: 47, name: 'RUSSO' },
      { id: 48, name: 'SUL-AFRICANO' },
      { id: 49, name: 'SUECO' },
      { id: 50, name: 'SUÍÇO' },
      { id: 51, name: 'TAILANDÊS' },
      { id: 52, name: 'TURCO' },
      { id: 53, name: 'UCRANIANO' },
      { id: 54, name: 'URUGUAIO' },
      { id: 55, name: 'VENEZUELANO' },
      { id: 56, name: 'VIETNAMITA' },
      { id: 57, name: 'ZIMBABUANO' }
    ]
  },

  async getGenders(): Promise<TNameValueOption[]> {
    return [
      { name: 'MASCULINO', value: 'M' },
      { name: 'FEMINO', value: 'F' }
    ]
  },

  async getSexualOrientations(): Promise<TValueOption[]> {
    return [
      { value: 'MULHER CISGÊNERO' },
      { value: 'HOMEM CISGÊNERO' },
      { value: 'MULHER TRANSGÊNERO' },
      { value: 'HOMEM TRANSGÊNERO' },
      { value: 'OUTROS' }
    ]
  },

  async getMaritalStatuses(): Promise<TNameValueOption[]> {
    return [
      { name: 'SOLTEIRO', value: 'S' },
      { name: 'CASADO', value: 'C' },
      { name: 'DIVORCIADO', value: 'D' },
      { name: 'SEPARADO', value: 'P' }
    ]
  },

  async getLegalRelationShip(): Promise<TValueOption[]> {
    return [
      { value: 'PAI' },
      { value: 'MÃE' },
      { value: 'AVÔ(Ó)' },
      { value: 'NETO(A)' },
      { value: 'IRMÃO(Ã)' },
      { value: 'TIO(A)' },
      { value: 'SOBRINHO(A)' },
      { value: 'PRIMO(A)' },
      { value: 'CUNHADO(A)' },
      { value: 'SOGRO(A)' },
      { value: 'GENRO' },
      { value: 'NORA' },
      { value: 'PADRASTO' },
      { value: 'MADRASTA' },
      { value: 'ENTEADO(A)' },
      { value: 'TUTOR(A)' },
      { value: 'ASSISTÊNCIA SOCIAL' }
    ]
  },

  async getHowDidYouHearAboutISBETs(): Promise<any[]> {
    return [
      {
        id: EHowDidYouHearAboutISBETEnum.CRAS,
        value: 'ENCAMINHADO PELO CRAS'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.CREAS,
        value: 'ENCAMINHADO PELO CREAS'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.FRIEND,
        value: 'INDICAÇÃO DE UM AMIGO'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.SOCIALMIDIA,
        value: 'REDES SOCIAIS'
      },
      {
        id: EHowDidYouHearAboutISBETEnum.OTHERS,
        value: 'OUTROS'
      }
    ]
  },

  async getSpecificSkills(pagination: any) {
    let url = '/common/GetSpecificSkills'

    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getSocialMedias(): Promise<any[]> {
    return await http.get(`/common/GetSocialMedias`)
  }
}
