import { FilterModalStyle } from './style'
import { FormStyle } from '../../../../shared/styles/form.style'
import CalendarIcon from '../../../../assets/svgs/calendar.svg'
import { useEffect, useState } from 'react'
import { commonService } from '../../../../shared/services'
import { TModalFilter, TStatus } from '../../../../shared/models'
import dayjs from 'dayjs'

interface FilterModalProps {
  state: string
  outPutData: (data: TModalFilter) => void
  pulse: boolean
}

const FilterModal: React.FC<FilterModalProps> = ({
  state,
  outPutData,
  pulse
}) => {
  const [statuses, setStatuses] = useState<TStatus[]>([])
  const [formData, setFormData] = useState<TModalFilter>({
    startDate: null,
    endDate: null,
    filters: []
  })

  useEffect(() => {
    const fetchStatuses = async () => {
      try {
        const statuses = await commonService.getStatus()
        setStatuses(statuses)
      } catch (error) {
        console.error('Error fetching statuses:', error)
      }
    }
    fetchStatuses()
  }, [])

  useEffect(() => {
    if (pulse) {
      outPutData(formData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pulse])

  function calendarIcon() {
    return (
      <img
        className="me-1"
        src={CalendarIcon}
        style={{ width: 20 }}
        alt="Date picker opening icon"
        width={32}
      />
    )
  }

  const handleStatusChange = (index: number) => {
    const updatedStatuses = [...statuses]
    updatedStatuses[index] = {
      ...updatedStatuses[index],
      status: !updatedStatuses[index].status
    }
    setStatuses(updatedStatuses)

    const selectedFilters = updatedStatuses
      .filter(status => status.status)
      .map(status => status.id)
    setFormData({ ...formData, filters: selectedFilters })
  }

  return (
    <FilterModalStyle.Container>
      <FilterModalStyle.Label>Por Período</FilterModalStyle.Label>
      <div className="d-flex justify-content-between mb-4">
        <div className="d-flex flex-column mt-3">
          <FormStyle.Label>De</FormStyle.Label>
          <FormStyle.DatePickerStyle
            slots={{ openPickerIcon: calendarIcon }}
            sx={{ width: 220 }}
            format="DD/MM/YYYY"
            onChange={date =>
              setFormData({
                ...formData,
                startDate: dayjs(date as string).format('YYYY-MM-DD')
              })
            }
            value={formData.startDate}
          />
        </div>
        <div className="d-flex flex-column mt-3">
          <FormStyle.Label>Até</FormStyle.Label>
          <FormStyle.DatePickerStyle
            slots={{ openPickerIcon: calendarIcon }}
            sx={{ width: 220 }}
            format="DD/MM/YYYY"
            onChange={date =>
              setFormData({
                ...formData,
                endDate: dayjs(date as string).format('YYYY-MM-DD')
              })
            }
            value={formData.endDate}
          />
        </div>
      </div>
      <FilterModalStyle.Label>Por Status</FilterModalStyle.Label>
      <div className="d-flex flex-wrap mt-2">
        {statuses.map((status, index) => (
          <button
            key={index}
            className="me-2 mb-2"
            style={{
              height: 30,
              backgroundColor: status.status ? state : 'white',
              border: '1px solid gray',
              color: status.status ? 'white' : 'black',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              borderRadius: '25px',
              paddingLeft: '10px',
              paddingRight: '10px',
              fontSize: '13px'
            }}
            onClick={() => handleStatusChange(index)}
          >
            {status.label}
          </button>
        ))}
      </div>
    </FilterModalStyle.Container>
  )
}

export default FilterModal
