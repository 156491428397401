/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { TMenuItem } from '../../../models'
import { getMenu } from '../../../services'
import { Container, List } from './style'
import './style.css'

const SideMenu = () => {
  const menuItems: TMenuItem[] = getMenu()
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState(location.pathname)
  const [hoveredIndex, setHoveredIndex] = useState(-1)

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location.pathname])

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index)
  }

  const handleMouseLeave = () => {
    setHoveredIndex(-1)
  }

  return (
    <Container>
      <List>
        {menuItems.map((item, index) => {
          const isActive = currentPath.startsWith(item.path)
          const hasSubmenu = item.submenu && item.submenu.length > 0
          return (
            <div
              key={index}
              className={`mb-3 text-decoration-none btn-group dropdown dropend`}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <span
                className={`item ${isActive ? 'active' : ''} ${
                  currentPath === item.path ? 'active' : ''
                }`}
                style={{
                  color:
                    hoveredIndex === index
                      ? item.labelBackGroundColor
                      : undefined,
                  backgroundColor: isActive ? item.labelBackGroundColor : ''
                }}
              >
                {React.createElement(item.icon, { size: 23 })}
                {hasSubmenu && hoveredIndex === index && (
                  <ul className="dropdown-menu">
                    <li
                      style={{
                        backgroundColor: item.labelBackGroundColor
                      }}
                    >
                      <Link
                        className="dropdown-item"
                        style={{
                          backgroundColor:
                            hoveredIndex === index
                              ? item.labelBackGroundColor
                              : undefined,
                          color: '#fff',
                          height: '45px',
                          paddingTop: '10px'
                        }}
                        to={item.path}
                        state={item.labelBackGroundColor}
                      >
                        {item.label}
                      </Link>
                    </li>
                    {item.submenu.map((subitem: any, subindex: number) => (
                      <li key={subindex}>
                        <Link
                          className="dropdown-item"
                          to={subitem.path}
                          state={subitem.labelBackGroundColor}
                        >
                          <span className={item.roverClass}>
                            {subitem.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </span>
            </div>
          )
        })}
      </List>
    </Container>
  )
}

export default SideMenu
