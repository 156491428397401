import { TOpportunityForm } from '../../../core/models'
import { TCandidateForm } from '../../../core/models/candidate.model'
import http from '../../../core/services/api/request'
import { EPeriodsEnum } from '../../enums'
import { EHowDidYouHearAboutISBETEnum } from '../../enums/candidate'
const basePath = 'candidate/'

export const candidateService = {
  educationalLevels: [
    { id: 4, label: 'Ensino Fundamental', value: '4' },
    { id: 1, label: 'Ensino Médio', value: '1' },
    { id: 3, label: 'Ensino Superior', value: '3' },
    { id: 2, label: 'Ensino Técnico', value: '2' }
  ],
  courseTypes: [
    { id: EPeriodsEnum.SEMIANNUAL, label: 'Semestral' },
    { id: EPeriodsEnum.ANNUAL, label: 'Anual' }
  ],
  availabilities: [
    { id: 1, label: 'Manhã', value: 'M' },
    { id: 2, label: 'Tarde', value: 'T' },
    { id: 3, label: 'Noite', value: 'N' },
    { id: 4, label: 'Integral', value: 'I' }
  ],

  getInicialForm(): TCandidateForm {
    return {
      //primeira pagina
      personId: null,
      selectedFacility: null,
      name: '',
      gender: '',
      sexualOrientation: '',
      maritalStatus: '',
      socialName: '',
      birthDate: '',
      legalGuardianName: '',
      legalGuardianEmail: '',
      legalRelationshipDegree: '',
      legalGuardianCellPhone: '',
      documentType: 'C',
      cpfNumber: '',
      rgNumber: '',
      rgIssueDate: '',
      nationality: '',
      allowSpecialNeeds: 'N',
      specialNeedsType: null,
      complementarCourses: [],
      educationInstitution: null,

      //segunda pagina
      email: '',
      phone: '',
      mobile: '',
      emergencyPhone: '',
      zipCode: '',
      city: '',
      streetAddress: '',
      streetNumber: '',
      state: '',
      neighborhood: '',
      complement: '',
      ibgeCode: '',
      latitude: null,
      longitude: null,

      educationLevel: null,
      courseType: EPeriodsEnum.ANNUAL,
      studying: [1],

      //terceira pagina
      voterId: '',
      militarId: '',
      employmentCardDigital: 'S',
      employmentNumber: '',
      employmentSerie: '',
      courseId: null,
      coursesAndSpecializations: [],
      languages: [],

      howDidYouHearAboutISBET: EHowDidYouHearAboutISBETEnum.CRAS,
      selectedSocialMedia: '',
      description: '',
      isGraduated: 'S',
      graduationDate: '',
      period: ''
    }
  },

  async createCandidate(data: TOpportunityForm): Promise<any> {
    return await http.post(`${basePath}createCandidate`, data)
  },

  async getAllCandidate(pagination: any): Promise<any> {
    let url = `${basePath}getAllCandidate`
    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async deleteCandidate(personId: number): Promise<any> {
    return await http.put(`${basePath}deleteCandidate`, { personId })
  },

  async getCandidateById(personId: number): Promise<any> {
    return await http.get(`${basePath}getCandidateById?personId=${personId}`)
  },
  async updateCandidate(data: TOpportunityForm): Promise<any> {
    return await http.put(`${basePath}updateCandidate`, data)
  }
}
