import { TOpportunityForm } from '../../../core/models'
import http from '../../../core/services/api/request'
const basePath = 'indicator/'

export const indicatorService = {
  async createIndicator(data: TOpportunityForm): Promise<any> {
    return await http.post(`${basePath}createIndicator`, data)
  },

  async getAllIndicator(pagination: any): Promise<any> {
    let url = `${basePath}GetAllIndicators`
    if (pagination) {
      const params = new URLSearchParams()

      Object.keys(pagination).forEach(key => {
        params.append(key, pagination[key])
      })

      url += `?${params.toString()}`
    }
    return await http.get<unknown, any>(url)
  },

  async getIndicatorById(indicatorId: number): Promise<any> {
    return await http.get(
      `${basePath}getIndicatorById?indicatorId=${indicatorId}`
    )
  },

  async updateIndicator(data: TOpportunityForm): Promise<any> {
    return await http.put(`${basePath}UpdateIndicator`, data)
  },

  async activateIndicator(data: {
    indicatorId: number
    active: boolean
  }): Promise<any> {
    return await http.put(`${basePath}activation`, data)
  }
}
