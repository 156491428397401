import styled from 'styled-components'

export const Container = styled.div`
  height: 95dvh !important;
`
export const Header = styled.div`
  span {
    color: #151515;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 41px;
    display: flex;
    align-items: center;
    line-height: normal;
    border-radius: 12px;
    border: 1px solid #e6e7ec;
    svg {
      transform: rotateY(180deg);
    }
  }
`
export const Content = styled.div`
  height: 92%;
  background: #fff;
`

export const Grid = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
`

export const Item = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 24px;
  border: 8px solid var(--base-line, #e6e7ec);
  background: #fff;
  transition: 0.25s;
  cursor: pointer;
  img {
    filter: brightness(0) saturate(100%) invert(18%) sepia(37%) saturate(5732%)
      hue-rotate(203deg) brightness(91%) contrast(101%);
  }
  h3 {
    color: var(--text-primary, #0a1b39);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &:hover {
    border: 8px solid var(--base-line, #004699);
  }
`
