import axios from 'axios'
import { authService } from '../authentication'
import { notification } from '../../../shared/services'
import { useNavigate } from 'react-router-dom'

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use((config: any) => {
  const token = authService.getToken()
  if (token && config.url.substring(8, 14) !== 'viacep') {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

http.interceptors.response.use(
  response => response.data,
  error => {
    switch (error.response.status) {
      case 401:
        notification.error(
          'Atenção',
          'Acesso sem autorização, você será redirecionado'
        )
        authService.removeToken()
        const navigate = useNavigate()
        navigate('/')
        break
      case 500:
        notification.error('Atenção', error.response.data.message)
        break
      default:
        if (Array.isArray(error.response.data)) {
          notification.error('Atenção', error.response.data[0].message)
        } else {
          if (error.response.data !== '') {
            notification.error('Atenção', error.response.data.message)
          } else {
            notification.error('Atenção', 'Falha na cominicação com servidor')
          }
        }
        break
    }
    return Promise.reject(error)
  }
)
export default http
