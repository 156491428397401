import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  candidateService,
  commonService,
  notification
} from '../../shared/services'
import CustomSpinner from '../../shared/components/common/custom-spinner'
import { Autocomplete, TextField } from '@mui/material'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { HiOutlineTrash } from 'react-icons/hi'
import { HiOutlineAdjustmentsHorizontal } from 'react-icons/hi2'
import { IoIosArrowDown, IoMdAddCircleOutline } from 'react-icons/io'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import BradCrumbs from '../../shared/components/common/breadcrumbs'
import CustomModal from '../../shared/components/common/custom-modal'
import {
  TFacilitiesFilters,
  TFacility,
  TCandidateModalFilter,
  TCandidateRequestPagination,
  TCandidatesTableBody
} from '../../shared/models'
import { FormStyle } from '../../shared/styles/form.style'
import { CandidateStyle } from './style'
import FilterModal from './components/filter-modal'
import { EIntershipStatusEnum } from '../../shared/enums'
import { CandidatesTable } from './components/candidate-table'
import { ECandidatesStatusEnum } from '../../shared/enums/candidate'
import dayjs from 'dayjs'
import { Helmet } from 'react-helmet'

const CandidatePage: React.FC = () => {
  let debounceTimer: any
  const navigate = useNavigate()
  const location = useLocation()
  const [isloading, setLoading] = useState<boolean>(false)
  const [facilitiesFilters, setFacilitiesFilters] = useState<
    TFacilitiesFilters[]
  >([])
  const [clearFilters, setClearFilters] = useState(false)
  const [currentFacility, setCurrentFacility] = useState<number | null>(null)
  const [selectedCandidateId, setSelectedCandidateId] = useState<number | null>(
    null
  )
  const [search, setSearch] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [showConfirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [sendData, setSendData] = useState(false)
  const [formData, setFormData] = useState<TCandidateModalFilter>({
    startDate: null,
    endDate: null,
    filters: []
  })

  const [pagination, setPagination] = useState<TCandidateRequestPagination>({
    searchTerm: '',
    page: 1,
    pageSize: 12,
    facilityId: null,
    startDate: '',
    endDate: '',
    isAsc: false,
    orderCollumnName: '',
    registrationSourceCode: ''
  })
  const [candidates, setCandidates] = useState<TCandidatesTableBody[]>([])

  const tableInfo = {
    options: {
      striped: true,
      sortable: true,
      hover: true,
      responsive: true,
      size: 'sm',
      showEdit: true,
      showDelete: true,
      skipItems: ['id', 'personId'],
      clickable: [],
      backgroundColor: location.state || '#bc0823'
    },

    headers: [
      {
        id: 1,
        label: 'Id',
        sortable: true,
        isSort: true,
        originalName: 'personId'
      },
      {
        id: 2,
        label: 'Nome',
        sortable: true,
        isSort: true,
        originalName: 'Name'
      },
      {
        id: 3,
        label: 'E-Mail',
        sortable: true,
        isSort: false,
        originalName: 'Email'
      },
      {
        id: 4,
        label: 'CPF/Passaporte',
        sortable: true,
        isSort: false,
        originalName: 'DocumentCode'
      },
      {
        id: 9,
        label: 'Ações',
        sortable: false,
        isSort: false
      }
    ]
  }

  const handlerSetFacility = (value: any) => {
    setCurrentFacility(value.id)
    setPagination(prevData => ({
      ...prevData,
      facilityId: value.id,
      page: 1
    }))
  }

  const handleClearFilters = () => {
    setClearFilters(true)
  }

  const handlerSetSort = (value: any) => {
    setPagination(prevData => ({
      ...prevData,
      orderCollumnName: value ? value.originalName : 'personId',
      isAsc: value ? value.isSort : true,
      page: 1
    }))
  }

  const handlerScroll = (event: any) => {
    setPagination(prevData => ({
      ...prevData,
      page: (prevData.page += 1)
    }))
  }

  const handlerschedulerOrientation = (value: any) => {
    // navigate(`/intership/opportunity-record/${value.jobVacancyId}/true`)
  }

  const handleEdit = (value: any) => {
    navigate(`/candidate/candidate-record/${value.personId}`)
  }

  const handlerDelete = (value: any) => {
    setSelectedCandidateId(value.personId)
    setConfirmDeleteModal(true)
  }

  const handleCloseConfirmDeleteModal = () => {
    setConfirmDeleteModal(false)
    setSelectedCandidateId(null)
  }

  const handlerConfirmDeleteVacancy = async () => {
    try {
      if (selectedCandidateId) {
        setLoading(true)
        await candidateService.deleteCandidate(selectedCandidateId)
        notification.success('Sucesso.', 'oportunidade removida com sucesso.')
        handleCloseConfirmDeleteModal()
        handlerLoadCandidates()
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setSendData(false)
  }

  const handleSaveModal = () => {
    setSendData(true)
  }

  const handleOpenModal = () => setShowModal(true)

  const handleInputSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value || ''

    setSearch(value)
    if (value.length === 0 || value.length > 2) debounceSearch(value)
  }

  const debounceSearch = (value: string) => {
    clearTimeout(debounceTimer)
    debounceTimer = setTimeout(() => {
      setPagination(prevData => ({
        ...prevData,
        page: 1,
        searchTerm: value
      }))
    }, 1500)
  }

  const handleFilterChange = (event: TCandidateModalFilter) => {
    let filters: any
    if (event.filters.length < 2) {
      filters = event.filters.reduce(
        (acc: string[], f: ECandidatesStatusEnum) => {
          if (f === ECandidatesStatusEnum.SYSTEM) acc.push('S')
          if (f === ECandidatesStatusEnum.PORTAL) acc.push('P')
          return acc
        },
        []
      )
    } else {
      filters = ''
    }

    setFormData(event)
    setShowModal(false)
    setSendData(false)
    console.log(event)

    setPagination(prevData => ({
      ...prevData,
      startDate: event.startDate
        ? dayjs(event.startDate as string).format('YYYY-MM-DD HH:mm')
        : '',
      endDate: event.endDate
        ? dayjs(event.endDate as string).format('YYYY-MM-DD HH:mm')
        : '',
      registrationSourceCode: filters
    }))
  }

  const handlerLoadCandidates = async (): Promise<void> => {
    try {
      if (!pagination.facilityId) return
      setLoading(true)
      const candidates = await candidateService.getAllCandidate(pagination)
      if (pagination.page > 1) {
        setCandidates(prevCandidates => [...prevCandidates, ...candidates])
      } else {
        setCandidates(candidates)
      }
    } catch {
    } finally {
      setLoading(false)
    }
  }

  const handlerLoadFilters = async (): Promise<void> => {
    try {
      setLoading(true)
      const facilitiesResult = await commonService.getFacilitiesFilters()
      setFacilitiesFilters(facilitiesResult)
      handlerSetFacility(facilitiesResult[0].id)
      setPagination(prevData => ({
        ...prevData,
        facilityId: facilitiesResult[0].id
      }))
    } catch {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handlerLoadFilters()
  }, [])

  useEffect(() => {
    handlerLoadCandidates()
  }, [pagination])
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=1024" />{' '}
      </Helmet>
      <CandidateStyle.Container className="w-100">
        <BradCrumbs
          names={['Sistema de estágio']}
          paths={[]}
          disabled={false}
          showArrow={false}
          backGroundColor={location.state || '#bc0823'}
        />
        <div style={{ paddingLeft: '2.6%', paddingRight: '2.6%' }}>
          <CandidateStyle.HeaderContent className="row mt-4">
            <CandidateStyle.PageTitle className="col-1 ">
              Candidatos
            </CandidateStyle.PageTitle>

            <div className="col d-flex align-items-center justify-content-center ms-5">
              <CandidateStyle.SelectedFacility>
                Unidade:
              </CandidateStyle.SelectedFacility>
              <Autocomplete
                disablePortal
                options={facilitiesFilters}
                getOptionLabel={option => option.name}
                getOptionKey={option => option.id}
                value={
                  facilitiesFilters.find(
                    (f: any) => f.id === pagination.facilityId
                  ) || { id: 0, name: '', situationCode: '' }
                }
                onChange={(event, newValue) => handlerSetFacility(newValue)}
                sx={{
                  minWidth: 200,
                  '& .MuiInputBase-root': { height: 40, paddingTop: 0 }
                }}
                disableClearable={true}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Selecione Unidade"
                    InputLabelProps={{ shrink: false }}
                  />
                )}
                renderOption={(props, option: TFacility, state, ownerState) => (
                  <li {...props}>{option.name}</li>
                )}
                popupIcon={<IoIosArrowDown />}
              />
            </div>
            <div className="col"></div>
            <div className="col"></div>
            <div className="col"></div>
            <div className="col">
              <CandidateStyle.NewOportunityBtn
                backgroundColor={location.state || '#bc0823'}
                className="px-3"
              >
                <Link
                  className="dropdown-item"
                  to="/candidate/candidate-record"
                  state={location.state || '#bc0823'}
                >
                  <IoMdAddCircleOutline size={25} className="me-2" /> Cadastrar
                  Novo Candidato
                </Link>
              </CandidateStyle.NewOportunityBtn>
            </div>
          </CandidateStyle.HeaderContent>
          {showModal && (
            <CustomModal
              show={showModal}
              onClose={handleCloseModal}
              onSave={handleSaveModal}
              onClear={handleClearFilters}
              title="Filtros"
              icon={<HiOutlineAdjustmentsHorizontal size={20} />}
              state={location.state || '#bc0823'}
              onlyCloseBtn={false}
              buttonTitle={'Salvar'}
            >
              <FilterModal
                state={location.state || '#bc0823'}
                outPutData={handleFilterChange}
                pulse={sendData}
                data={pagination}
              />
            </CustomModal>
          )}
          {showConfirmDeleteModal && (
            <CustomModal
              show={showConfirmDeleteModal}
              onClose={handleCloseConfirmDeleteModal}
              onSave={handlerConfirmDeleteVacancy}
              title="Confirmação"
              icon={<HiOutlineTrash size={20} />}
              state={location.state || '#bc0823'}
              onlyCloseBtn={false}
              buttonTitle={'Continuar'}
            >
              <h5>Tem certeza que deseja excluir?</h5> Esta ação não poderá ser
              desfeita.
            </CustomModal>
          )}
          <CandidateStyle.FilterContent className="mt-3 ">
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="button-tooltip-2">Filtros</Tooltip>}
            >
              <CandidateStyle.FilterBtn
                backgroundColor={location.state || '#004699'}
                className="me-2"
                onClick={handleOpenModal}
              >
                <HiOutlineAdjustmentsHorizontal size={25} />
              </CandidateStyle.FilterBtn>
            </OverlayTrigger>
            <div className="w-100 " style={{ position: 'relative' }}>
              <FormStyle.SearchIcon />
              <FormStyle.SearchInput
                type="search"
                placeholder="Busca"
                name="search"
                value={search}
                onChange={handleInputSearchChange}
              />
            </div>
          </CandidateStyle.FilterContent>
          <CustomSpinner state={location.state || '#bc0823'} show={isloading} />
          <div
            className="mt-3 w-100"
            style={{
              overflowX: 'hidden',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              borderTop: '1px solid #ccc'
            }}
          >
            <CandidatesTable
              headers={tableInfo.headers}
              body={candidates}
              options={tableInfo.options}
              sortHeader={handlerSetSort}
              scroll={handlerScroll}
              editCandidate={handleEdit}
              deleteCandidate={handlerDelete}
              schedulerOrientation={handlerschedulerOrientation}
            ></CandidatesTable>
          </div>
        </div>
      </CandidateStyle.Container>
    </>
  )
}

export default CandidatePage
