export enum ECandidateStepsEnum {
  FISTSTEP = 1,
  SECONDSTEP = 2,
  THIRDSTEP = 3,
  FOURTHSTEP = 4
}
export enum EHowDidYouHearAboutISBETEnum {
  CRAS = 1,
  CREAS = 2,
  FRIEND = 3,
  SOCIALMIDIA = 4,
  OTHERS = 5
}
export enum ECandidatesStatusEnum {
  SYSTEM = 1,
  PORTAL = 2
}
